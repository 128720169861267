import React from "react";
import { Logo2 } from "../Assets";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import { forgetPassword } from "../Features/ForgetPassSlice";

const ForgetPass = () => {
  const { status, error, message, forgetPass } = useSelector(
    (state) => state.forgetPass
  );
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const formSubmit = (data) => {
    dispatch(forgetPassword(data));
  };
  return (
    <div className="w-full h-[100vh] bg-secondary flex items-center flex-col">
      <div className="flex items-center gap-2 mt-7 mb-7 ">
        <img src={Logo2} alt="logo" className="w-[38px] " />
        <h3 className="text-2xl text-primary">PsychInsights</h3>
      </div>

      <form
        onSubmit={handleSubmit(formSubmit)}
        className={`w-[95%] lg:w-[40%] h-full bg-white rounded-md ${
          message ? "hidden" : ""
        }`}
      >
        <h2 className="my-5 text-[22px] font-[600] text-center text-primary">
          Forget Password
        </h2>
        <div className="w-[90%] my-0 mx-auto ">
          <div className="flex flex-col items-start w-full gap-2">
            <label htmlFor="email">Eenter your email</label>
            <input
              type="email"
              placeholder="Enter your email"
              {...register("email")}
              required
              className="w-full bg-[#F0F1F9] outline-none h-10 pl-[9px] rounded-lg"
            />
          </div>

          {error && (
            <div className="flex items-center mt-3 mb-[-20px]">
              <p className=" text-red-700 text-base font-medium">{error}</p>
            </div>
          )}

          {forgetPass.message ? (
            <div className="my-10 text-center flex flex-col items-center justify-center">
              <p className="font-bold">{forgetPass?.message}</p>

              <p className="text-primary font-bold text-xs">
                Open your mail, follow the instruction to reset password
              </p>
            </div>
          ) : (
            <div className="w-full my-4 ">
              <button
                disabled={status === StatusCode.LOADING}
                type="submit"
                className="w-[60%] relative bg-primary mt-20 text-white text-base mx-auto flex items-center justify-center py-[6px] rounded-md"
              >
                {status === StatusCode.LOADING ? (
                  <Shimmer height={20} width={20} color={"#fff"} />
                ) : (
                  <div className="w-full flex items-center justify-center relative">
                    Proceed
                    <HiOutlineArrowNarrowRight
                      size={20}
                      className="absolute right-3"
                    />
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
      </form>

      <div
        className={`${
          message ? "" : "hidden"
        } w-[95%] lg:w-[40%] text-center h-[50%] bg-white rounded-md flex items-center justify-center`}
      >
        <div>
          <p className="font-bold">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;
