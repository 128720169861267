import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  deactivtedUser: [],
  error: null,
};

export const getDeactivatedUser = createAsyncThunk(
  "get/deactivated",
  async (current) => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/closed-accounts?pages=${current ?? 1}`,
      config
    );
    const final = response.data.data;
    return final;
  }
);

const deactivatedUserSlice = createSlice({
  name: "deactivtedUser",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDeactivatedUser.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getDeactivatedUser.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.deactivtedUser = payload;
      });
  },
});

export default deactivatedUserSlice.reducer;
