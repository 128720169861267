import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StatusCode from "../Ultilities/StatusCode";
import axios from "axios";

const initialState = {
  status: StatusCode.IDLE,
  data: [],
  error: null,
};
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const fetchPromoCode = createAsyncThunk("get", async (current) => {
  try {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/promo-codes?page=${current ?? 1}`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

const fetchPromoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoCode.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchPromoCode.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchPromoCode.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default fetchPromoCodeSlice.reducer;
