import React from "react";
import { TagIcon } from "../Assets";

const ResourceList = ({
  photo,
  categories,
  title,
  author,
  publication_date,
  subscription_level,
}) => {
  return (
    <div className="px-5 py-2 border-[1px] border-inactiveText rounded-xl flex items-center gap-">
      <div className="w-[13%] h-[100px] flex items-center justify-start ">
        <img
          src={photo}
          alt="resource-cover"
          className="w-[80%] h-full rounded-lg"
        />
      </div>

      <div className="w-[87%]">
        <div className="flex items-end justify-between">
          <div>
            <div className="resource-category flex items-center">
              <div className="h-[25px] mr-2">
                <img src={TagIcon} alt="tag-Icon" className="" />
              </div>
              <p className="text-inactiveText text-base">
                {categories.join(", ")}
              </p>
            </div>

            <div className="my-[1px]">
              <h1 className="resource-title text-xl font-bold">{title}</h1>
            </div>

            <div>
              <p className="resource-author text-inactiveText text-lg">
                {author}
              </p>
            </div>
          </div>

          <div className="flex items-center text-sm gap-5">
            <p>{publication_date}</p>
            <p className="text-primary text-xs font-bold">
              <span>{subscription_level}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceList;
