import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  self: [],
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const fetchSelfReflect = createAsyncThunk(
  "get/selfReflect",
  async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/self-reflections`,
        config
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const selfReflectionSlice = createSlice({
  name: "self",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelfReflect.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchSelfReflect.fulfilled, (state, { payload }) => {
        state.self = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchSelfReflect.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default selfReflectionSlice.reducer;

// /// // // // // Create // // / // // /
export const postReflection = createAsyncThunk("post", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_URL}/admin/self-reflections`, config);

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// /// // // // // Delete reflection // // / // // /
export const deleteSelfRlect = createAsyncThunk("delete", async ({ id }) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({ id }),
    };

    const responce = await fetch(
      `${BASE_URL}/admin/self-reflections/${id}`,
      config
    );

    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // UPDATE // // // // // //
export const updateReflectCat = createAsyncThunk("update/", async (data) => {
  const { category, details, id } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category: category, details: details }),
    };

    const response = await fetch(
      `${BASE_URL}/admin/self-reflections/${id}`,
      config
    );

    //   success
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // Add question to a reflection // // // // // //
export const postQuestionReflect = createAsyncThunk("post", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${BASE_URL}/admin/self-reflections/${id}/questions`,
      config
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // Add question to a reflection // // // // // //
export const updateQuestionReflect = createAsyncThunk("post", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${BASE_URL}/admin/self-reflections/questions/${id}`,
      config
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // Add single Option to Question // // // // // //
export const postSingleOption = createAsyncThunk("post", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${BASE_URL}/admin/self-reflections/questions/${id}/options`,
      config
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// /// // // // // Delete Question // // / // // /
export const deleteOneQuestion = createAsyncThunk("delete", async ({ id }) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({ id }),
    };

    const responce = await fetch(
      `${BASE_URL}/admin/self-reflections/questions/${id}`,
      config
    );

    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});
// /// // // // // Delete Option // // / // // /
export const deleteOneOption = createAsyncThunk("delete", async ({ id }) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({ id }),
    };

    const responce = await fetch(
      `${BASE_URL}/admin/self-reflections/options/${id}`,
      config
    );

    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // Update single Option  // // // // // //
export const updateSingleOption = createAsyncThunk("post", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(
      `${BASE_URL}/admin/self-reflections/options/${id}`,
      config
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// /// // // // // Publish Option // // / // // /
export const publishOption = createAsyncThunk(
  "publish/unpublish",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await fetch(
        `${BASE_URL}/admin/self-reflections/${id}/publish`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
