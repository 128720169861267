import Modal from "./Modal";
import Button from "./Button";
import CatForm from "./CatForm";
import { PlusIcon } from "../Assets";
import ResourceListHead from "./ResourceListHead";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  AddBlogCateg,
  DeleteBlogCategory,
  UpdateBlogCateg,
  getBlogCategories,
} from "../Features/BlogCategoriesSlice";

const ThStyle = `py-2 px-3 text-primary`;
const TdStyle = `border-b border-inactiveText bg-trasnparent p-2 text-center text-sm font-medium text-[#2E2F32]`;

const BlogCategories = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formState, setFormState] = useState({
    category: "",
    description: "",
  });

  // // // // // // Fetch // // // // // //
  useEffect(() => {
    dispatch(getBlogCategories());
  }, [dispatch]);
  const blogCat = useSelector(
    (state) => state.blogCategories.blogCategoriesData.data
  );

  // // // // // // selectedItem // // // // // // //
  const blogCatSelect = (item) => {
    setSelectedItem(item);
    setModalIsOpen(!modalIsOpen);
  };

  // // // // // // Add New Blog Category // // // // // // //
  const openForm = () => {
    setFormIsOpen(!formIsOpen);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const createBlogCategory = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
    };
    if (payLoad.category === "") {
      alert("category field is required");
    } else if (payLoad.description === "") {
      alert("description field is required");
    } else {
      setLoading(true);
      dispatch(AddBlogCateg(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Category Added Successfully`);
        })
        .then(() => {
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2100);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // // Update Blog Category // // // // // // //
  const forUpate = () => {
    setEditMode(!editMode);
    setFormIsOpen(!formIsOpen);
  };
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
  }, [editMode, selectedItem]);
  const updateBlogCat = (e) => {
    e.preventDefault();
    const updateData = {
      id: selectedItem.id,
      category: formState.category,
      description: formState.description,
    };

    if (updateData.category === "") {
      alert("category field is required");
    } else if (updateData.description === "") {
      alert("description field is required");
    } else {
      setLoading(true);
      dispatch(UpdateBlogCateg({ id: selectedItem.id, updateData: updateData }))
        .unwrap()
        .then(() => {
          showToast(`Category Update Successfully`);
        })
        .then(() => {
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2100);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // DELETE BLOG Category // // // // // // //
  const handleDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(DeleteBlogCategory({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Blog Category Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  return (
    <div className="flex flex-col gap-5">
      <ToastContainer />
      <div className="flex justify-end">
        <Button
          btnStyle={"bg-primary"}
          btnText="Add new blog"
          btnIcon={PlusIcon}
          btnClick={openForm}
        />
      </div>

      <div className="bg-white w-full p-5 rounded-lg shadow-lg">
        <ResourceListHead resourceListType="Blog Categories" />
        <div className="w-full overflow-x-scroll py-3 px-5">
          <table className="w-full table-auto">
            <thead className="text-center bg-[#F6F7FA]">
              <tr>
                <th className={ThStyle}>S/N</th>
                <th className={ThStyle}>Category</th>
                <th className={ThStyle}>Created at</th>
                <th className={ThStyle}>Updated at</th>
              </tr>
            </thead>

            <tbody>
              {blogCat?.map((category, index) => (
                <tr
                  className="cursor-pointer hover:bg-[#E9F3F3]"
                  onClick={() => blogCatSelect(category)}
                  key={category?.id}
                >
                  <td className={TdStyle}>{index + 1}</td>
                  <td className={TdStyle}>{category?.category}</td>
                  <td className={TdStyle}>
                    {new Date(category?.created_at).toDateString()}
                  </td>
                  <td className={TdStyle}>
                    {new Date(category?.updated_at).toDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modalIsOpen ? (
        <div className="modalfloat">
          <div className="w-[60%] mx-auto">
            <Modal
              item={selectedItem}
              loading={loading}
              updateAction={forUpate}
              cancelAction={() => setModalIsOpen(!modalIsOpen)}
              deleteitem={handleDelete}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {formIsOpen ? (
        <div className="blogfloat">
          <CatForm
            editMode={editMode}
            formState={formState}
            loading={loading}
            handleChange={handleChange}
            handleCreate={createBlogCategory}
            handleUpdate={updateBlogCat}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogCategories;

// Toastify
export const showToast = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
  });
};
