import { createAsyncThunk } from "@reduxjs/toolkit";

const userToken = localStorage.getItem("userToken");
const BASE_URL = "https://psychinsightsapp.net/api";

export const createPromoCode = createAsyncThunk("get", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(data),
    };
    const responce = await fetch(`${BASE_URL}/admin/promo-codes`, config);
    if (responce.ok) {
      const resData = await responce.json();
      return resData;
    } else {
      alert.log("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});

// /// // // // // Delete Promo code // // / // // /
export const deletePromoCode = createAsyncThunk("delete", async ({ id }) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({ id }),
    };
    const responce = await fetch(`${BASE_URL}/admin/promo-codes/${id}`, config);
    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});

// /// // // // // Activate / Deactivate Promo code // // / // // /
export const activatePromoCode = createAsyncThunk(
  "activate",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/promo-codes/${id}/activation`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to activate");
      }
    } catch (error) {
      throw error;
    }
  }
);

// /// // // // // Update Promo code // // / // // /
export const updatePromoCode = createAsyncThunk("get", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(payload),
    };
    const responce = await fetch(`${BASE_URL}/admin/promo-codes/${id}`, config);
    if (responce.ok) {
      const resData = await responce.json();
      return resData;
    } else {
      alert.log("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});
