import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  quoteData: [],
  error: null,
};

export const getQuote = createAsyncThunk("get/getQuote", async (current) => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/daily-quotes`, config);

    const final = response.data.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const quoteSlice = createSlice({
  name: "quotes",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getQuote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuote.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.quoteData = payload;
      });
  },
});

export default quoteSlice.reducer;
