import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StatusCode from "../Ultilities/StatusCode";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: StatusCode.IDLE,
  freePackage: {},
  error: null,
};

export const getFreePackage = createAsyncThunk("get", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/free-package`, config);
  return response.data;
});

const freePackageSlice = createSlice({
  name: "freePackage",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFreePackage.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(getFreePackage.fulfilled, (state, { payload }) => {
        state.status = StatusCode.IDLE;
        state.freePackage = payload;
      });
  },
});

export default freePackageSlice.reducer;

// ADD/UPDATE

export const addFreePackage = createAsyncThunk("add", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_URL}/admin/free-package`, config);
    return response;
  } catch (error) {
    throw error;
  }
});

export const deleteFreePackage = createAsyncThunk("delete", async () => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const responce = await fetch(`${BASE_URL}/admin/free-trials`, config);
    return responce;
  } catch (error) {
    throw error;
  }
});
