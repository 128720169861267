import React from "react";
import {
  Logo,
  Dashboard,
  Users,
  Resources,
  Category,
  Assessment,
  Subscription,
  Strategy,
  Admin,
  Group,
} from "../Assets";

import { Link, NavLink } from "react-router-dom";
import Acod from "./Acod";
import Acod2 from "./Acod2";
import Acod3 from "./Acod3";

const Sidebar = ({ logOut }) => {
  const activeLink = "flex items-start gap-3 text-white";
  const normalLink = "flex items-start gap-3 text-[#94A3B8] hover:text-white";
  return (
    <div className="flex  flex-col items-center fixed w-[15%] z-20 h-[100vh] rounded-tr-3xl rounded-br-3xl mx-auto my-0 bg-primary">
      <div className="flex items-center justify-center gap-1 mt-5 mb-10">
        <img src={Logo} alt="logo" className="w-[35px]" />
        <Link to="/" className="text-base text-white capitalize">
          PsychInsights
        </Link>
      </div>

      <div className="">
        <ul>
          <li className="mb-3">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Dashboard} alt="dash" /> Dashboard
            </NavLink>
          </li>

          {/* <li className="mb-3">
            <NavLink
              to="user"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Users} alt="user" /> Users
            </NavLink>
          </li> */}

          <li className="mb-3">
            <div className="flex items-start gap-3">
              <img src={Users} alt="user" /> <Acod3 />
            </div>
          </li>

          <li className="mb-3">
            <div className="flex items-start gap-3">
              <img src={Resources} alt="resource" /> <Acod />
            </div>
          </li>

          <li className="mb-3">
            <NavLink
              to="category"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Category} alt="category" /> Category
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="assessment"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Assessment} alt="assess" /> Assessments
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="sub"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Subscription} alt="sub" /> Subscription
            </NavLink>
          </li>
          <li className="mb-3">
            <div className="flex items-start gap-3 ">
              <img src={Strategy} alt="resource" /> <Acod2 />
            </div>
          </li>
          <li className="mb-3">
            <NavLink
              to="admin"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={Admin} alt="admin" /> Admin
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="absolute flex items-center justify-center left-9 bottom-3">
        <button
          onClick={logOut}
          className="flex items-center justify-center gap-3 text-white"
        >
          <img src={Group} alt="logout" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
