import React from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import CountUp from "react-countup";
const UserCard = ({ icon, title, period, value, prefix, durate }) => {
  return (
    <div className="w-full bg-white rounded-[6px] shadow-lg">
      <div className="w-[95%] py-4 mx-auto ">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2 ">
            <img src={icon} alt={`${icon}`} className="w-[44px]" />
            <h3 className="text-lg font-semibold capitalize">{title}</h3>
          </div>
          {/* <div className="flex items-center gap-3 px-2 py-1 rounded-[5px] bg-secondary">
            <p className="text-[#94A3B8] capitalize text-sm">{period}</p>
            <IoChevronDownOutline color="#94A3B8" />
          </div> */}
        </div>
        <div className="flex items-center justify-between w-full mt-6">
          <h3 className="text-2xl font-bold">
            <CountUp
              prefix={`${prefix}`}
              separator=","
              start={0}
              end={value}
              duration={2.5}
            />
          </h3>
        </div>
        <div className="flex items-center justify-between">
          {/* <p className="mt-3 text-sm font-semibold capitalize">{durate}</p> */}
          {/* <div className="flex items-center gap-2 px-2 py-1 rounded-lg bg-secondary">
            <img src={Curly} alt="curly" />
            <p className="text-[13px] ">40%</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
