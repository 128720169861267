import React, { useEffect, useState } from "react";
import { ReactPagination, Shimmer, UserCard, UserDetails } from "../Components";
import { Buser, Dashuser, Puser, DummyUserImage, VerifyIcon } from "../Assets";
import {
  fetchUsers,
  getUserInfos,
  getUserDetails,
} from "../Features/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import StatusCode from "../Ultilities/StatusCode";

const Users = () => {
  const { userItem, totalPages, itemPerPage, status2 } = useSelector(
    (state) => state.user
  );
  const [selectedItem, setSelectedItem] = useState();
  const dispatch = useDispatch();
  const values = useSelector((state) => state.user.userData);
  const [activeItem, setActiveItem] = useState(userItem[0]?.id || null);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const startSerial = (currentPage - 1) * itemPerPage + 1;
  const Data = [
    {
      id: 1,
      prefix: "",
      title: "users",
      icon: Dashuser,
      value: `${values?.total_users}`,
      period: "today",
      durate: "today",
    },
    {
      id: 2,
      prefix: "",
      title: "premium users",
      icon: Puser,
      value: `${values?.premium_users}`,
      period: "last week",
      durate: "in last week",
    },
    {
      id: 3,
      prefix: "",
      title: "Basic users",
      icon: Buser,
      value: `${values?.basic_users}`,
      period: "today",
      durate: "in last week",
    },
    {
      id: 4,
      prefix: "",
      title: "deactivated users",
      icon: Buser,
      value: `${values?.deactivated_users}`,
      period: "today",
      durate: "in last week",
    },
  ];
  const handleClicked = (item) => {
    setSelectedItem(item);
    setActiveItem(item.id);
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    localStorage.setItem("currentPage", newPage);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetails(userItem[0]?.id));
    setSelectedItem(null);
  }, [userItem, dispatch]);

  useEffect(() => {
    dispatch(getUserInfos(currentPage));
  }, [currentPage, dispatch]);

  return (
    <div>
      <div className="flex items-center justify-between  w-full">
        <h1 className="text-xl text-[#0F2851] font-semibold capitalize">
          User management
        </h1>
        <div className="flex items-center gap-3">
          <p>Home</p>
          <p>Users</p>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="grid w-full grid-cols-2 gap-7 ">
          {Data.map((data) => (
            <div key={data.id}>
              <UserCard {...data} />
            </div>
          ))}
        </div>

        <div className="flex items-start w-full gap-4">
          <div className="w-[70%] bg-white rounded-lg py-2 ">
            <div className="flex items-center justify-between w-[95%] mx-auto mt-2 mb-6">
              <h3 className="font-semibold capitalize text-xl">
                Users information
              </h3>
              {/* <div className="flex items-center gap-3 px-2 py-1 rounded-md bg-secondary">
                <img src={Download} alt="download" />
                <p className="text-[#9F9F9F]">view all</p>
              </div> */}
            </div>

            <div className="w-[95%] mx-auto my-4  relative">
              <table className="table  divide-y  divide-gray-200 w-full">
                <thead>
                  <tr className="text-center">
                    <th className="text-[16px] border-r border-gray-400  text-primary">
                      S/N
                    </th>
                    <th className="text-[16px] border-r border-gray-400 text-primary">
                      Subscribers
                    </th>
                    <th className="text-[16px] border-r border-gray-400 text-primary">
                      Plan
                    </th>
                    <th className="text-[16px] border-r border-gray-400 text-primary">
                      Status
                    </th>
                    <th className="text-[16px] border-r border-gray-400 text-primary">
                      User Status
                    </th>
                    <th className="text-[16px] border-r border-gray-400 text-primary">
                      Start date
                    </th>
                    <th className="text-[16px] text-primary">End date</th>
                  </tr>
                </thead>
                {status2 === StatusCode.LOADING ? (
                  <div className="w-full flex items-center justify-center absolute ">
                    <Shimmer height={24} color={"#207384"} />
                  </div>
                ) : (
                  <tbody>
                    {userItem?.map((data, index) => (
                      <tr
                        className={`text-center border-b cursor-pointer  ${
                          activeItem === data?.id
                            ? "bg-primary text-[#fff]"
                            : "hover:bg-[#E9F3F3]"
                        }`}
                        key={data.id}
                        onClick={() => handleClicked(data)}
                      >
                        <td className="border-r border-gray-400 ">
                          {startSerial + index}
                        </td>
                        <td className="border-r border-gray-400 flex items-center justify-center gap-5">
                          <div className="w-[20%]  flex items-center justify-center">
                            <img
                              src={data.profile_photo || DummyUserImage}
                              alt="profile"
                              className="w-[30px] h-[30px] rounded-full  "
                            />
                          </div>

                          <div className="w-[80%] ml-[-25px]">
                            <h3 className="capitalize text-sm font-semibold">
                              {data.name}
                            </h3>
                            <div className="flex items-center gap-2 justify-center">
                              <p className={`text-[10px]`}>{data.email}</p>
                              {data?.email_verified == "1" ? (
                                <img
                                  src={VerifyIcon}
                                  alt=""
                                  className=" cursor-pointer"
                                  title="Email is verified"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="border-r border-gray-400 text-sm capitalize">
                          {data.subscription_package}
                        </td>
                        <td className="border-r border-gray-400 text-sm">
                          {data.status == "1" ? "Active" : "Inactive"}
                        </td>
                        <td className="border-r border-gray-400 text-sm capitalize">
                          {data?.deactivated == "1" ? "Inactive" : "Active"}
                        </td>
                        <td className="border-r border-gray-400 font-semibold text-sm">
                          {data.start_date}
                        </td>
                        <td className="font-semibold  text-sm">
                          {!data?.end_date ? "N/A" : data?.end_date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>

              <div className="mt-6 flex justify-end items-end relative w-full">
                <ReactPagination
                  total={totalPages}
                  onChanges={handlePageChange}
                  forcePage={currentPage - 1}
                />
              </div>
            </div>
          </div>

          <div className="w-[38%] bg-white rounded-lg py-3">
            <UserDetails data={selectedItem} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
