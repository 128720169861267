import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  tipData: [],
  error: null,
};

export const getTip = createAsyncThunk("get/getTip", async (current) => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/daily-tips`, config);

    const final = response.data.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const tipSlice = createSlice({
  name: "tips",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTip.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTip.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.tipData = payload;
      });
  },
});

export default tipSlice.reducer;
