import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  scope: [],
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const fetchScope = createAsyncThunk("get/fetchScope", async () => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/premium-score-ranges`,
      config
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const scopeSlice = createSlice({
  name: "category",
  initialState,
  reducecer: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchScope.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchScope.fulfilled, (state, { payload }) => {
        state.scope = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchScope.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default scopeSlice.reducer;

export const addScope = createAsyncThunk(
  "category/createNewCategory",
  async (payLoad) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(
        `${BASE_URL}/admin/premium-score-ranges`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteScope = createAsyncThunk(
  "category/deleteScope",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify({ id }),
      };
      const responce = await fetch(
        `${BASE_URL}/admin/premium-score-ranges/${id}`,
        config
      );
      const data = await responce.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateScope = createAsyncThunk(
  "category/createNewCategory",
  async (data) => {
    const { id, payLoad } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(
        `${BASE_URL}/admin/premium-score-ranges/${id}`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
