import React, { useEffect, useState } from "react";
import SubTable from "./SubTable";
import { DummyUserImage, PlusIcon } from "../Assets";
import InputTag from "./InputTag";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createPromoCode,
  updatePromoCode,
} from "../Features/PostPromoCodeSlice.js";
import { showToast } from "./BlogCategories";
import { fetchPromoCode } from "../Features/PromoCodeSlice.js";
import DonoughtChart from "./DonoughtChart.jsx";
import CountUp from "react-countup";
import moment from "moment";
import StatusCode from "../Ultilities/StatusCode.js";
import Shimmer from "./Shimmer.jsx";
import ReactPagination from "./ReactPagination.jsx";
import { fetchSubscriber } from "../Features/SubscribeSlice.js";

const Promo = () => {
  const dispatch = useDispatch();
  const [createPromo, setCreatePromo] = useState(false);
  const [theSelected, setTheSelected] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    promo_code: "",
    usage_limit: 1, //a user number of time
    total_limit: 2,
    percentage_off: 0,
    scope: [],
  });

  const {
    subscribers: subItem,
    allPages,
    initialPage,
    itemPerPage,
    status2,
  } = useSelector((state) => state.subscribe);
  const promoCodeData = useSelector((state) => state?.promoCode.data);
  const getTotal = useSelector((state) => state.subscribe.sub_summary);
  const promoCodeStatus = useSelector((state) => state?.promoCode?.status);

  const Total = getTotal.total_users;
  const serialNum = (initialPage - 1) * itemPerPage + 1;
  const Recent = getTotal.recent_subscribers;
  const handlePageChanges = (data) => {
    dispatch(fetchSubscriber(data.selected + 1));
  };

  const showCreatePlan = () => {
    setCreatePromo(!createPromo);
  };
  const closeCreatePlan = () => {
    setCreatePromo(!createPromo);
    dispatch(fetchPromoCode());
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => {
      if (id === "scope") {
        return { ...prevData, [id]: [value] };
      } else {
        return { ...prevData, [id]: value };
      }
    });
  };
  const increase = (e) => {
    e.preventDefault();
    const { id } = e.target;
    setFormState((prev) => {
      const newLimit = { ...prev };
      if (id === "usage_limit") {
        newLimit.usage_limit = Number(newLimit.usage_limit) + 1;
        return newLimit;
      } else if (id === "total_limit") {
        newLimit.total_limit = Number(newLimit.total_limit) + 1;
        return newLimit;
      } else return null;
    });
  };
  const decrease = (e) => {
    e.preventDefault();
    const { id } = e.target;
    setFormState((prev) => {
      const newLimit = { ...prev };
      if (id === "usage_limit") {
        newLimit.usage_limit = Number(newLimit.usage_limit) - 1;
        return newLimit;
      } else if (id === "total_limit") {
        newLimit.total_limit = Number(newLimit.total_limit) - 1;
        return newLimit;
      }
    });
  };
  const createCode = () => {
    if (formState.scope.length < 1 || formState.scope[0] === "") {
      alert("please selet a scop");
    } else if (formState.promo_code === "") {
      alert("Promocode is required");
    } else if (formState.percentage_off <= 0) {
      alert("percentage off is required");
    } else if (formState.total_limit <= formState.usage_limit) {
      alert("Total limit must be grater than usage limit");
    } else {
      setLoading(true);
      dispatch(createPromoCode(formState))
        .unwrap()
        .then(() => {
          showToast("Promo code created successfully");
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            setLoading(false);
          }, 2100);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };
  useEffect(() => {
    dispatch(fetchPromoCode());
  }, [dispatch]);
  useEffect(() => {
    if (!editMode) return;
    setFormState(theSelected);
  }, [editMode, theSelected, setFormState]);
  const toEditMode = () => {
    setCreatePromo(!createPromo);
    setEditMode(!editMode);
  };
  const updateCode = () => {
    const payload = {
      promo_code: formState.promo_code,
      usage_limit: formState.usage_limit,
      percentage_off: formState.percentage_off,
      scope: formState.scope,
    };
    if (payload.scope.length < 1 || payload.scope[0] === "") {
      alert("please selet a scop");
    } else if (payload.promo_code === "") {
      alert("Promocode is required");
    } else if (payload.percentage_off <= 0) {
      alert("percentage off is required");
    } else {
      setLoading(true);
      dispatch(updatePromoCode({ payload: payload, id: theSelected.id }))
        .unwrap()
        .then(() => {
          showToast("Promo code created successfully");
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            setLoading(false);
          }, 2100);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  return (
    <>
      {!createPromo ? (
        <div>
          <div className="flex relative items-center justify-between mt-10">
            <button
              className="bg-primary py-[6px] px-5 rounded-xl text-white text-sm absolute top-[-54px] right-0 flex items-center gap-2"
              onClick={showCreatePlan}
            >
              <img src={PlusIcon} alt="" />
              Create plan
            </button>
          </div>

          <div className="w-full flex items-start mt-12 justify-between">
            <SubTable
              datas={promoCodeData?.data}
              status={promoCodeStatus}
              theSelected={theSelected}
              toEditMode={toEditMode}
              setTheSelected={setTheSelected}
            />
            <div className="w-[28%] rounded-lg bg-white">
              <div className="w-[90%] mx-auto py-2">
                <h2 className="">Total No, of Users</h2>
                <h4 className="mt-1 font-bold text-[24px]">
                  <CountUp separator="," start={0} end={Total} duration={2.5} />
                </h4>
                <div className="flex items-center">
                  <div className="w-[98%]">
                    <DonoughtChart />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-start mt-7 justify-between">
            <div className="w-[70%] rounded-lg bg-white">
              <div className="w-[90%] mx-auto mt-3 mb-4 ">
                <h3 className="text-base font-semibold">Subscribers</h3>
              </div>
              <div className="w-full pb-3 relative">
                <table className="table w-full mx-auto divide-y divide-gray-200 ">
                  <thead className="bg-[#ECECEC]">
                    <tr>
                      <th className="text-primary ">S/N</th>
                      <th className="text-primary ">Subscribers</th>
                      <th className="text-primary ">Status</th>
                      <th className="text-primary ">Plan</th>
                      <th className="text-primary ">Start Date</th>
                      <th className="text-primary ">End Date</th>
                    </tr>
                  </thead>

                  {status2 === StatusCode.LOADING ? (
                    <div className="w-full flex items-center justify-center absolute ">
                      <Shimmer height={24} color={"#207384"} />
                    </div>
                  ) : (
                    <tbody>
                      {subItem?.map((data, index) => (
                        <tr
                          className="text-center border-b hover:bg-[#E9F3F3]"
                          key={data.id}
                        >
                          <td className="border-r border-gray-400">
                            {serialNum + index}
                          </td>
                          <td className="border-r border-gray-400 flex items-center  justify-center gap-5">
                            <div className="w-[40px] h-[40px] flex items-center justify-center">
                              <img
                                src={data.profile_photo || DummyUserImage}
                                alt="profile"
                                className="w-[80%] h-[80%] rounded-full"
                              />
                            </div>
                            <div className="w-[80%] ml-[-25px]">
                              <h3 className="capitalize text-sm font-semibold">
                                {data.subscriber}
                              </h3>
                              <p className="text-[10px] text-[#898D9E] ">
                                {data.email}
                              </p>
                            </div>
                          </td>
                          <td className="border-r border-gray-400 text-sm capitalize">
                            {data.status == "1" ? "Active" : "Unactive"}
                          </td>
                          <td className="border-r border-gray-400 text-sm">
                            {data?.subscription_package?.package}
                          </td>
                          <td className="border-r border-gray-400 font-semibold text-sm">
                            {data.start_date}
                          </td>
                          <td className="border-r border-gray-400 font-semibold text-sm">
                            {data.end_date}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>

              <div className="mt-1 mb-3 flex justify-end items-end w-[96%] mx-auto">
                <ReactPagination
                  total={allPages}
                  onChanges={handlePageChanges}
                />
              </div>
            </div>

            <div className="w-[28%] h[565px] overflow-y-auto rounded-lg bg-white">
              <div className="w-[93%] mx-auto mt-3">
                <h2 className="mb-3 text-base font-semibold">
                  10 Recent subscription
                </h2>
                {Recent?.slice(0, 10).map((data) => (
                  <div
                    className="w-full flex items-center justify-between border-b py-[3px] my-[1px]"
                    key={data.user_id}
                  >
                    <div className="w-[18%] ">
                      <img
                        src={data.icon || DummyUserImage}
                        alt={`${data.subscriber}`}
                        className="rounded-full w-[80%] "
                      />
                    </div>
                    <div className="w-[80%] ">
                      <div className="flex items-center justify-between w-full">
                        <h3 className="font-semibold text-base">
                          {data.subscriber}
                        </h3>
                        <p className="font-semibold text-base">
                          ${data.amount_paid}
                        </p>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <p className="text-sm text-[#898D9E] ">
                          {moment(data.subscription_time)
                            .startOf("ss")
                            .fromNow()}
                        </p>
                        <p className="text-sm text-[#898D9E]">{data.plan}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white py-10 px-5 rounded-lg my-10">
          <div className=" grid grid-cols-2 gap-5">
            <InputTag
              labelFor="promo_code"
              labelText={"Promo Code"}
              inputType={"text"}
              forOnChange={(e) => handleChange(e)}
              forValue={formState?.promo_code}
              feildPlaceHoler={"Enter code"}
            />
            <InputTag
              labelFor="percentage_off"
              labelText={"Percentage Off"}
              inputType={"number"}
              forOnChange={(e) => handleChange(e)}
              forValue={formState?.percentage_off}
              feildPlaceHoler={"100%"}
            />

            <div className="flex flex-col gap-5">
              <div>
                <label htmlFor="usage_limit">Usage_Limit</label>
                <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[70%]">
                  <button
                    dangerouslySetInnerHTML={{ __html: "&minus;" }}
                    className="px-1"
                    id="usage_limit"
                    onClick={(e) => decrease(e)}
                  />
                  <input
                    name="usage_limit"
                    type="tel"
                    placeholder="value"
                    value={formState?.usage_limit}
                    onChange={(e) => handleChange(e)}
                    className={`w-full h-[25px] px-2 text-center border-x-2`}
                  />
                  <button
                    id="usage_limit"
                    dangerouslySetInnerHTML={{ __html: "&plus;" }}
                    className="px-1"
                    onClick={(e) => increase(e)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="total_limit">Total_limit</label>
                <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[70%]">
                  <button
                    dangerouslySetInnerHTML={{ __html: "&minus;" }}
                    className="px-1"
                    id="total_limit"
                    onClick={(e) => decrease(e)}
                  />
                  <input
                    name="total_limit"
                    type="tel"
                    placeholder="value"
                    value={formState?.total_limit}
                    onChange={(e) => handleChange(e)}
                    className={`w-full h-[25px] px-2 text-center border-x-2`}
                  />
                  <button
                    id="total_limit"
                    dangerouslySetInnerHTML={{ __html: "&plus;" }}
                    className="px-1"
                    onClick={(e) => increase(e)}
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="scope">Scope</label> <br />
              <select
                value={formState?.scope}
                onChange={(e) => handleChange(e)}
                name="scope"
                id="scope"
                className=" w-full border-2 border-inactiveText rounded-lg py-[5px]  bg-transparent"
              >
                <option value="">Selete Scope</option>
                <option value="all">All</option>
                <option value="more">More</option>
              </select>
            </div>
          </div>

          <div className="mt-5 flex items-center gap-5">
            <Button
              btnClick={editMode ? updateCode : createCode}
              btnDisabled={loading}
              btnText={editMode ? "Update Code" : "Create Code"}
              btnStyle={"bg-primary"}
            />

            <Button
              btnClick={closeCreatePlan}
              btnText={"Cancel"}
              btnStyle={"bg-inactiveText"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Promo;
