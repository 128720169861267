import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  blogCategoriesData: {},
  error: null,
};

export const getBlogCategories = createAsyncThunk(
  "get/getBlogCategories",
  async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/blog-categories`,
        config
      );

      const final = response.data;
      return final;
    } catch (error) {
      throw error;
    }
  }
);

const blogCategoriesSlice = createSlice({
  name: "blogCategories",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBlogCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBlogCategories.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.blogCategoriesData = payload;
      });
  },
});

export default blogCategoriesSlice.reducer;

// // // // // // Add New Blog // // // // // // //
export const AddBlogCateg = createAsyncThunk("add/blog", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(`${BASE_URL}/admin/blog-categories`, config);
    return response;
  } catch (error) {
    throw error;
  }
});

// // // // // // // Update Blog // // // // // // //
export const UpdateBlogCateg = createAsyncThunk("add/blog", async (data) => {
  try {
    const { id, updateData } = data;
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(updateData),
    };

    const response = await fetch(
      `${BASE_URL}/admin/blog-categories/${id}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
});

export const DeleteBlogCategory = createAsyncThunk(
  "delete/deleteBlog",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };
      const response = await fetch(
        `${BASE_URL}/admin/blog-categories/${id}`,
        config
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);
