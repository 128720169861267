import React from "react";
import CountUp from "react-countup";

const BoardData = ({
  imgStyle,
  resourceIcon,
  resourcesIconAlt,
  resourceTotal,
  resourceName,
  textStyle,
}) => {
  return (
    <div className="w-1/3 bg-mainWhite flex items-center justify-around py-5 rounded-xl">
      <div className="flex flex-col items-center justify-center gap-5">
        <div
          className={`w-[60px] h-[60px] rounded-full ${imgStyle} bg-[#E2F8F9] flex items-center justify-center`}
        >
          <img src={resourceIcon} alt={resourcesIconAlt} />
        </div>
        <p className={`resourceTextColor ${textStyle} text-lg font-bold`}>
          {resourceName}
        </p>
      </div>

      <div className="flex items-center justify-center font-bold text-2xl">
        <h1>
          <CountUp start={0} end={resourceTotal} />
        </h1>
      </div>
    </div>
  );
};

export default BoardData;
