import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const Assessment = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <h1>Assessment</h1>
        <div className="flex items-center gap-3">
          <p>Home</p>
          <p className="text-primary">Basic Question</p>
        </div>
      </div>

      <div className="flex items-center gap-12 mt-5">
        <Link
          to=""
          className={`px-3 py-[6px] text-base rounded-lg ${
            pathname === "/assessment" ? "bg-mainWhite" : ""
          }`}
        >
          Daily question
        </Link>
        <Link
          to="basic-quest"
          className={`px-3 py-[6px] text-base rounded-lg ${
            pathname === "/assessment/basic-quest" ? "bg-mainWhite" : ""
          }`}
        >
          Basic question
        </Link>
        <Link
          to="dass-quest"
          className={`px-3 py-[6px] text-base rounded-lg ${
            pathname === "/assessment/dass-quest" ? "bg-mainWhite" : ""
          }`}
        >
          DASS-21 questions
        </Link>

        {/* <Link
          to="self-reflection"
          className={`px-3 py-[6px] text-base rounded-lg ${
            pathname === "/assessment/self-reflection" ? "bg-mainWhite" : ""
          }`}
        >
          Self Reflection
        </Link> */}

        <Link
          to="goals"
          className={`px-3 py-[6px] text-base rounded-lg ${
            pathname === "/assessment/goals" ? "bg-mainWhite" : ""
          }`}
        >
          Goals & Self Reflection
        </Link>
      </div>

      <div className="w-full mt-12">
        <Outlet />
      </div>
    </div>
  );
};

export default Assessment;
