import React, { useEffect, useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import {
  fetchNotificationSetting,
  updateNotificationSettings,
} from "../Features/NotificationSettings";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const NotificationSetting = () => {
  const dispatch = useDispatch();
  const notificationSettingsFeild = useSelector(
    (state) => state.notificationSetting?.notification
  );
  const [isClicked, setIsClicked] = useState(false);
  const [formState, setFormState] = useState({ ...notificationSettingsFeild });

  const toggleSwitch = (e) => {
    const { id } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [id]: prevFormState[id] === 1 ? 0 : 1,
    }));
    setIsClicked(true);
  };

  useEffect(() => {
    dispatch(fetchNotificationSetting());
  }, [dispatch]);

  useEffect(() => {
    if (isClicked) {
      const payLoad = { ...formState };
      dispatch(updateNotificationSettings(payLoad))
        .unwrap()
        .then(() => showToast("Notification Setting successfully updated"))
        .catch((error) => {
          showToast(error);
        });
    }
  }, [formState, dispatch, isClicked]);

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full flex flex-col gap-5">
        <div className="shadow-custom bg-white py-1 w-full flex items-center gap-3 rounded-lg">
          <div className="w-[80%]">
            <div className="w-[94%] mx-auto py-[6px]">
              <h2 className="capitalize text-lg ">new users</h2>
              <p className="text-[#898D9E] text-base py-1">
                The new user notifications are the notifications you get when a
                user signup on the platform
              </p>
            </div>
          </div>

          <div className="w-[20%]">
            <div className="w-[94%] mx-auto flex items-center gap-2">
              <label className="flex relative items-center gap-4">
                <span className="capitalize text-base text-[#898D9E] font-medium">
                  notify me
                </span>
                {/* <div className="w-[50px] h-6 bg-gray-300 flex items-center cursor-pointer rounded-full p-[1px]">
                  <div
                    className={`w-[22px] h-[22px] rounded-full border-primary border transition-transform cursor-pointer transform flex items-center justify-center ${
                      formState?.new_user_notification === 1
                        ? "translate-x-6"
                        : "translate-x-0"
                    }  ${
                      formState?.new_user_notification === 1
                        ? "bg-primary"
                        : "bg-white"
                    }`}
                  >
                    <IoCheckmarkSharp size={15} color="#fff" />
                  </div>
                </div> */}
                <input
                  id="new_user_notification"
                  type="checkbox"
                  className="sr-only peer "
                  onChange={(e) => toggleSwitch(e)}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white py-1 w-full flex items-center gap-3 rounded-lg">
          <div className="w-[80%]">
            <div className="w-[94%] mx-auto py-[6px]">
              <h2 className="capitalize text-lg ">new subscriber</h2>
              <p className="text-[#898D9E] text-base py-1">
                The new subscriber notifications are the notifications you get
                when a user subscribe
              </p>
            </div>
          </div>

          <div className="w-[20%]">
            <div className="w-[94%] mx-auto flex items-center gap-2">
              <label className="flex relative items-center gap-4">
                <span className="capitalize text-base text-[#898D9E] font-medium">
                  notify me
                </span>
                <div className="w-[50px] h-6 bg-gray-300 flex items-center cursor-pointer rounded-full p-[1px]">
                  <div
                    className={`w-[22px] h-[22px] rounded-full border-primary border transition-transform cursor-pointer transform flex items-center justify-center ${
                      formState?.new_subscriber_notification === 1
                        ? "translate-x-6"
                        : "translate-x-0"
                    }  ${
                      formState?.new_subscriber_notification === 1
                        ? "bg-primary"
                        : "bg-white"
                    }`}
                  >
                    <IoCheckmarkSharp size={15} color="#fff" />
                  </div>
                </div>
                <input
                  id="new_subscriber_notification"
                  type="checkbox"
                  className="sr-only peer "
                  onChange={(e) => toggleSwitch(e)}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white py-1 w-full flex items-center gap-3 rounded-lg">
          <div className="w-[80%]">
            <div className="w-[94%] mx-auto py-[6px]">
              <h2 className="capitalize text-lg ">subscription renewal</h2>
              <p className="text-[#898D9E] text-base py-1">
                The subscription renewal notification are the notifications you
                get when a user renew their subscribtion
              </p>
            </div>
          </div>

          <div className="w-[20%]">
            <div className="w-[94%] mx-auto flex items-center gap-2">
              <label className="flex relative items-center gap-4">
                <span className="capitalize text-base text-[#898D9E] font-medium">
                  notify me
                </span>
                <div className="w-[50px] h-6 bg-gray-300 flex items-center cursor-pointer rounded-full p-[1px]">
                  <div
                    className={`w-[22px] h-[22px] rounded-full border-primary border transition-transform cursor-pointer transform flex items-center justify-center ${
                      formState?.subscription_renewal_notification === 1
                        ? "translate-x-6"
                        : "translate-x-0"
                    }  ${
                      formState?.subscription_renewal_notification === 1
                        ? "bg-primary"
                        : "bg-white"
                    }`}
                  >
                    <IoCheckmarkSharp size={15} color="#fff" />
                  </div>
                </div>
                <input
                  id="subscription_renewal_notification"
                  type="checkbox"
                  className="sr-only peer "
                  onChange={(e) => toggleSwitch(e)}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white py-1 w-full flex items-center gap-3 rounded-lg">
          <div className="w-[80%]">
            <div className="w-[94%] mx-auto py-[6px]">
              <h2 className="capitalize text-lg ">account deactivation</h2>
              <p className="text-[#898D9E] text-base py-1">
                The account deactivation notification are the notifications you
                get when a user rdeactivate their account
              </p>
            </div>
          </div>

          <div className="w-[20%]">
            <div className="w-[94%] mx-auto flex items-center gap-2">
              <label className="flex relative items-center gap-4">
                <span className="capitalize text-base text-[#898D9E] font-medium">
                  notify me
                </span>
                <div className="w-[50px] h-6 bg-gray-300 flex items-center cursor-pointer rounded-full p-[1px]">
                  <div
                    className={`w-[22px] h-[22px] rounded-full border-primary border transition-transform cursor-pointer transform flex items-center justify-center ${
                      formState?.account_deactivation_notification === 1
                        ? "translate-x-6"
                        : "translate-x-0"
                    }  ${
                      formState?.account_deactivation_notification === 1
                        ? "bg-primary"
                        : "bg-white"
                    }`}
                  >
                    <IoCheckmarkSharp size={15} color="#fff" />
                  </div>
                </div>
                <input
                  id="account_deactivation_notification"
                  type="checkbox"
                  className="sr-only peer "
                  onChange={(e) => toggleSwitch(e)}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white py-1 w-full flex items-center gap-3 rounded-lg">
          <div className="w-[80%]">
            <div className="w-[94%] mx-auto py-[6px]">
              <h2 className="capitalize text-lg ">prolong inactivity</h2>
              <p className="text-[#898D9E] text-base py-1">
                The prolong inactivity notification are the notifications you
                get when a user extends period of inactivity
              </p>
            </div>
          </div>

          <div className="w-[20%]">
            <div className="w-[94%] mx-auto flex items-center gap-2">
              <label className="flex relative items-center gap-4">
                <span className="capitalize text-base text-[#898D9E] font-medium">
                  notify me
                </span>
                <div className="w-[50px] h-6 bg-gray-300 flex items-center cursor-pointer rounded-full p-[1px]">
                  <div
                    className={`w-[22px] h-[22px] rounded-full border-primary border transition-transform cursor-pointer transform flex items-center justify-center ${
                      formState?.prolong_inactivity_notification === 1
                        ? "translate-x-6"
                        : "translate-x-0"
                    }  ${
                      formState?.prolong_inactivity_notification === 1
                        ? "bg-primary"
                        : "bg-white"
                    }`}
                  >
                    <IoCheckmarkSharp size={15} color="#fff" />
                  </div>
                </div>
                <input
                  id="prolong_inactivity_notification"
                  type="checkbox"
                  className="sr-only peer "
                  onChange={(e) => toggleSwitch(e)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSetting;
