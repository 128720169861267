import React, { useEffect } from "react";
import { fetchCategory } from "../Features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Shimmer from "./Shimmer";

const AddDassQuest = ({
  onCancle,
  editMode,
  handleUpdate,
  handleCreate,
  categories,
  loading,
  formState,
  handleChange,
  handleCheckboxChange,
}) => {
  const dispatch = useDispatch();
  const Categories = useSelector((state) => state.category.data);
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  return (
    <div className="w-full py-2 bg-white shadow-custom rounded-xl">
      <div className="w-[95%] mx-auto">
        <h1 className="text-lg font-medium text-primary ">
          {editMode ? "Update" : "Add"} DASS-21 question
        </h1>
      </div>

      <form>
        <div className="w-[95%] mx-auto flex items-start gap-5">
          <div className="flex flex-col items-start w-full">
            <h3 className="capitalize">question</h3>
            <div className="flex flex-col items-start w-full">
              <input
                type="text"
                className="border-[2px] pl-3 h-[30px] my-1 w-[80%] rounded-xl"
                name="question"
                id="question"
                value={formState?.question}
                onChange={(e) => handleChange(e)}
              />
              <label className="text-[#94A3B8]  capitalize text-sm">
                enter question
              </label>
            </div>
          </div>
          <div className="w-full">
            <h3 className="capitalize">Category</h3>
            <p className="text-[#94A3B8]  capitalize text-sm my-2">
              Check associated categories
            </p>
            <div className="grid grid-cols-3  w-full">
              {Categories.map((category, index) => (
                <div
                  key={category.id}
                  className="px-3 flex items-center gap-2 mb-2"
                >
                  <input
                    type="checkbox"
                    name={`selectedCategories[${index}]`}
                    value={category.id}
                    checked={categories?.includes(category.id)}
                    onChange={() => handleCheckboxChange(category?.id)}
                    className="w-5 h-5"
                  />
                  <label className="w-full text-sm">{category.category}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[95%] mx-auto mt-10 ">
          <div className="flex items-center gap-10 my-7">
            <button
              className={`px-4 py-[6px] text-base text-white rounded-xl ${
                loading ? "bg-[#898D9E]" : "bg-primary"
              }`}
              onClick={editMode ? handleUpdate : handleCreate}
              disabled={loading}
            >
              {loading ? (
                <Shimmer height={20} color={"#ff"} />
              ) : editMode ? (
                "Update Question"
              ) : (
                "Add question"
              )}
            </button>
            <button
              className="bg-[#898D9E] px-5 capitalize py-[4px] rounded-xl text-white text-base"
              onClick={onCancle}
            >
              cancle
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDassQuest;
