import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const AddNewArticle = createAsyncThunk(
  "articles/createNewArticles",
  async (formData) => {
    const userToken = localStorage.getItem("userToken");

    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(`${BASE_URL}/admin/articles`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const createArticleSlice = createSlice({
  name: "addNewArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNewArticle.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(AddNewArticle.fulfilled, (state) => {
        state.status = "successed";
      })
      .addCase(AddNewArticle.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});

export default createArticleSlice.reducer;
