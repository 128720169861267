import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import { PlusIcon } from "../Assets";
import ResourceListHead from "./ResourceListHead";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewBlog,
  UpdateTheBlog,
  deleteBlog,
  getBlog,
} from "../Features/BlogSlice";
import Modal from "./Modal";
import { ToastContainer, toast } from "react-toastify";
import BlogForm from "./BlogForm";
import { getBlogCategories } from "../Features/BlogCategoriesSlice";
import ReactPagination from "./ReactPagination";

const ThStyle = `py-2 px-3 text-primary`;
const TdStyle = `border-b border-inactiveText bg-trasnparent p-2 text-center text-sm font-medium text-[#2E2F32]`;

const BlogPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [loading, setLoading] = useState(null);
  const [formState, setFormState] = useState({
    title: "",
    author: "",
    duration: "",
  });
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const blogData = useSelector((state) => state.blog.blogData);
  const blogCategoriesArray = useSelector(
    (state) => state.blogCategories.blogCategoriesData.data
  );
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  useEffect(() => {
    dispatch(getBlog());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getBlogCategories());
  }, [dispatch]);

  // // // // // // selectedItem // // // // // // //
  const blogSelect = (item) => {
    setSelectedItem(item);
    setModalIsOpen(!modalIsOpen);
  };

  // // // // // // DELETE BLOG // // // // // // //
  const handleDelete = (e) => {
    setLoading(true);
    dispatch(deleteBlog({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        setModalIsOpen(!modalIsOpen);
        showToast("Blog Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // Add New Blog // // // // // // //
  const openForm = () => {
    setFormIsOpen(!formIsOpen);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues?.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const createBlog = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      body: editorRef.body.target.getContent(),
    };

    const formData = new FormData();
    formData.append("photo", selectedFile);

    if (payLoad.title === "") {
      alert("Title field is required");
    } else if (payLoad.author === "") {
      alert("Author field is required");
    } else if (payLoad.body === "") {
      alert("Blog body field is required");
    } else if (payLoad.categories.length < 1) {
      alert("please select categorires");
    } else if (payLoad.duration === "") {
      alert("Duration is required");
    } else if (!selectedFile) {
      alert("please select an image file");
    } else {
      Object.entries(payLoad).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((element) => {
            formData.append(`${key}[]`, element);
          });
        } else {
          formData.append(key, value);
        }
      });
      setLoading(true);
      dispatch(AddNewBlog(formData))
        .unwrap()
        .then(() => {
          showToast(`A new blog created successfully`);
        })
        .then(() => {
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2100);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // Update Blog // // // // // // //
  const forUpate = () => {
    setEditMode(!editMode);
    setFormIsOpen(!formIsOpen);
  };
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = blogCategoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, blogCategoriesArray]);
  const updateBlog = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const updateData = {
      id: selectedItem.id,
      title: formState.title,
      body: editorRef.body.target.getContent(),
      duration: formState.duration,
    };
    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("photo", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    if (selectedItem.id !== null) {
      setLoading(true);
      dispatch(UpdateTheBlog({ id: selectedItem.id, formData: formData }))
        .unwrap()
        .then(() => {
          showToast(`updated successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // Toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      <ToastContainer />
      <div className="flex justify-end">
        <Button
          btnStyle={"bg-primary"}
          btnText="Add new blog"
          btnIcon={PlusIcon}
          btnClick={openForm}
        />
      </div>

      <div className="bg-white w-full p-5 rounded-lg shadow-lg">
        <ResourceListHead resourceListType="Blogs" />
        <div className="w-full overflow-x-scroll py-3 px-5">
          <table className="w-full table-auto">
            <thead className="text-center bg-[#F6F7FA]">
              <tr>
                <th className={ThStyle}>S/N</th>
                <th className={ThStyle}>Blog</th>
                <th className={ThStyle}>Categpry</th>
                <th className={ThStyle}>Last Updated</th>
              </tr>
            </thead>

            <tbody>
              {blogData?.data?.map((blog, index) => (
                <tr
                  className="cursor-pointer hover:bg-[#E9F3F3]"
                  onClick={() => blogSelect(blog)}
                  key={blog?.id}
                >
                  <td className={TdStyle}>{lastSerialNumber + index + 1}</td>
                  <td className={TdStyle}>{blog?.title}</td>
                  <td className={TdStyle}>{blog?.categories.join(", ")}</td>
                  <td className={TdStyle}>
                    {new Date(blog?.updated_at).toDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={blogData?.last_page}
              onChanges={(i) => {
                dispatch(getBlog(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    blogData?.data?.length * blogData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      {modalIsOpen ? (
        <div className="modalfloat">
          <div className="w-[60%] mx-auto">
            <Modal
              itemMedia={selectedItem.photo.url}
              body={selectedItem.body}
              item={selectedItem}
              loading={loading}
              updateAction={forUpate}
              cancelAction={() => setModalIsOpen(!modalIsOpen)}
              deleteitem={handleDelete}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {formIsOpen ? (
        <div className="blogfloat">
          <BlogForm
            editMode={editMode}
            formState={formState}
            editorRef={editorRef}
            loading={loading}
            categories={categories}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChange}
            handleFileChange={handleFileChange}
            handleCreate={createBlog}
            handleUpdate={updateBlog}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogPage;
