import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import categoryReducer from "../Features/CategorySlice";
import authReducer from "../Features/AuthSlice";
import adminReducer from "../Features/AdminSlice";
import dailyQuestionReducer from "../Features/DailySlice";
import selfReducer from "../Features/SelfRelectionSlice";
import basicQuestionReducer from "../Features/BasicSlice";
import dassQuestionReducer from "../Features/DassSlice";
import dashboardReducer from "../Features/DashSlice";
import UserReducer from "../Features/UserSlice";
import articleReducer from "../Features/ArticleSlice";
import subReducer from "../Features/SubscribeSlice";
import postArticleReducer from "../Features/ArticlePostSlice";
import bookReducer from "../Features/BookSlice";
import postBookReducer from "../Features/BookPostSlice";
import audioReducer from "../Features/AudioSlice";
import postAudioReducer from "../Features/AudioPostSlice";
import videoReducer from "../Features/VideoSlice";
import podcastReducer from "../Features/PodcastSlice";
import createArticle from "../Features/CreateArticleSlice";
import articleSummary from "../Features/ArticleSummary";
import podcastSummary from "../Features/PodcastSummary";
import CreatePodcastSlice from "../Features/CreatePodcastSlice";
import bookSummary from "../Features/BookSummary";
import audioSummary from "../Features/AudioSummary";
import CreateAudioSlice from "../Features/CreateAudioSlice";
import videoSummary from "../Features/VideoSummary";
import quoteReducer from "../Features/QuoteSlice";
import tipReducer from "../Features/TipSlice";
import DailyPostReducer from "../Features/DailyPostSlice";
import readReflectReducer from "../Features/ReasReflectSlice";
import listenLearnReducer from "../Features/listenLearnSlice";
import learnDoReducer from "../Features/learnDoSlice";
import learnDoSummary from "../Features/LearnDoSummary";
import readReflectSummary from "../Features/ReadReflectSummary";
import listenLearnSummry from "../Features/ListenLearnSummry";
import quoteTipSummary from "../Features/QouteTipSummary";
import DassQuestPostReducer from "../Features/DassQuestPostSlice";
import subPlanReducer from "../Features/SubPlanSlice";
import blogReducer from "../Features/BlogSlice";
import blogCategoriesReducer from "../Features/BlogCategoriesSlice";
import dassOption from "../Features/DassOptionSlice";
import blogSummary from "../Features/BlogSummay";
import promoCode from "../Features/PromoCodeSlice";
import scopeReducer from "../Features/ScopeSlice";
import deactivtedUserReducer from "../Features/DeactivateUserSlice";
import freeTrialReducer from "../Features/FreeTrialSlice";
import freePackageReducer from "../Features/FreePackageSlice";
import goalsReducer from "../Features/GoalsSlice";
import ForgetPassReducer from "../Features/ForgetPassSlice";
import notificationReducer from "../Features/NotificationSlice";
import notificationSettingReducer from "../Features/NotificationSettings";
import distressReducer from "../Features/DistressSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  notification: notificationReducer,
  notificationSetting: notificationSettingReducer,
  distress: distressReducer,

  admin: adminReducer,
  category: categoryReducer,
  auth: authReducer,
  book: bookReducer,
  article: articleReducer,
  basic: basicQuestionReducer,
  daily: dailyQuestionReducer,
  dass: dassQuestionReducer,
  dassOption: dassOption,
  self: selfReducer,
  updateArticle: postArticleReducer,
  updateBook: postBookReducer,
  audio: audioReducer,
  updateAudio: postAudioReducer,
  video: videoReducer,
  podcasts: podcastReducer,
  newArticle: createArticle,
  newPodcast: CreatePodcastSlice,
  dash: dashboardReducer,
  user: UserReducer,
  articleSummary: articleSummary,
  podcastSummary: podcastSummary,
  audioSummary: audioSummary,
  subscribe: subReducer,
  bookSummary: bookSummary,
  videoSummary: videoSummary,
  newAudio: CreateAudioSlice,
  quote: quoteReducer,
  postDailyQuest: DailyPostReducer,
  // newQoute: createQuote,
  postDassQuest: DassQuestPostReducer,
  // newQoute: createQuote,
  tip: tipReducer,

  quoteTipSummary: quoteTipSummary,

  // strategis
  readReflect: readReflectReducer,
  listenLearn: listenLearnReducer,
  learnDo: learnDoReducer,
  learnDoSummary: learnDoSummary,
  readReflectSummary: readReflectSummary,
  listenLearnSummry: listenLearnSummry,
  subPlan: subPlanReducer,
  blog: blogReducer,
  blogCategories: blogCategoriesReducer,
  blogSummary: blogSummary,
  promoCode: promoCode,
  scope: scopeReducer,

  deactivtedUser: deactivtedUserReducer,
  freeTrial: freeTrialReducer,
  freePackage: freePackageReducer,

  goals: goalsReducer,
  forgetPass: ForgetPassReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
