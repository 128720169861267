import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const AddNewAdmin = createAsyncThunk(
  "admin/addNewAdmin",
  async (formData) => {
    const userToken = localStorage.getItem("userToken");
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Conection: "keep-alive",
        },

        body: formData,
      };

      const response = await fetch(`${BASE_URL}/admin/admins`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const newAdmin = createSlice({
  name: "newAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNewAdmin.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(AddNewAdmin.fulfilled, (state) => {
        state.status = "Fulfilled";
      })
      .addCase(AddNewAdmin.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});

export default newAdmin.reducer;
