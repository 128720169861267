import {
  AddNewLearnDo,
  DeleteActivity,
  DeleteLearnDo,
  PublishLearnDo,
  UpdateLearnDo,
  createActivity,
  getLearnDo,
  updateActivityCall,
} from "../Features/learnDoSlice";
import Button from "./Button";
import Shimmer from "./Shimmer";
import TableRow from "./TableRow";
import InputTag from "./InputTag";
import TableMain from "./TableMain";
import { PlusIcon } from "../Assets";
import ResourceList from "./ResourceList";
import StrategisForm from "./StrategisForm";
import StrategiesModal from "./StrategiesModal";
import ReactPagination from "./ReactPagination";
import StatusCode from "../Ultilities/StatusCode";
import ResourceListHead from "./ResourceListHead";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { learnDoSummary } from "../Features/LearnDoSummary";

const LearnDoActivities = () => {
  const dispatch = useDispatch();
  const learnDoData = useSelector((state) => state.learnDo.learnDo);
  const learnDoStatus = useSelector((state) => state.learnDo.status);
  const categoriesArray = useSelector((state) => state.category.data);
  const learnDoSummaryData = useSelector(
    (state) => state.learnDoSummary?.learnDoSummary
  );

  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [actEditMode, setActEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itForPublish, setItForPublish] = useState(false);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [actModalIsOpen, setActModalIsOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [openActivityForm, setOpenActivityForm] = useState(false);
  const [formState, setFormState] = useState({
    title: "",
    subscription_level: 0,
    activity_title: "",
    activity_overview: "",
  });
  const [actFormState, setActFormState] = useState({
    title: "",
    example: "",
    post_text: "",
    overview: "",
  });
  const [questionValues, setQuestionValues] = useState([
    {
      question: "",
      answer_type: "text",
      number_of_list: null,
      minimum: null,
      maximum: null,
    },
  ]);

  useEffect(() => {
    dispatch(learnDoSummary());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getLearnDo(lastSerialNumber));
  }, [dispatch, lastSerialNumber]);

  const editorRefs = {
    overview: useRef(null),
    activity_overview: useRef(null),
  };

  const openTheModal = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeTheModal = (item) => {
    setModalIsOpen(!modalIsOpen);
  };
  const handleFormOpen = (e) => {
    e.preventDefault();
    setOpenForm(!openForm);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  // // // // // SUMMARY // // // // //
  const popularLearnDo = learnDoSummaryData?.data;

  // // // // // Fetch // // // // //
  const allStrategies = learnDoData?.data?.map((learnDo, index) => (
    <TableRow
      key={learnDo.id}
      {...learnDo}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(learnDo.created_at).toDateString()}
      subscription_level={new Date(learnDo.updated_at).toDateString()}
      tableRowclick={() => openTheModal(learnDo)}
    />
  ));

  // // // // // // Create Learn & Do // // // // //
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
    setActFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  const createLearnDo = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const payLoad = {
      ...formState,
      categories,
      overview: editorRefs.overview?.current.target.getContent(),
    };
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields.");
      return;
    } else if (!selectedFile) {
      return alert("please select an image file");
    }

    formData.append("photo", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(AddNewLearnDo(formData))
      .unwrap()
      .then(() => {
        showToast(
          `A new Listen & Learn titiled ${formState.title} has been added successfully`
        );
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // // Update Learn & Do // // // // //
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setOpenForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updateLearnDo = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const updateData = {
      id: selectedItem.id,
      title: formState.title,
      activity_title: formState.activity_title,
      activity_overview: formState.activity_overview,
      subscription_level: formState.subscription_level === "Basic" ? 0 : 1,
      overview: editorRefs.overview?.current.target.getContent(),
    };

    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("photo", selectedFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    if (selectedItem.id !== null) {
      setLoading(true);
      dispatch(UpdateLearnDo({ id: selectedItem.id, formData: formData }))
        .unwrap()
        .then(() => {
          showToast(
            `Read & Reflect titiled ${formState.title} has been updated successfully`
          );
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // Delete lEARN & DO // // // // //
  const handleDeleteLearnDo = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };
    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(DeleteLearnDo({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setModalIsOpen(!modalIsOpen);
          showToast("A Learn & Do Deleted successfully.");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  // // // // // // ADD ACTIVITIES// // // // //
  const openActFormClick = (e) => {
    e.preventDefault();
    setOpenActivityForm(!openActivityForm);
    setModalIsOpen(!modalIsOpen);
  };
  const addActivityWrap = () => {
    setQuestionValues((prevValues) => [
      ...prevValues,
      { question: "", answer_type: "text" },
    ]);
  };
  const removeActivityWrap = () => {
    setQuestionValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.pop();
      return updatedValues;
    });
  };
  const questionChange = (e, index) => {
    if (actEditMode) {
      const { name, value } = e.target;
      const updatedQuestions = [...questionValues];
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [name]: value,
      };
      setQuestionValues(updatedQuestions);
    } else {
      const newValues = [...questionValues];
      newValues[index][e.target.name] = e.target.value;
      setQuestionValues(newValues);
    }
  };
  const validateActivityForm = (obj, exceptions = []) => {
    for (const key in obj) {
      if (
        !exceptions.includes(key) &&
        (obj[key] === undefined || obj[key] === "")
      ) {
        return true;
      }
    }
    return false;
  };
  const hasListAnswerType = (questions) => {
    return questions.some(
      (question) =>
        question.answer_type === "list" &&
        (question.number_of_list === null || !question.number_of_list)
    );
  };
  const hasNumberAnswerType = (questions) => {
    return questions.some(
      (question) =>
        question.answer_type === "number" &&
        (question.minimum === null ||
          question.maximum === null ||
          !question.minimum ||
          !question.maximum)
    );
  };
  const checkValidNumber = (questions) => {
    return questions.some((question) =>
      question.answer_type === "number"
        ? question.minimum < 1 || question.minimum >= question.maximum
        : question.answer_type === "list"
        ? question.number_of_list < 1
        : ""
    );
  };
  const submitActivity = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedItem.id,
      ...actFormState,
      // overview: editorRefs.activity_overview?.current?.target.getContent(),
    };

    if (questionValues[0].question !== "") {
      payLoad["questions"] = questionValues; // questions get added to payload object here
    }
    const exceptions = ["example", "post_text"];
    if (validateActivityForm(payLoad, exceptions)) {
      alert("Please fill in all required fields.");
      return;
    } else if (payLoad.questions) {
      if (hasListAnswerType(payLoad.questions)) {
        alert("Number of list is required for questions with list answer type");
        return;
      } else if (hasNumberAnswerType(payLoad.questions)) {
        alert(
          "Minimum and Maximum values are required for questions with number answer type."
        );
        return;
      } else if (checkValidNumber(payLoad.questions)) {
        alert(
          "Number must start from 1, maximum value must be grater than minimum value"
        );
      }
    }
    setLoading(true);
    dispatch(createActivity(payLoad))
      .unwrap()
      .then(() => {
        showToast(`A new Activity has been added successfully`);
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // SELECTED ACTIVITY FOR DISPLAY AND EDIT //
  const openTheActModal = (item) => {
    setActModalIsOpen(!actModalIsOpen);
    setSelectedActivity(item);
  };
  const openEditActiviy = () => {
    setActEditMode(!actEditMode);
    setOpenActivityForm(true);
    setModalIsOpen(false);
  };
  useEffect(() => {
    if (!actEditMode) return;
    setActFormState(selectedActivity);
    setQuestionValues(selectedActivity?.questions || []);
  }, [actEditMode, selectedActivity]);

  // // // // // // UPDATED ACTIVITIES// // // // //
  const updateSelectedActivity = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedActivity?.id,
      title: actFormState.title,
      post_text: actFormState.post_text,
      example: actFormState.example,
      overview: actFormState.overview,
    };

    const exceptions = ["example", "post_text"];
    if (validateActivityForm(payLoad, exceptions)) {
      alert("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    dispatch(updateActivityCall(payLoad))
      .unwrap()
      .then(() => {
        showToast(`Activity has been updated successfully`);
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // // UPDATED QUESTION IN ACTIVITIES// // // // //

  // // // // // // DELETE ACTIVITIES// // // // //
  const handleDeleteActivity = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(DeleteActivity({ id: selectedActivity?.id }))
      .unwrap()
      .then(() => {
        showToast("Activity Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // PUBLISH UNPUBLISH // // // // // //
  const publishUnpublish = (e) => {
    e.preventDefault();
    if (selectedItem.id !== null) {
      setItForPublish(true);
      dispatch(PublishLearnDo({ id: selectedItem.id }))
        .unwrap()
        .then(() => {
          showToast(
            selectedItem.published == "1"
              ? "Strategy unpublished successfully"
              : "Strategy published successfully"
          );
          setItForPublish(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => showToast("error:", error));
    }
  };

  return (
    <>
      {/* // // // // // // ACTIVITIES // // // // // // */}
      <div
        className={`formActivities bg-mainWhite rounded-lg ${
          openActivityForm ? "" : "hidden"
        }`}
      >
        <div className="border-inactiveText border-b-[1px] py-3 px-10">
          <h1 className="text-primary text-lg">
            {actEditMode ? "Update Activity" : "Add Activity"}
          </h1>
        </div>

        <div className="px-10 mt-5">
          <div className="w-1/2">
            <InputTag
              labelText="Title"
              feildPlaceHoler="Enter title"
              labelFor="title"
              inputType="text"
              forValue={actFormState.title}
              forOnChange={(e) => handleChange(e)}
            />
          </div>

          <div className="my-3">
            <label htmlFor="overview">Overview</label>
            <br />
            <textarea
              name="overview"
              id="overview"
              value={actFormState.overview}
              onChange={(e) => handleChange(e)}
              className="border-2 px-3 py-1 border-inactiveText w-full h-[120px] rounded-lg"
            ></textarea>
          </div>

          <div className="my-3">
            <label htmlFor="example">Example (optional)</label>
            <br />
            <textarea
              name="example"
              id="example"
              value={actFormState.example}
              onChange={(e) => handleChange(e)}
              className="border-2 px-3 py-1 border-inactiveText w-full h-[120px] rounded-lg"
            ></textarea>
          </div>

          <div className="w-1/2">
            <InputTag
              labelText="Post Text (Optional)"
              feildPlaceHoler="Enter post text"
              labelFor="post_text"
              inputType="text"
              forValue={actFormState.post_text}
              forOnChange={(e) => handleChange(e)}
            />
          </div>

          {actEditMode ? (
            ""
          ) : (
            <div className="my-20">
              <h1 className=" text-2xl font-bold">Question</h1>

              <div className="gap-10gridgrid-cols-2 mt-5">
                {questionValues?.map((_, index) => (
                  <div key={index} className="my-5">
                    <label htmlFor="question">{`Activity Question ${
                      index + 1
                    } (Optional)`}</label>
                    <br />
                    <textarea
                      name="question"
                      id="question"
                      placeholder="Enter Question"
                      value={questionValues[index]?.question || ""}
                      onChange={(e) => questionChange(e, index)}
                      className="border-2 px-3 py-1 border-inactiveText w-full h-[120px] rounded-lg"
                    ></textarea>
                    <div className="flex gap-10">
                      <div>
                        <label htmlFor="answer_type">{`Select answer type for question ${
                          index + 1
                        }`}</label>{" "}
                        <br />
                        <select
                          name="answer_type"
                          id="answer_type"
                          value={questionValues[index]?.answer_type || "text"}
                          onChange={(e) => questionChange(e, index)}
                          className="w-[70%] p-1 rounded-lg border-2"
                        >
                          <option value="text">Text</option>
                          <option value="longtext">Long Text</option>
                          <option value="time">Time</option>
                          <option value="list">List</option>
                          <option value="number">Number</option>
                        </select>
                      </div>

                      {questionValues[index]?.answer_type === "list" ? (
                        <div>
                          <InputTag
                            labelFor={"number_of_list"}
                            labelText={`Number_of_list for question ${
                              index + 1
                            }`}
                            inputType={"number"}
                            forValue={questionValues[index]?.number_of_list}
                            forOnChange={(e) => questionChange(e, index)}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {questionValues[index]?.answer_type === "number" ? (
                        <div className="flex gap-10">
                          <InputTag
                            labelFor={"minimum"}
                            labelText={`Minimum value for question ${
                              index + 1
                            }`}
                            inputType={"number"}
                            forValue={questionValues[index]?.minimum}
                            forOnChange={(e) => questionChange(e, index)}
                          />
                          <InputTag
                            labelFor={"maximum"}
                            labelText={`Maximum value for question ${
                              index + 1
                            }`}
                            inputType={"number"}
                            forValue={questionValues[index]?.maximum}
                            forOnChange={(e) => questionChange(e, index)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={"my-5 flex items-center gap-5"}>
                <Button
                  btnClick={addActivityWrap}
                  btnText="Add more question"
                  btnStyle="bg-[#78B7C5]"
                  btnIcon={PlusIcon}
                />
                {questionValues?.length > 1 ? (
                  <Button
                    btnClick={removeActivityWrap}
                    btnText="Remove one question"
                    btnStyle="bg-inactiveText"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div className="my-5 pb-7 flex items-center gap-10">
            <Button
              btnClick={actEditMode ? updateSelectedActivity : submitActivity}
              btnText={
                loading ? (
                  <Shimmer height={20} color={"#fff"} />
                ) : actEditMode ? (
                  "Update Activity"
                ) : (
                  "Add Activity"
                )
              }
              btnStyle="bg-primary"
            />
            <Button
              btnClick={closeAddForm}
              btnText="Cancel"
              btnStyle="bg-inactiveText"
            />
          </div>
        </div>
      </div>

      {/* // // // // // // ADD / UPATE FORM // // // // // // */}
      <div
        className={`form ${
          openForm ? "" : "hidden"
        } bg-mainWhite pb-5 rounded-lg`}
      >
        <StrategisForm
          strategisType={"Learn & Do"}
          formState={formState}
          categories={categories}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editorRefs={editorRefs}
          editMode={editMode}
          currentSub={selectedItem?.subscription_level}
          // selectedItem={selectedItem}
        />

        <div className="w-1/2 px-5 mb-5">
          <InputTag
            labelFor="activity_title"
            labelText="Activity Title"
            inputType="text"
            forValue={formState?.activity_title}
            forOnChange={(e) => handleChange(e)}
          />
        </div>

        <div className="px-5 ">
          <label htmlFor="protocols" className="mb-5">
            Add Activity Overview
          </label>
          <textarea
            name="activity_overview"
            id="activity_overview"
            value={formState?.activity_overview}
            onChange={(e) => handleChange(e)}
            className=" h-[150px] w-full rounded-lg border-2 py-3 px-5"
          ></textarea>
        </div>

        <div className="px-5 flex gap-10 mt-10">
          <Button
            btnClick={editMode ? updateLearnDo : createLearnDo}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Learn & Do"
              ) : (
                "Add Learn & Do"
              )
            }
            btnStyle="bg-primary"
            btnIcon={PlusIcon}
          />

          <Button
            btnClick={closeAddForm}
            btnText="Cancle"
            btnStyle="bg-inactiveText"
          />
        </div>
      </div>

      {/* // // // // // // ITEM MODAL // // // // // // */}
      <div className={`${modalIsOpen ? "" : "hidden"}`}>
        {selectedItem && (
          <StrategiesModal
            itemMedia={selectedItem?.photo?.url}
            title={selectedItem?.title}
            categories={selectedItem?.categories}
            overview={selectedItem?.overview}
            content={selectedItem?.protocols}
            subscription_level={selectedItem?.subscription_level}
            updated_at={new Date(selectedItem?.updated_at).toDateString()}
            moreAddition="Add Activity"
            updateAction={toggleEditMode}
            addReflectionAction={openActFormClick}
            cancelAction={closeTheModal}
            deleteitem={handleDeleteLearnDo}
            loading={loading}
            activities={selectedItem?.activities}
            selectedActivity={selectedActivity}
            openTheActModal={openTheActModal}
            actModalIsOpen={actModalIsOpen}
            updateActivity={openEditActiviy}
            deleteActivity={handleDeleteActivity}
            itForPublish={itForPublish}
            publishStatus={selectedItem?.published}
            publishClick={publishUnpublish}
          />
        )}
      </div>

      <div
        className={`relative flex flex-col gap-5 ${openForm ? "hidden" : ""} ${
          openActivityForm ? "hidden" : ""
        } ${modalIsOpen ? "hidden" : ""}`}
      >
        <div className="ml-auto">
          <Button
            btnText="Add Learn & Do"
            btnIcon={PlusIcon}
            btnStyle="bg-primary"
            btnClick={handleFormOpen}
          />
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular Strategies</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularLearnDo?.map((strategy) => (
              <div key={strategy.id}>
                <ResourceList
                  {...strategy}
                  photo={strategy?.photo?.url}
                  publication_date={new Date(
                    strategy?.updated_at
                  ).toDateString()}
                  level={strategy.plan}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead
            resourceListType="Learn & Do"
            placeholder="Search Learn & do"
          />

          {learnDoStatus === StatusCode.LOADING ? (
            <div>
              <Shimmer height={38} width={38} color={"#207384"} />
            </div>
          ) : (
            <TableMain
              resourceType="Strategies"
              TableRows={allStrategies}
              subLevel="Last Updated"
            />
          )}

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={learnDoData?.last_page}
              onChanges={(i) => {
                dispatch(getLearnDo(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allStrategies.length * learnDoData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LearnDoActivities;
