import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  basic: [],
  error: null,
};

const userToken = localStorage.getItem("userToken");
const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchBasicQuest = createAsyncThunk("get/basicQuest", async () => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/basic-questions`,
      config
    );

    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const basicQuestionSlice = createSlice({
  name: "basic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBasicQuest.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchBasicQuest.fulfilled, (state, { payload }) => {
        state.basic = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchBasicQuest.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default basicQuestionSlice.reducer;

// // // // // // // create basic quetion  // // // // // //
export const postBasicQuest = createAsyncThunk(
  "post/dailyQues",
  async (data) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(`${BASE_URL}/admin/basic-questions`, config);

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // // Delete basic quetion  // // // // // //
export const deleteBasicQuest = createAsyncThunk(
  "delete/question",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/basic-questions/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE // // // // // //
export const updateBasicQuest = createAsyncThunk("update/", async (data) => {
  try {
    const config = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(
      `${BASE_URL}/admin/basic-questions/${data.id}`,
      config
    );

    //   success
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

// // // // // // // create Options  // // // // // //
export const postOptions = createAsyncThunk("post/dailyQues", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      redirect: "manual",
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${BASE_URL}/admin/basic-question-options`,
      config
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});
