import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  articleSummary: {},
  error: null,
};

export const articleSummary = createAsyncThunk("get/getsummary", async () => {
  const userToken = localStorage.getItem("userToken");

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/admin/articles-summary`,
    config
  );
  const final = response.data.data;
  return final;
});

const articleSummarySlice = createSlice({
  name: "articleSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(articleSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(articleSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.articleSummary = payload;
      });
  },
});

export default articleSummarySlice.reducer;
