import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  learnDoSummary: {},
  error: null,
};
export const learnDoSummary = createAsyncThunk("get/getsummary", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/learn-and-do-summary`,
    config
  );
  const final = response.data;
  return final;
});
const learnDoSummarySlice = createSlice({
  name: "learnDoSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(learnDoSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(learnDoSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.learnDoSummary = payload;
      });
  },
});

export default learnDoSummarySlice.reducer;
