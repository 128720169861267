import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { fetchSubscription } from "../Features/SubscribeSlice";
import { useSelector, useDispatch } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonoughtChart = () => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state?.subscribe?.sub_summary);
  const Chartdata = Data?.subscription_summary;

  const options = {
    plugins: {
      tooltip: {
        titleFont: {
          size: 20,
        },
        bodyFont: {
          size: 20,
        },
      },
      legend: {
        display: true,
        responsive: true,
        position: "left",
        labels: {
          boxWidth: 20,
          boxHeight: 10,
          padding: 10,
          font: {
            size: 14,
          },
        },
      },
    },
  };
  const data = {
    labels: Chartdata?.map((data) => data?.package),
    datasets: [
      {
        label: "tot",
        data: Chartdata?.map((data) => data?.percentage),
        backgroundColor: ["#207384", "rgba(54, 162, 235, 1", "#78B7C5"],
        borderColor: ["rgba(54, 162, 235, 1)", "#207384", "#78B7C5"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  return (
    <div>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonoughtChart;
