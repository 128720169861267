import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const AddNewBook = createAsyncThunk(
  "articles/createNewBook",
  async (formData) => {
    const userToken = localStorage.getItem("userToken");

    try {
      const config = {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };
      const response = await fetch(`${BASE_URL}/admin/books`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const createBookSlice = createSlice({
  name: "addNewBook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNewBook.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(AddNewBook.fulfilled, (state) => {
        state.status = "successed";
      })
      .addCase(AddNewBook.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});

export default createBookSlice.reducer;
