import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  quoteTipSummary: {},
  error: null,
};
export const quoteTipSummary = createAsyncThunk("get/getsummary", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/quotes-summary`, config);
  const final = response.data;
  return final;
});
const quoteTipSummarySlice = createSlice({
  name: "quoteTipSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(quoteTipSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(quoteTipSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.quoteTipSummary = payload;
      });
  },
});

export default quoteTipSummarySlice.reducer;
