import React, { useEffect, useState } from "react";
import DonoughtChart from "./DonoughtChart";
import CountUp from "react-countup";
import { DummyUserImage, PlusIcon } from "../Assets";
import { fetchSubscriber, fetchSubscription } from "../Features/SubscribeSlice";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ReactPagination from "./ReactPagination";
import { Shimmer } from "../Components";
import StatusCode from "../Ultilities/StatusCode";
import { fetchSubPlan } from "../Features/SubPlanSlice";
import { toast } from "react-toastify";

const Subs = ({ showPlan }) => {
  const theSubPlan = useSelector((state) => state?.subPlan?.data?.data);
  const [selectedItem, setSelectedItem] = useState(theSubPlan?.[0]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("initialPage")) || 1
  );
  const dispatch = useDispatch();
  const getTotal = useSelector((state) => state.subscribe.sub_summary);
  const {
    subscribers: subItem,
    allPages,
    itemPerPage,
    status2,
  } = useSelector((state) => state.subscribe);
  const Total = getTotal.total_users;
  const Recent = getTotal.recent_subscribers;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    localStorage.setItem("initialPage", newPage);
  };

  const serialNum = (currentPage - 1) * itemPerPage + 1;
  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSubscriber(currentPage));
  }, [currentPage, dispatch]);

  // // // // // // sub pakage and action // // // // // //
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);
  const pickPakage = (item) => {
    setSelectedItem(item);
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <div className="flex relative items-center justify-between my-6">
        <button
          className="bg-primary py-[6px] px-5 rounded-xl text-white text-sm absolute top-[-54px] right-0 flex items-center gap-2"
          onClick={showPlan}
        >
          <img src={PlusIcon} alt="" />
          Create plan
        </button>
      </div>

      <div className="w-full flex items-start mt-12 justify-between">
        <div className="w-[70%] flex items-start gap-5">
          <div className="w-1/2 rounded-lg bg-white">
            <h1 className="px-5 py-3">Plans</h1>
            <table className="table  divide-y  divide-gray-200 w-full">
              <thead className="bg-[#ECECEC]">
                <tr>
                  <th className="text-primary ">S/N</th>
                  <th className="text-primary ">Subscription plan</th>
                  <th className="text-primary ">Status</th>
                </tr>
              </thead>

              <tbody>
                {theSubPlan?.map((item, index) => (
                  <tr
                    className="text-center cursor-pointer hover:bg-primary"
                    onClick={() => pickPakage(item)}
                  >
                    <td className="py-2 capitalize">{index + 1}</td>
                    <td className="py-2 capitalize">{item?.package}</td>
                    <td className="py-2 capitalize">Active</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="w-1/2 rounded-lg bg-white">
            <h1 className="px-5 py-3">{selectedItem?.package}</h1>
            <table className="table  divide-y  divide-gray-200 w-full">
              <thead className="bg-[#ECECEC]">
                <tr>
                  <th className="text-primary ">Payment plan</th>
                  <th className="text-primary ">Price</th>
                </tr>
              </thead>

              <tbody>
                {selectedItem?.payment_plans?.map((item, index) => (
                  <tr className="text-center">
                    <td className="py-2 capitalize">{item?.duration_type}</td>
                    <td className="py-2 capitalize">$ {item?.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-[28%] rounded-lg bg-white">
          <div className="w-[90%] mx-auto py-2">
            <h2 className="">Total No, of Users</h2>
            <h4 className="mt-1 font-bold text-[24px]">
              <CountUp separator="," start={0} end={Total} duration={2.5} />
            </h4>
            <div className="flex items-center">
              <div className="w-[98%]">
                <DonoughtChart />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-start mt-7 justify-between">
        <div className="w-[70%] rounded-lg bg-white">
          <div className="w-[90%] mx-auto mt-3 mb-4 ">
            <h3 className="text-base font-semibold">Subscribers</h3>
          </div>
          <div className="w-full pb-3 relative">
            <table className="table w-full mx-auto divide-y divide-gray-200 ">
              <thead className="bg-[#ECECEC]">
                <tr>
                  <th className="text-primary ">S/N</th>
                  <th className="text-primary ">Subscribers</th>
                  <th className="text-primary ">Status</th>
                  <th className="text-primary ">Plan</th>
                  <th className="text-primary ">Start Date</th>
                  <th className="text-primary ">End Date</th>
                </tr>
              </thead>
              {status2 === StatusCode.LOADING ? (
                <div className="w-full flex items-center justify-center absolute ">
                  <Shimmer height={24} color={"#207384"} />
                </div>
              ) : (
                <tbody>
                  {subItem?.map((data, index) => (
                    <tr
                      className="text-center border-b hover:bg-[#E9F3F3]"
                      key={data.id}
                    >
                      <td className="border-r border-gray-400">
                        {serialNum + index}
                      </td>
                      <td className="border-r border-gray-400 flex items-center  justify-center gap-5">
                        <div className="w-[40px] h-[40px] flex items-center justify-center">
                          <img
                            src={data.profile_photo || DummyUserImage}
                            alt="profile"
                            className="w-[80%] h-[80%] rounded-full"
                          />
                        </div>
                        <div className="w-[80%] ml-[-25px]">
                          <h3 className="capitalize text-sm font-semibold">
                            {data.subscriber}
                          </h3>
                          <p className="text-[10px] text-[#898D9E] ">
                            {data.email}
                          </p>
                        </div>
                      </td>
                      <td className="border-r border-gray-400 text-sm capitalize">
                        {data.status == "1" ? "Active" : "Unactive"}
                      </td>
                      <td className="border-r border-gray-400 text-sm">
                        {data?.subscription_package?.package}
                      </td>
                      <td className="border-r border-gray-400 font-semibold text-sm">
                        {data.start_date}
                      </td>
                      <td className="border-r border-gray-400 font-semibold text-sm">
                        {data.end_date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          <div className="mt-1 mb-3 flex justify-end items-end w-[96%] mx-auto">
            <ReactPagination
              total={allPages}
              onChanges={handlePageChange}
              forcePage={currentPage - 1}
            />
          </div>
        </div>

        <div className="w-[28%] h[565px] overflow-y-auto rounded-lg bg-white">
          <div className="w-[93%] mx-auto mt-3">
            <h2 className="mb-3 text-base font-semibold">
              10 Recent subscription
            </h2>
            {Recent?.slice(0, 10).map((data) => (
              <div
                className="w-full flex items-center justify-between border-b py-[3px] my-[1px]"
                key={data.user_id}
              >
                <div className="w-[18%] ">
                  <img
                    src={data.icon || DummyUserImage}
                    alt={`${data.subscriber}`}
                    className="rounded-full w-[80%] "
                  />
                </div>
                <div className="w-[80%] ">
                  <div className="flex items-center justify-between w-full">
                    <h3 className="font-semibold text-base">
                      {data.subscriber}
                    </h3>
                    <p className="font-semibold text-base">
                      ${data.amount_paid}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <p className="text-sm text-[#898D9E] ">
                      {moment(data.subscription_time).startOf("ss").fromNow()}
                    </p>
                    <p className="text-sm text-[#898D9E]">{data.plan}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subs;
