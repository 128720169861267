import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  status2: StatusCode.IDLE,
  sub_summary: [],
  subscribers: [],
  itemPerPage: 10,
  allPages: 1,
  initialPage: 1,
  error: null,
  error2: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchSubscription = createAsyncThunk(
  "get/subscription",
  async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/subscription-summary`,
        config
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubscriber = createAsyncThunk(
  "get/subscriber",
  async (page) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/subscribers?page=${page}`,
        config
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const subSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscription.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchSubscription.fulfilled, (state, { payload }) => {
        state.sub_summary = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchSubscription.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      })
      .addCase(fetchSubscriber.pending, (state) => {
        state.status2 = StatusCode.LOADING;
      })
      .addCase(fetchSubscriber.fulfilled, (state, { payload }) => {
        state.subscribers = payload.data;
        state.allPages = payload.last_page;
        state.initialPage = payload.current_page;
        state.itemPerPage = payload.per_page;
        state.status2 = StatusCode.IDLE;
      })
      .addCase(fetchSubscriber.rejected, (state, { payload }) => {
        state.error2 = payload;
        state.status2 = StatusCode.ERROR;
      });
  },
});

export default subSlice.reducer;
