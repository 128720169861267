import { Dot } from "../Assets";
import Button from "./Button";
import Shimmer from "./Shimmer";
import { Editor } from "@tinymce/tinymce-react";
// import { useEffect } from "react";
import StatusCode from "../Ultilities/StatusCode";
import { removeHtmlTags } from "../Ultilities/useFunctions";

const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const BasicDistress = ({
  loading,
  editorRef,
  handleUpdate,
  handleSubmit,
  distressAction,
  distressState,
  distressEditMode,
  distressActionOpen,
  handleDistresDelete,
  handledDistressChange,
  distrassInfo,
  distrassData,
  readyDistresupdate,
}) => {
  return (
    <div className="bg-white py-5 px-10 flex flex-col gap-20">
      {distressEditMode ? (
        ""
      ) : (
        <div>
          <h1 className="mb-5 text-primary">Distress Score</h1>
          <table className="table w-full mx-auto divide-y divide-gray-200 ">
            <thead className="bg-[#ECECEC]">
              <tr>
                <th className="text-primary px-2 text-[14px] ">S/N</th>
                <th className="text-primary px-2 text-[14px] ">Verdict</th>
                <th className="text-primary px-2 text-[14px] ">Minimum</th>
                <th className="text-primary px-2 text-[14px] ">Maximum</th>
                <th className="text-primary px-2 text-[14px] ">
                  Welcome Message
                </th>
                <th className="text-primary px-2 text-[14px] ">Action</th>
              </tr>
            </thead>

            {distrassInfo?.status === StatusCode.LOADING ? (
              <div className="fixed w-full top-[70%] left-[5%] ">
                <Shimmer height={38} width={38} color={"#207384"} />
              </div>
            ) : (
              <tbody>
                {distrassData?.map((data, index) => (
                  <tr
                    className="text-center border-b hover:bg-[#E9F3F3]"
                    key={index}
                  >
                    <td className="py-2 px-5 text-[12px] font-semibold border-x border-gray-400">
                      {index + 1}
                    </td>
                    <td className="py-2 px-5 text-[12px] font-semibold border-x border-gray-400">
                      {data?.verdict}
                    </td>
                    <td className="py-2 px-5 text-[12px] font-semibold capitalize border-r border-gray-400">
                      {data?.min}
                    </td>
                    <td className="py-2 px-5 text-[12px] font-semibold capitalize border-r border-gray-400">
                      {data?.max}
                    </td>
                    <td className="py-2 px-5 text-[12px] font-semibold capitalize border-r border-gray-400">
                      {removeHtmlTags(data?.welcome_message)}
                    </td>

                    <td className="py-2 px-5 text-[10px] font-semibold border-r border-gray-400 ">
                      <img
                        src={Dot}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => distressAction(data)}
                      />
                      <div className="relative">
                        <div
                          className={`${
                            distressActionOpen?.[data?.id] ? "" : "hidden"
                          } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
                        >
                          <p
                            className="text-xs cursor-pointer text-[#0F2851]"
                            onClick={() => distressAction(data)}
                          >
                            Close
                          </p>
                          <p
                            className="text-xs cursor-pointer text-primary"
                            onClick={readyDistresupdate}
                          >
                            {loading ? (
                              <Shimmer height={10} color={"#FF0101"} />
                            ) : (
                              "Update"
                            )}
                          </p>
                          <p
                            className="text-xs cursor-pointer text-[#FF0101]"
                            onClick={handleDistresDelete}
                          >
                            {loading ? (
                              <Shimmer height={10} color={"#FF0101"} />
                            ) : (
                              "Delete"
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}

      <div>
        <div className="w-[98%]">
          <div className="w-full flex gap-10">
            <div className="w-1/2">
              <div>
                <label htmlFor="welcome_message">Welcome message</label>
                <textarea
                  name="welcome_message"
                  id="welcome_message"
                  placeholder="Place your content here"
                  value={distressState?.welcome_message}
                  onChange={(e) => handledDistressChange(e)}
                  className="w-full h-[200px] border-2 rounded-lg p-5"
                ></textarea>

                {/* <Editor
                  tinymceScriptSrc={
                    "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                    "/tinymce/tinymce.min.js"
                  }
                  onInit={(editorContent) => {
                    editorRef.welcome_message.current = editorContent;
                  }}
                  initialValue={
                    distressEditMode ? distressState?.welcome_message : ""
                  }
                  init={{
                    height: 250,
                    menubar: true,
                    plugins,
                    toolbar,
                    content_style,
                    setup: function (editor) {
                      editor.setContent("place your content here");
                    },
                  }}
                  id="welcome_message"
                /> */}
              </div>
            </div>

            <div className="w-1/2">
              <div className="flex flex-col items-start w-full">
                <label className="text-base">Enter verdict</label>
                <input
                  name="verdict"
                  id="verdict"
                  type="text"
                  value={distressState?.verdict}
                  onChange={(e) => handledDistressChange(e)}
                  className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                />
              </div>

              <div className="flex gap-3 items-center">
                <div className="flex flex-col items-start w-full">
                  <label className="text-base">Minimum</label>
                  <input
                    name="min"
                    id="min"
                    type="number"
                    value={distressState?.min}
                    onChange={(e) => handledDistressChange(e)}
                    className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                  />
                </div>

                <div className="flex flex-col items-start w-full">
                  <label className="text-base">Maximum</label>
                  <input
                    name="max"
                    id="max"
                    type="number"
                    value={distressState?.max}
                    onChange={(e) => handledDistressChange(e)}
                    className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 flex items-center  gap-5">
          <Button
            btnDisabled={loading}
            btnClick={distressEditMode ? handleUpdate : handleSubmit}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : distressEditMode ? (
                "Update Distress Score"
              ) : (
                "Add Distress Score"
              )
            }
            btnStyle={loading ? "bg-[#898D9E]" : "bg-primary"}
          />
          <Button
            btnClick={() => window.location.reload()}
            btnText="Cancel"
            btnStyle="bg-[#898D9E]"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDistress;
