import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Admin,
  Assessment,
  Category,
  Dashboard,
  Layout,
  Profile,
  Subscription,
  Users,
} from "./pages";

import {
  Article,
  Assess,
  Audio,
  BasicQuest,
  Book,
  DailyQuest,
  DassQuest,
  Errorpage,
  ListenLearn,
  Login,
  Podcast,
  ReadReflect,
  Video,
  LearnDoActivities,
  ProtectedRoute,
  Plans,
  Promo,
  IdleTimerComponent,
  ProfileSetting,
  NotificationSetting,
  PasswordSetting,
} from "./Components";
import Quote from "./Components/Quote";
import Blog from "./Components/Blog";
import BlogPage from "./Components/BlogPage";
import BlogCategories from "./Components/BlogCategories.jsx";
import Accordion from "./Components/AccordionItem.jsx";
import FreeTrial from "./Components/FreeTrial.jsx";
import FreePackage from "./Components/FreePackage.jsx";
import Goals from "./Components/Goals.jsx";
import DeactivatedUser from "./Components/DeactivatedUser.jsx";
import ForgetPass from "./Components/ForgetPass.jsx";
import PasswordRest from "./Components/passwordReset.jsx";
import AdminVerify from "./Components/AdminVerify.jsx";

function App() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("currentPage");
      localStorage.removeItem("initialPage");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <IdleTimerComponent />
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="user" element={<Users />} />
            <Route path="deactivated_user" element={<DeactivatedUser />} />
            <Route path="article" element={<Article />} />
            <Route path="video" element={<Video />} />
            <Route path="audio" element={<Audio />} />
            <Route path="book" element={<Book />} />
            <Route path="podcast" element={<Podcast />} />
            <Route path="quote" element={<Quote />} />

            <Route path="blog" element={<Blog />}>
              <Route index element={<BlogPage />} />
              <Route path="blog-categories" element={<BlogCategories />} />
            </Route>

            <Route path="category" element={<Category />} />
            <Route path="assessment" element={<Assessment />}>
              <Route index element={<DailyQuest />} />
              <Route path="basic-quest" element={<BasicQuest />} />
              <Route path="dass-quest" element={<DassQuest />} />
              <Route path="assess" element={<Assess />} />
              {/* <Route path="self-reflection" element={<SelfReflection />} /> */}
              <Route path="goals" element={<Goals />} />
            </Route>

            <Route path="profile" element={<Profile />}>
              <Route index element={<ProfileSetting />} />
              <Route
                path="notification-setting"
                element={<NotificationSetting />}
              />
              <Route path="password-setting" element={<PasswordSetting />} />
            </Route>

            <Route path="sub" element={<Subscription />}>
              <Route index element={<Plans />} />
              <Route path="promos" element={<Promo />} />
              <Route path="free-trial" element={<FreeTrial />} />
              <Route path="free-package" element={<FreePackage />} />
            </Route>

            <Route path="readReflect" element={<ReadReflect />} />
            <Route path="learnDo" element={<LearnDoActivities />} />
            <Route path="listenLearn" element={<ListenLearn />} />
            <Route path="admin" element={<Admin />} />
            <Route path="accordion" element={<Accordion />} />
          </Route>
          <Route path="*" element={<Errorpage />} />
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="forget_password" element={<ForgetPass />} />
        <Route path={`reset_password/:token`} element={<PasswordRest />} />
        <Route path={`admin_verify/:token`} element={<AdminVerify />} />
      </Routes>
    </>
  );
}

export default App;
