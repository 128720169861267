import React from "react";
import Button from "./Button";
import { Shimmer } from "../Components";

const QuestionModal = ({
  selectedItem,
  closeModal,
  deleteItem,
  loading,
  updateItem,
  prerequisite,
  handleMakePrerequisite,
  selfReflection,
  questionClick,
  reflecttionQuestion,
  publishSlefRelection,
}) => {
  return (
    <div
      className={`w-[60%] bg-white py-20 flex flex-col items-center justify-center`}
    >
      <div className="text-center">
        {selectedItem?.question ? (
          <h1 className="">{selectedItem?.question}</h1>
        ) : (
          ""
        )}
        {selectedItem?.categories ? (
          <p>{selectedItem?.categories.join(", ")}</p>
        ) : (
          ""
        )}
        {selectedItem?.updated_at ? (
          <p>{new Date(selectedItem?.updated_at).toDateString()}</p>
        ) : (
          ""
        )}
      </div>

      <div>
        {selectedItem?.options?.map((optionEach, index) => (
          <div key={optionEach?.id}>
            <div className="flex items-center justify-between">
              <p className="text-left">{optionEach?.option}</p>

              <p className="text-right">{optionEach?.value}</p>
            </div>
          </div>
        ))}
      </div>

      {selfReflection ? (
        <div className="px-5">
          <h1 className="text-center">{selfReflection?.category}</h1>

          {selfReflection.questions
            ? selfReflection.questions.map((eachQuestion, index) => (
                <div
                  key={eachQuestion}
                  className="cursor-pointer my-3 border-2 px-2 border-primary hover:bg-secondary"
                  onClick={() => questionClick(eachQuestion)}
                >
                  <h1>
                    {index + 1}. {eachQuestion.question}
                  </h1>

                  <ul className=" ml-5 my-1">
                    {eachQuestion?.options ? (
                      eachQuestion?.options?.map((eachOption, index) => (
                        <li>
                          {String.fromCharCode(65 + index)}.{" "}
                          {eachOption?.option}
                        </li>
                      ))
                    ) : (
                      <p className="text-xs text-infoColor">
                        This question has no option
                      </p>
                    )}
                  </ul>
                </div>
              ))
            : ""}
        </div>
      ) : (
        ""
      )}

      <div className="flex items-center justify-center gap-5 mt-10 w-full">
        <Button
          btnText="Upate"
          btnStyle="bg-primary"
          btnClick={updateItem} // the update function will be here
        />

        {prerequisite ? (
          <Button
            btnText="Make Prerequisite"
            btnStyle="bg-[#78B7C5]"
            btnClick={handleMakePrerequisite}
          />
        ) : (
          ""
        )}

        {selfReflection ? (
          <Button
            btnText=" Add Question"
            btnStyle="bg-[#78B7C5]"
            btnClick={reflecttionQuestion}
          />
        ) : (
          ""
        )}

        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={closeModal}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteItem}
        />
      </div>
      {selfReflection ? (
        <div className="flex items-center justify-center mt-10">
          <Button
            btnDisabled={loading}
            btnText={selfReflection?.published == "1" ? "Unpublish" : "Publish"}
            btnStyle={loading ? "bg-inactiveText" : "bg-[#78B7C5]"}
            btnClick={publishSlefRelection}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionModal;
