import React, { useEffect, useRef } from "react";
import BoardData from "./BoardData";
import { EyeIcon, PlusIcon, AudioIcon, TagIcon } from "../Assets";
import Button from "./Button";
import ResourceListHead from "./ResourceListHead";
import TableMain from "./TableMain";
import TableRow from "./TableRow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAudios } from "../Features/AudioSlice";
import { updateAudio } from "../Features/AudioPostSlice";
import { deleteAudio } from "../Features/AudioDeleteSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { audioSummary } from "../Features/AudioSummary";
import { AddNewAudio } from "../Features/CreateAudioSlice";
import ReactPagination from "./ReactPagination";
import Shimmer from "./Shimmer";
import { fetchCategory } from "../Features/CategorySlice";
import { fetchSubPlan } from "../Features/SubPlanSlice";
import InputCat from "./InputCat";
import StatusCode from "../Ultilities/StatusCode";
import ResourceList from "./ResourceList";

const Audio = () => {
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPhotoFile, setSelectedPhotoFile] = useState(null);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [formState, setFormState] = useState({
    // id: "",
    title: "",
    description: "",
    release_date: "",
    subscription_level: 0,
  });

  const categoriesArray = useSelector((state) => state.category.data);

  // Update
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const toggleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    setAddForm(!addForm);
    setModalIsOpen(!modalIsOpen);
  };

  const updateAudioResource = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const updateData = {
      id: selectedItem.id,
      title: formData.get("title"),
      description: formData.get("description"),
      release_date: formData.get("release_date"),
      audio: !selectedFile && selectedItem.audio,
      photo: !selectedPhotoFile && selectedItem.photo,
      subscription_level: formData.get("subscription_level"),
    };

    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("audio_file", selectedFile);
    selectedPhotoFile && formData.append("photo", selectedPhotoFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    if (selectedItem.id !== null) {
      const id = selectedItem.id;
      const data = { id, formData };
      setLoading(true);
      dispatch(updateAudio(data))
        .unwrap()
        .then(() => {
          showToast("Audio Updated succesfully");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post", error);
        });
    }
  };

  // Add New Audio
  const openAddForm = () => {
    setAddForm(true);
    setModalIsOpen(false);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    setAddForm(false);
    window.location.reload();
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const [categories, setCategories] = useState([]);
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handlePhotoFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhotoFile(file);
  };

  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  // Add new Audio {onSubmit}
  const createNewAudio = (e) => {
    e.preventDefault();

    const payLoad = { ...formState, categories };
    const formData = new FormData();
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields before submitting.");
      return;
    } else if (!selectedFile) {
      return alert("please selecte an Audio file");
    } else if (!selectedPhotoFile) {
      return alert("please selecte an Photo file");
    }

    formData.append("audio_file", selectedFile);
    formData.append("photo", selectedPhotoFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(AddNewAudio(formData))
      .unwrap()
      .then(() => {
        setAddForm(!addForm);
        showToast(
          `A new Article titiled ${formState.title} has been added successfully`
        );
        setLoading(false);
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2100);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  };

  // Delete
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    setLoading(true);
    if (deleteIt.id !== null) {
      dispatch(deleteAudio({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setModalIsOpen(!modalIsOpen);
          showToast("Audio Deleted successfully");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Summary
  const audioSummaryData = useSelector(
    (state) => state.bookSummary?.bookSummary
  );
  useEffect(() => {
    dispatch(audioSummary());
  }, [dispatch]);
  const popularAudio = audioSummaryData?.popular_audio;

  // Preview Selected items
  const closeResourceInfo = () => {
    setModalIsOpen(!modalIsOpen);
    audioRef.current.pause();
    setSelectedItem(null);
  };

  const resourceInfo = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };

  // fetch audios
  const audioData = useSelector((state) => state.audio.audiosData);
  useEffect(() => {
    dispatch(getAudios());
  }, [dispatch]);

  const allAudios = audioData?.data?.map((audio, index) => (
    <TableRow
      key={audio.id}
      {...audio}
      publication_date={new Date(audio.updated_at).toDateString()}
      index={lastSerialNumber + index + 1}
      tableRowclick={() => resourceInfo(audio)}
    />
  ));

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <div>
      <div className={`${addForm ? "" : "hidden"}`}>
        <AddForm
          cancelAction={closeAddForm}
          createAudio={createNewAudio}
          updateAudioAction={updateAudioResource}
          handleChange={handleChange}
          categories={categories}
          handleFileChange={handleFileChange}
          handlePhotoFileChange={handlePhotoFileChange}
          handleCheckboxChange={handleCheckboxChange}
          editMode={editMode}
          formState={formState}
          selectedItem={selectedItem}
          setCategories={setCategories}
          loading={loading}
        />
      </div>

      <div
        className={`relative flex flex-col gap-4 ${addForm ? "hidden" : ""}`}
      >
        <div
          className={`${
            modalIsOpen ? "modalfloat" : "hidden"
          } flex items-center justify-center`}
        >
          <div className={` w-[60%]`}>
            {selectedItem && (
              <ModalAudio
                audioRef={audioRef}
                item={selectedItem}
                cancelAction={closeResourceInfo}
                deleteitem={handleDelete}
                updateAudioBtn={toggleEditMode}
                loading={loading}
              />
            )}
          </div>
        </div>

        <div className="flex gap-5">
          <BoardData
            resourceIcon={AudioIcon}
            resourcesIconAlt="AudioIcon"
            resourceTotal={audioSummaryData?.total_audio}
            resourceName="Total Audios"
            textStyle="text-[#60BB18]"
          />

          <BoardData
            resourceIcon={EyeIcon}
            resourcesIconAlt="EyeIcon"
            resourceTotal={audioSummaryData?.total_views}
            resourceName="Total Listens"
            textStyle="text-[#0F2851]"
          />

          <div className="ml-auto">
            <Button
              btnClick={openAddForm}
              btnIcon={PlusIcon}
              btnText="Add new audio"
              btnStyle="bg-primary"
            />
          </div>
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular audio</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularAudio?.map((audio) => (
              <div key={audio.id}>
                <ResourceList {...audio} photo={audio?.photo?.url} />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead resourceListType="Audios" />
          <TableMain
            resourceType="Audios"
            subLevel="Sub Level"
            TableRows={allAudios}
          />

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={audioData?.last_page}
              onChanges={(i) => {
                dispatch(getAudios(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allAudios.length * audioData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Audio;

const AddForm = ({
  editMode,
  createAudio,
  cancelAction,
  formState,
  handleChange,
  categories,
  handleFileChange,
  handlePhotoFileChange,
  handleCheckboxChange,
  selectedItem,
  updateAudioAction,
  loading,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);
  return (
    <div className="update-modal bg-mainWhite w-full rounded-lg shadow-lg">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Update Audio" : "Add Audio"}
        </h1>
      </div>

      <form
        action=""
        className="p-5"
        onSubmit={editMode ? updateAudioAction : createAudio}
      >
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              <span>Audio</span> title
            </label>
            <input
              value={formState?.title}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="audio_file" className="text-sm font-bold">
              Audio file
              <span className="text-red-500">
                (audio must be a file type of: mp3, mpeg3, wav, aac.)
              </span>
            </label>
            <input
              type="file"
              name="audio_file"
              id="audio_file"
              onChange={handleFileChange}
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
          </div>

          <div>
            <label htmlFor="description" className="text-sm font-bold">
              Description
            </label>
            <textarea
              value={formState?.description}
              onChange={(e) => {
                handleChange(e);
              }}
              name="description"
              id="description"
              cols="30"
              rows="4"
              placeholder="Type or Paste audio description"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            ></textarea>
            <span className="text-inactiveText text-xs">
              Description <span>Article</span>
            </span>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <label htmlFor="photo" className="text-sm font-bold">
                Add image
                <span className="text-red-500">
                  (Image must be less than 500 killobyte)
                </span>
              </label>
              <p className={`font-bold ${editMode ? "" : "hidden"}`}>
                Current image{" "}
                <span className="text-primary">
                  <a
                    href={selectedItem?.photo?.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {selectedItem?.photo?.filename}
                  </a>
                </span>
              </p>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handlePhotoFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
            <div>
              <label htmlFor="release_date" className="text-sm font-bold">
                Release date
              </label>
              <input
                value={formState?.release_date}
                onChange={(e) => {
                  handleChange(e);
                }}
                type="date"
                name="release_date"
                id="release_date"
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div>
            <h1 className="text-lg font-bold">Category</h1>
            <span className="text-inactiveText text-xs">
              Check associated categories
            </span>

            <div className="flex items-center justify-between mb-5">
              <div className="w-[50%]">
                {catStatus === StatusCode.LOADING ? (
                  <div>
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <div className="">
                    {Data.map((category) => (
                      <InputCat
                        key={category.id}
                        {...category}
                        checked={categories?.includes(category.id)}
                        onChange={() => handleCheckboxChange(category.id)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="flex items-center w-[50%]">
                <div className="w[15%]">
                  <label htmlFor="subscription_level">Subscription level</label>
                  <select
                    value={formState?.subscription_level}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="subscription_level"
                    id="subscription_level"
                    className=" border-2 py-2 w-full rounded-lg"
                  >
                    <option value="0">Basic</option>
                    {SubPlanData?.map((subPlan) => (
                      <option value={subPlan?.level}>{subPlan?.package}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="btns flex items-center gap-10">
              <Button
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
                btntype={"submit"}
                btnText={
                  loading ? (
                    <Shimmer height={20} color={"#fff"} />
                  ) : editMode ? (
                    "Update Audio"
                  ) : (
                    "Add Audio"
                  )
                }
              />

              <Button
                btnClick={cancelAction}
                btnStyle="bg-inactiveText"
                btnText="Cancel"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const ModalAudio = ({
  item,
  updateAudioBtn,
  cancelAction,
  deleteitem,
  content,
  ifContentStyle,
  loading,
  audioRef,
}) => {
  return (
    <div
      className={` bg-mainWhite w-full flex flex-col items-center px-5 py-12 rounded-lg shadow-lg  ${ifContentStyle} `}
    >
      <div className="w-[80%] mx-auto bg-[#2E2F32] shadow-xl rounded-lg flex items-center justify-center gap-5 p-5 mb-5">
        <div className="w-[100px]">
          <img src={item.photo.url} alt="" />
        </div>

        <div>
          <audio controls ref={audioRef}>
            <source src={item.audio_file.url} />
          </audio>
        </div>
      </div>

      <h1 className="font-bold text-lg text-center">{item.title}</h1>

      <div className="text-inactiveTest text-sm flex items-start gap-2">
        <p>{item.author}</p>

        <li>{item.publication_date}</li>

        <p className="flex">
          <span className=" w-[20px] flex items-center justify-center ml-2">
            <img src={TagIcon} alt="tag-Icon" className="" />
          </span>

          <span>{item.categories}</span>
        </p>
      </div>

      <div className="text-center w-[85%] mx-auto my-5 text-xs">
        <p className="description text-center">{item.summary}</p>

        <div className="text-inactiveTest flex items-start justify-center gap-5 mt-2">
          <p>{item.publication_date}</p>

          <p>{item.subscription_level}</p>
          <p>
            $ <span>{item.price}</span>
          </p>
        </div>

        <p
          className="content text-start mt-5"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      <div className="flex items-center gap-5">
        <Button
          btnText="Upate"
          btnStyle="bg-primary"
          btnClick={updateAudioBtn}
        />
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteitem}
        />
      </div>
    </div>
  );
};
