import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Logo2 } from "../Assets";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../Features/AuthSlice";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error } = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo]);

  const submitForm = (data) => {
    dispatch(adminLogin(data)).then(() => navigate("/"));
  };

  return (
    <div className="w-full h-[100vh] bg-secondary flex items-center flex-col">
      <div className="flex items-center gap-2 mt-7 mb-7 ">
        <img src={Logo2} alt="logo" className="w-[38px] " />
        <h3 className="text-2xl text-primary">PsychInsights</h3>
      </div>
      <form
        onSubmit={handleSubmit(submitForm)}
        className="sm:w-[95%] lg:w-[40%] h-full bg-white rounded-md "
      >
        <h2 className="my-5 text-[22px] font-[600] text-center text-primary">
          Sign in
        </h2>
        <div className="w-[90%] my-0 mx-auto ">
          <div className="flex flex-col items-start w-full gap-2">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              {...register("email")}
              required
              className="w-full bg-[#F0F1F9] outline-none h-10 pl-[9px] rounded-lg"
            />
          </div>

          {error && (
            <div className="flex items-center mt-3 mb-[-20px]">
              <p className=" text-red-700 text-base font-medium">{error}</p>
            </div>
          )}

          <div className="flex flex-col items-start w-full gap-2 my-8">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              required
              {...register("password")}
              className="w-full bg-[#F0F1F9] outline-none h-10 pl-[9px] rounded-lg"
            />
            <Link
              to="forget_password"
              className="w-full text-sm text-right text-primary"
            >
              forget password ?
            </Link>
          </div>

          <div className="w-full my-4 ">
            <button
              type="submit"
              disabled={status.loading}
              className="w-[60%] relative bg-primary mt-20 text-white text-base mx-auto flex items-center justify-center py-[6px] rounded-md"
            >
              {status === StatusCode.LOADING ? (
                <Shimmer height={20} width={20} color={"#fff"} />
              ) : (
                <div className="w-full flex items-center justify-center relative">
                  Sign in
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="absolute right-3"
                  />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
