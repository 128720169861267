import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  listenLearn: {},
  error: null,
};

export const getListenLearn = createAsyncThunk(
  "get/getReadReflex",
  async (current) => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/listen-and-learns?page=${current ?? 1}`,
      config
    );
    const final = response.data.data;
    return final;
  }
);

const listenLearnSlice = createSlice({
  name: "listenLearn",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getListenLearn.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getListenLearn.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.listenLearn = payload;
      });
  },
});

export default listenLearnSlice.reducer;

// // // // // // DELETE // // // // // //
export const deleteListenLearn = createAsyncThunk(
  "delete/ReadReflect",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/listen-and-learns/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert.log("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // CREATE // // // // // //
export const AddNewListenLearn = createAsyncThunk(
  "add/listenLearn",
  async (formData) => {
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}/admin/listen-and-learns`,
        config
      );
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // AUDIO // // // // // //
export const AddAudio = createAsyncThunk("add/Audio", async (data) => {
  try {
    const { id, formData } = data;

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: formData,
    };

    const response = await fetch(
      `${BASE_URL}/admin/listen-and-learns/${id}/audios`,
      config
    );
  } catch (error) {
    throw error;
  }
});

// // // // // // UPDATE // // // // // //
export const updateListenLearn = createAsyncThunk(
  "update/listenLearn",
  async (data) => {
    const { id } = data;
    try {
      const config = {
        method: "POST",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/listen-and-learns/${id}`,
        config
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // DELETE SINGLE AUDIO // // // // // // //
export const DeleteAudio = createAsyncThunk(
  "delete/Reflection",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };
      const responce = await fetch(
        `${BASE_URL}/admin/listen-and-learns/audios/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // PUBLISH // // // // // //
export const PublishListen = createAsyncThunk(
  "publish/unpublish",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await fetch(
        `${BASE_URL}/admin/listen-and-learns/${id}/publish`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
