import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const AddNewCategory = createAsyncThunk(
  "category/createNewCategory",
  async (payLoad) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(`${BASE_URL}/admin/categories`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/categories/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/createNewCategory",
  async (data) => {
    const { payLoad, id } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(
        `${BASE_URL}/admin/categories/${id}`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
