import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  listenLearnSummary: {},
  error: null,
};
export const listenLearnSummary = createAsyncThunk(
  "get/getsummary",
  async () => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/listen-and-learn-summary`,
      config
    );
    const final = response.data;
    return final;
  }
);
const listenLearnSummarySlice = createSlice({
  name: "listenLearnSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(listenLearnSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(listenLearnSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.listenLearnSummary = payload;
      });
  },
});

export default listenLearnSummarySlice.reducer;
