import React from "react";
import InputTag from "./InputTag";
import InputCat from "./InputCat";
import { fetchCategory } from "../Features/CategorySlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import { Editor } from "@tinymce/tinymce-react";
import { fetchSubPlan } from "../Features/SubPlanSlice";

const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const StrategisForm = ({
  strategisType,
  formState,
  handleChange,
  categories,
  handleCheckboxChange,
  handleFileChange,
  editorRefs,
  editMode,
  currentSub,
  loading,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);

  return (
    <div className="strategiesForm bg-mainWhite w-full rounded-lg">
      <h1 className="py-3 px-5 border-b border-inactiveText text-primary text-lg ">
        {strategisType}
      </h1>

      <form action="" className="p-5">
        <div className="flex items-center gap-10">
          <InputTag
            labelFor="title"
            labelText="Title"
            inputType="text"
            feildPlaceHoler="Enter title"
            inputStyle="w-1/2"
            forValue={formState?.title}
            forOnChange={(e) => handleChange(e)}
          />
          <InputTag
            labelFor="photo"
            labelText="Add image"
            inputType="file"
            inputStyle="w-1/2"
            forOnChange={handleFileChange}
          />
        </div>

        <div className="flex gap-10 my-10">
          <div className="category w-1/2">
            <div className="mb-5">
              <h2 className="text-lg font-bold">Category</h2>
              <p className="text-xs font-light">Check associated categories</p>
            </div>

            {catStatus === StatusCode.LOADING ? (
              <div>
                <Shimmer height={38} width={38} color={"#207384"} />
              </div>
            ) : (
              <div className="">
                {Data?.map((category) => (
                  <InputCat
                    key={category.id}
                    {...category}
                    checked={categories?.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="w-1/2">
            {editMode ? (
              <p className="text-xs text-primary">
                The current subscription of this strategy is {currentSub}
              </p>
            ) : (
              ""
            )}
            <label htmlFor="subscription_level">Subscription level</label>
            <br />
            <select
              value={formState?.subscription_level}
              onChange={(e) => {
                handleChange(e);
              }}
              name="subscription_level"
              id="subscription_level"
              className=" border-2 py-2 px-5 w-1/2 rounded-lg"
            >
              <option value="0">Basic</option>
              {SubPlanData?.map((subPlan) => (
                <option value={subPlan?.level}>{subPlan?.package}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="forTinyM">
          <div>
            <label htmlFor="overview">Add overview</label>
            <Editor
              tinymceScriptSrc={
                "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                "/tinymce/tinymce.min.js"
              }
              onInit={(editorOverview) => {
                editorRefs.overview.current = editorOverview;
              }}
              initialValue={editMode ? formState?.overview : ""}
              init={{
                height: 250,
                menubar: true,
                plugins,
                toolbar,
                content_style,
                setup: function (editor) {
                  editor.setContent("place your content here");
                },
              }}
              id="overview"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default StrategisForm;
