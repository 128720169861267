import Button from "./Button";
import Shimmer from "./Shimmer";
import TableRow from "./TableRow";
import TableMain from "./TableMain";
import { Dot, PlusIcon, pauseIcon, playIcon } from "../Assets";
import ResourceList from "./ResourceList";
import StrategisForm from "./StrategisForm";
import StrategiesModal from "./StrategiesModal";
import ReactPagination from "./ReactPagination";
import { Editor } from "@tinymce/tinymce-react";
import StatusCode from "../Ultilities/StatusCode";
import ResourceListHead from "./ResourceListHead";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import {
  deleteListenLearn,
  getListenLearn,
  AddNewListenLearn,
  updateListenLearn,
  AddAudio,
  DeleteAudio,
  PublishListen,
} from "../Features/listenLearnSlice";
import { listenLearnSummary } from "../Features/ListenLearnSummry";

const ListenLearn = () => {
  const dispatch = useDispatch();
  const categoriesArray = useSelector((state) => state.category.data);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openAudioForm, setOpenAudioForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [itForPublish, setItForPublish] = useState(false);
  const [openFom, setOpenForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  // const [isPlayingArray, setIsPlayingArray] = useState([]);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [audioEditOpen, setAudioEditOpen] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    title: "",
    subscription_level: 0,
  });

  // // // // // SUMMARY // // // // //
  useEffect(() => {
    dispatch(listenLearnSummary());
  }, [dispatch]);
  const listenLearnSummaryData = useSelector(
    (state) => state.listenLearnSummry.listenLearnSummary
  );
  const populartListenLearn = listenLearnSummaryData?.data;

  // // // // // // DELETE SPECIFIC AUDIO // // // // // //
  const handleDeleteAudio = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(DeleteAudio({ id: selectedAudio.id }))
      .unwrap()
      .then(() => {
        showToast("An Audio Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // list of Audio // // // // // //
  // const ifMagicAsk = () => {
  // useEffect(() => {
  //   if (selectedItem) {
  //     setIsPlayingArray(selectedItem.audios?.map(() => false));
  //   }
  // }, [selectedItem]);
  // const togglePlay = (index) => {
  //   const newIsPlayingArray = [...isPlayingArray];
  //   newIsPlayingArray[index] = !newIsPlayingArray[index];
  //   setIsPlayingArray(newIsPlayingArray);
  // };
  // const audioRef = useRef(new Audio());
  // useEffect(() => {
  //   const audioPlayer = audioRef.current;
  //   const handleTimeUpdate = () => {
  //     setCurrentTime(audioPlayer.currentTime);
  //   };
  //   const handleLoadedMetadata = () => {
  //     setDuration(audioPlayer.duration);
  //   };
  //   audioPlayer.addEventListener("timeupdate", handleTimeUpdate);
  //   audioPlayer.addEventListener("loadedmetadata", handleLoadedMetadata);
  //   return () => {
  //     audioPlayer.removeEventListener("timeupdate", handleTimeUpdate);
  //     audioPlayer.removeEventListener("loadedmetadata", handleLoadedMetadata);
  //   };
  // }, []);
  // }; // if magis ask

  const audioOption = (item) => {
    setAudioEditOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedAudio(item);
  };
  const togglePlay = () => {
    const audioElement = document.getElementById("audioPlayer");
    // const audioPlayer = audioRef.current;
    if (isPlaying) {
      audioElement.pause();
      // audioPlayer.pause();
    } else {
      audioElement.play();
      // audioPlayer.play();
    }
    setPlaying(!isPlaying);
  };
  const listAudios = selectedItem?.audios?.map((each, index) => (
    <div key={each.id}>
      <div className="flex items-center gap-10 mb-10">
        <button
          onClick={() => togglePlay(each)}
          className="w-[60px] h-[50px] bg-primary rounded-full flex items-center justify-center"
        >
          <img
            src={isPlaying ? pauseIcon : playIcon}
            // src={isPlayingArray[index] ? pauseIcon : playIcon}
            alt="play and purse Icon"
          />
        </button>
        <div className="w-full">
          <div className="flex items-center gap-5">
            <div className="w-full">
              <progress
                value={currentTime}
                max={duration}
                // style={{ width: "10%" }}
                // className="w-[100%]"
                className={`w-[100%] ${
                  isPlaying ? "bg-red-500" : "bg-gray-300"
                }`}
              />
              <audio id="audioPlayer" src={each?.audio.url} />
              {/* <div className="flex items-center justify-between -mt-1">
                <p className="text-xs">{currentTime.toFixed(2)}s </p>
                <p className="text-xs">{duration.toFixed(2)}s</p>
              </div> */}
            </div>
            <div className="option-wrap cursor-pointer relative">
              <img
                src={Dot}
                alt=""
                className="cursor-pointer"
                onClick={() => audioOption(each)}
              />
              <div
                className={`forFelectionEdit ${
                  audioEditOpen[each.id] ? "" : "hidden"
                } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
              >
                <p
                  className="text-xs cursor-pointer text-[#0F2851]"
                  onClick={() => audioOption(each)}
                >
                  Close
                </p>
                <p
                  className="text-xs cursor-pointer text-[#207384]"
                  // onClick={openSingleRefEditForm}
                >
                  Edit
                </p>
                <p
                  className="text-xs cursor-pointer text-[#FF0101]"
                  onClick={handleDeleteAudio}
                >
                  Delete
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  // // // // // // Add New Audio // // // // // //
  const handleAudioForm = (id) => {
    setOpenAudioForm(!openAudioForm);
  };
  const addAudioSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (!selectedFile) {
      alert("You need to select aN Audio file");
    } else {
      formData.append("audio", selectedFile);
      setLoading(true);
      dispatch(AddAudio({ id: selectedItem?.id, formData: formData }))
        .unwrap()
        .then(() => {
          showToast(`A new Audio has been added successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // Create New Listen an Learn // // // // // //
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  const createListenLearn = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      audio_overview: editorRefs.audio_overview?.current.target.getContent(),
      overview: editorRefs.overview?.current.target.getContent(),
    };

    const formData = new FormData();
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields.");
      return;
    } else if (!selectedFile) {
      return alert("please select an image file");
    }

    formData.append("photo", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);

    dispatch(AddNewListenLearn(formData))
      .unwrap()
      .then(() => {
        showToast(
          `A new Listen & Learn titiled ${formState.title} has been added successfully`
        );
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // // Update Listen an Learn // // // // // //
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories?.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [selectedItem, editMode, categoriesArray]);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setOpenForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updateListenLearnAction = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const updateData = {
      id: selectedItem.id,
      title: formState.title,
      subscription_level: formState.subscription_level === "Basic" ? 0 : 1,
      overview: editorRefs.overview?.current.target.getContent(),
      audio_overview: editorRefs.audio_overview?.current.target.getContent(),
    };

    const payLoad = { ...updateData, categories };
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (selectedItem.id !== null) {
      setLoading(true);
      dispatch(updateListenLearn(payLoad))
        .unwrap()
        .then(() => {
          showToast(
            `A new Listen & Learn titiled ${formState.title} has been updated successfully`
          );
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // Modal For Selected Item // // // // // //
  const openTheModal = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
    setPlaying(false);
  };
  const handleFormOpen = (e) => {
    e.preventDefault();
    setOpenForm(!openFom);
  };
  const handleFormClose = (e) => {
    window.location.reload();
  };
  const editorRefs = {
    audio_overview: useRef(null),
    overview: useRef(null),
  };

  // // // // // // Fetch // // // // // //
  const listenLearnData = useSelector(
    (state) => state.listenLearn?.listenLearn
  );
  const listenLearnStatus = useSelector((state) => state.listenLearn.status);
  useEffect(() => {
    dispatch(getListenLearn());
  }, [dispatch]);
  const allStrategies = listenLearnData?.data?.map((listenLearn, index) => (
    <TableRow
      key={listenLearn.id}
      {...listenLearn}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(listenLearn.created_at).toDateString()}
      subscription_level={new Date(listenLearn.updated_at).toDateString()}
      tableRowclick={() => openTheModal(listenLearn)}
    />
  ));

  // // // // // // DELETE // // // // // //
  const handleDeleteListenLearn = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };
    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteListenLearn({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setModalIsOpen(!modalIsOpen);
          showToast("A Listen Learn Deleted successfully.");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // // // // // // PUBLISH UNPUBLISH // // // // // //
  const publishUnpublish = (e) => {
    e.preventDefault();
    if (selectedItem.id !== null) {
      setItForPublish(true);
      dispatch(PublishListen({ id: selectedItem.id }))
        .unwrap()
        .then(() => {
          showToast(
            selectedItem.published == "1"
              ? "Strategy unpublished successfully"
              : "Strategy published successfully"
          );
          setItForPublish(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => showToast("error:", error));
    }
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <>
      <div
        className={`forAudio bg-mainWhite w-full rounded-lg pb-3 ${
          openAudioForm ? "" : "hidden"
        }`}
      >
        <h1 className="py-3 px-5 border-b border-inactiveText text-primary text-lg ">
          Add Audio
        </h1>
        <div className="reflectionsWrap px-5">
          <div>
            <div className="w-[40%]">
              <label htmlFor={`audio`}>Add Audio</label>
              <br />
              <input
                name="refections"
                id={`audio`}
                type="file"
                onChange={handleFileChange}
                className="bg-transparent rounded-lg px-3 py-[4px] border-2 border-inactiveText w-full"
              />
            </div>
          </div>
        </div>

        <div className="px-5 my-5 flex items-start gap-5">
          <Button
            btnDisabled={loading}
            btnClick={addAudioSubmit}
            btnText={
              loading ? <Shimmer height={20} color={"#fff"} /> : "Submit"
            }
            btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
          />

          <Button
            btnClick={handleFormClose}
            btnText="cancel"
            btnStyle="bg-inactiveText"
          />
        </div>
      </div>

      <div
        className={`formModal ${
          openFom ? "" : "hidden"
        } bg-mainWhite pb-5 rounded-lg`}
      >
        <StrategisForm
          strategisType="Listen & Learn"
          editorRefs={editorRefs}
          handleChange={handleChange}
          formState={formState}
          categories={categories}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editMode={editMode}
          currentSub={selectedItem?.subscription_level}
          selectedItem={selectedItem}
        />

        <div className="px-5 ">
          <label htmlFor="audio_overview" className="mb-5">
            Add audio overview
          </label>
          <Editor
            tinymceScriptSrc={
              "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
              "/tinymce/tinymce.min.js"
            }
            initialValue={editMode ? formState?.audio_overview : ""}
            onInit={(editorProtocol) => {
              editorRefs.audio_overview.current = editorProtocol;
            }}
            init={{
              height: 250,
              menubar: true,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "Link Checker",
                "Page Embed",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
                "fontselect",
                "fontsize",
              ],
              toolbar:
                "undo redo | formatselect | fontselect | fontsizeselect | " +
                "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
                "bullist numlist outdent indent | removeformat | link image | table media | " +
                "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
                "preview help wordcount",

              content_style:
                "body { font-family: Helvetica, Arial, sans-serif; font-size: 25px }",

              // setup: function (editor) {
              //   editor.setContent("place your content here");
              // },
            }}
            id="audio_overview"
          />
        </div>

        <div className="px-5 flex gap-10 mt-10">
          <Button
            btnDisabled={loading}
            btnClick={editMode ? updateListenLearnAction : createListenLearn}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Listen & Learn"
              ) : (
                "Add Listen & Learn "
              )
            }
            btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
          />

          <Button
            btnClick={handleFormClose}
            btnText="Cancle"
            btnStyle="bg-inactiveText"
          />
        </div>
      </div>

      <div
        className={`${modalIsOpen ? "" : "hidden"} ${
          openAudioForm ? "hidden" : ""
        }`}
      >
        <StrategiesModal
          itemMedia={selectedItem?.photo?.url}
          title={selectedItem?.title}
          categories={selectedItem?.categories}
          overview={selectedItem?.overview}
          moreAddition="Add Audio"
          // btns
          addReflectionAction={handleAudioForm}
          cancelAction={openTheModal}
          updateAction={toggleEditMode}
          deleteitem={handleDeleteListenLearn}
          audios={listAudios}
          loading={loading}
          itForPublish={itForPublish}
          publishStatus={selectedItem?.published}
          publishClick={publishUnpublish}
        />
      </div>

      <div
        className={`relative flex flex-col gap-5 ${openFom ? "hidden" : ""} ${
          modalIsOpen ? "hidden" : ""
        }`}
      >
        <div className="ml-auto">
          <Button
            btnText="Add Listen & Learn"
            btnIcon={PlusIcon}
            btnStyle="bg-primary"
            btnClick={handleFormOpen}
          />
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular Strategies</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {populartListenLearn?.map((strategy) => (
              <div key={strategy.id}>
                <ResourceList
                  {...strategy}
                  photo={strategy?.photo?.url}
                  publication_date={new Date(
                    strategy?.updated_at
                  ).toDateString()}
                  level={strategy.plan}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead
            resourceListType="Listen & Learn"
            placeholder="search Strategy"
          />

          {listenLearnStatus === StatusCode.LOADING ? (
            <div>
              <Shimmer height={38} width={38} color={"#207384"} />
            </div>
          ) : (
            <TableMain
              resourceType="Strategies"
              TableRows={allStrategies}
              subLevel="Last Updated"
            />
          )}
          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={listenLearnData?.last_page}
              onChanges={(i) => {
                getListenLearn(i.selected + 1);
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allStrategies.length * listenLearnData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ListenLearn;
