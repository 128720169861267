import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  status2: StatusCode.IDLE,
  quest: [],
  checkIn: [],
  error: null,
  error2: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchDailyQuest = createAsyncThunk("get/dailyQuest", async () => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/daily-questions`,
      config
    );

    return response.data.data;
  } catch (error) {
    throw error;
  }
});

export const fetchTotalCheckin = createAsyncThunk(
  "get/totalCheckin",
  async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/total-checkins`,
        config
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const dailyQuestionSlice = createSlice({
  name: "daily",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyQuest.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchDailyQuest.fulfilled, (state, { payload }) => {
        state.quest = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchDailyQuest.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(fetchTotalCheckin.pending, (state) => {
        state.status2 = StatusCode.LOADING;
      })
      .addCase(fetchTotalCheckin.fulfilled, (state, { payload }) => {
        state.checkIn = payload;
        state.status2 = StatusCode.IDLE;
      })
      .addCase(fetchTotalCheckin.rejected, (state, { payload }) => {
        state.error2 = payload;
      });
  },
});

export default dailyQuestionSlice.reducer;
