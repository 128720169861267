import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
// import { ChartData } from "./DummyData";
import { fetchDashboard } from "../Features/DashSlice";
import { useSelector, useDispatch } from "react-redux";

Chart.register(CategoryScale);

const BarChart = () => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.dash.data);
  const ChartData = Data.interest_summary;
  const state = {
    labels: ChartData?.map((data) => data.interest),
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: "#207384",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: ChartData?.map((data) => data.percentage),
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);

  return (
    <div>
      <Bar
        data={state}
        height={80}
        width={100}
        options={{
          plugins: {
            title: {
              display: false,
              text: "User Gain Between 2016-2020",
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
          },
        }}
      />
    </div>
  );
};

export default BarChart;
