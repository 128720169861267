import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const deleteQuote = createAsyncThunk(
  "delete/deleteQoute",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/daily-quotes/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteTip = createAsyncThunk(
  "delete/deleteTip",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/daily-tips/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);
