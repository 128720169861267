import React from "react";

const InputTag = ({
  inputStyle,
  labelFor,
  labelText,
  inputType,
  forOnChange,
  forValue,
  feildPlaceHoler,
  inputTagStyle,
  forDisable,
}) => {
  return (
    <div className={inputStyle}>
      {labelText ? <label htmlFor={labelFor}>{labelText}</label> : ""}
      {inputStyle ? "" : <br />}
      <input
        disabled={forDisable}
        name={labelFor}
        id={labelFor}
        type={inputType}
        placeholder={feildPlaceHoler}
        value={forValue}
        onChange={forOnChange}
        className={`bg-transparent rounded-lg px-3 py-[4px] border-2 border-inactiveText ${
          inputTagStyle || "w-full"
        }`}
      />
    </div>
  );
};

export default InputTag;
