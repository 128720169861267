import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const deleteArticle = createAsyncThunk(
  "delete/deleteArticle",
  async ({ id }) => {
    const userToken = localStorage.getItem("userToken");

    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(`${BASE_URL}/admin/articles/${id}`, config);

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

const articleDelete = createSlice({
  name: "deleteArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteArticle.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(deleteArticle.fulfilled, (state) => {
        state.status = "Fulfilled";
      })
      .addCase(deleteArticle.rejected, (state) => {
        state.status = "Failed";
      });
  },
});

export default articleDelete.reducer;
