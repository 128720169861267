import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  blogData: [],
  error: null,
};

export const getBlog = createAsyncThunk("get/getBlog", async (current) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/blogs?page=${current ?? 1}`,
      config
    );
    const final = response.data.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const blogSlice = createSlice({
  name: "blog",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBlog.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.blogData = payload;
      });
  },
});

export default blogSlice.reducer;

export const deleteBlog = createAsyncThunk(
  "delete/deleteBlog",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };
      const responce = await fetch(`${BASE_URL}/admin/blogs/${id}`, config);
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const AddNewBlog = createAsyncThunk("add/blog", async (formData) => {
  try {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: formData,
    };

    const response = await fetch(`${BASE_URL}/admin/blogs`, config);
  } catch (error) {
    throw error;
  }
});

export const UpdateTheBlog = createAsyncThunk("update", async (data) => {
  const { id, formData } = data;
  try {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: formData,
    };

    const response = await fetch(`${BASE_URL}/admin/blogs/${id}`, config);

    //   success
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});
