import moment from "moment";
import { Button, Shimmer } from "../Components";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { fetchAdmins } from "../Features/AdminSlice";
import { useSelector, useDispatch } from "react-redux";
import { AddNewAdmin } from "../Features/AddAdminSlice";
import {
  activateDeactivate,
  deleteAdmin,
  sendActivationLink,
  UpdateSelectedAdmin,
} from "../Features/SupperAdminAction";
import { toast, ToastContainer } from "react-toastify";
import { Dot } from "../Assets";

const Admin = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    role: "ordinary",
  });

  const [editMode, setEdiitMode] = useState(false);
  const adminData = useSelector((state) => state.admin.data);
  const readyToUpdate = () => {
    setOpenForm(!openForm);
    setEdiitMode(!editMode);
  };

  // Create admin
  const updateAdmin = (e) => {
    e.preventDefault();
    const payLoad = { id: selectedItem?.id, ...formState };
    dispatch(UpdateSelectedAdmin(payLoad))
      .unwrap()
      .then(() => showToast("Admin Account Updated Succesfuly"))
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2100);
      })
      .catch((error) => {
        showToast(error);
      });
  };

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  // add admin form
  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };

  const adminUserData = useSelector((state) => state.auth.userInfo?.data);
  const [codeActionOpen, setCodeActionOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const action = (item) => {
    if (adminUserData?.role === "super") {
      setCodeActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
      setSelectedItem(item);
    } else {
      alert("Sorry, only supper admin can perform action");
    }
  };
  const enviromentClick = () => {
    if (!codeActionOpen) {
      return;
    } else {
      setCodeActionOpen(false);
    }
  };
  const cancelAction = () => {
    setCodeActionOpen(false);
    setSelectedItem(null);
  };

  // Create admin
  const addAdmin = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const payLoad = { ...formState };

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    dispatch(AddNewAdmin(formData))
      .unwrap()
      .then(() => showToast("Admin Account Created Succesfuly"))
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        showToast(error);
      });
  };

  // delete
  const handleDelete = (e) => {
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };
    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteAdmin({ id: deleteIt.id }))
        .unwrap()
        .then(() => showToast("Admin account deleted"))
        .then(() => {
          window.location.reload();
        })
        .then(() => {})
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Resend Activation Link
  const resendLink = () => {
    setLoading(true);
    dispatch(sendActivationLink({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Activation Link sent succesfully");
        setCodeActionOpen(false);
        setLoading(false);
      })
      .catch((error) => alert("error performing action post: ", error));
  };

  // Activation and deactivation
  const deactivateAdmin = () => {
    setLoading(true);
    dispatch(activateDeactivate({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast(
          selectedItem?.status == "1"
            ? "Admin deactivated succesfully"
            : "Admin activated succesfully"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => alert("error performing action post: ", error));
  };

  // Handle notification
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  return (
    <div onClick={enviromentClick}>
      <div
        className={`${openForm ? "" : "hidden"} 
        }`}
      >
        <AdminForm
          addAdminClick={addAdmin}
          handleChange={handleChange}
          formState={formState}
          //
          selectedItem={selectedItem}
          updateAdmin={updateAdmin}
          editMode={editMode}
        />
      </div>

      <div className={`${openForm ? "hidden" : ""}`}>
        <div className="flex items-center justify-between w-full">
          <h1>Admin</h1>
          <div className="flex items-center gap-3">
            <p>Home</p>
            <p className="text-primary">Admin</p>
          </div>
        </div>
        <div className="flex items-center justify-end mt-4">
          <button
            className="bg-primary py-[6px] px-5 rounded-xl text-white text-sm"
            onClick={handleOpenForm}
          >
            Add Admin
          </button>
        </div>

        <div className="bg-white mt-9">
          <table className="table w-full divide-y divide-gray-200 ">
            <thead className="bg-[#ECECEC]">
              <tr>
                <th className="text-primary py-2 border-r border-gray-400 ">
                  S/N
                </th>
                <th className="text-primary border-r border-gray-400 ">Name</th>
                <th className="text-primary border-r border-gray-400 ">Role</th>
                <th className="text-primary border-r border-gray-400 ">
                  Email
                </th>
                <th className="text-primary border-r border-gray-400 ">
                  Prev login
                </th>
                <th className="text-primary ">Last login</th>
                <th className="text-primary ">Action</th>
              </tr>
            </thead>
            <tbody>
              {adminData.map((data, index) => (
                <tr className="text-center border-b" key={data.id}>
                  <td className="py-2 border-r border-gray-400">{index + 1}</td>
                  <td className="py-2 border-r border-gray-400">{data.name}</td>
                  <td className="py-2 border-r border-gray-400">{data.role}</td>
                  <td className="py-2 border-r border-gray-400">
                    {data.email}
                  </td>
                  <td className="py-2 border-r border-gray-400">
                    {" "}
                    {moment(data.prev_login).startOf("ss").fromNow()}
                  </td>
                  <td className="py-2 border-r border-gray-400">
                    {moment(data.last_login).startOf("ss").fromNow()}
                  </td>

                  <td className="border-gray-400 cursor-pointer hover:bg-[#E9F3F3] items-center justify-center">
                    <p
                      onClick={() => action(data)}
                      className="flex items-center justify-center"
                    >
                      <img src={Dot} title="option" alt="" className="" />
                    </p>

                    <div
                      className={`${
                        codeActionOpen?.[data?.id] ? "" : "hidden"
                      } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-5 shadow-lg z-10`}
                    >
                      <p
                        className="hover-effect text-xs cursor-pointer text-[#0F2851]"
                        onClick={cancelAction}
                      >
                        Close
                      </p>

                      {data?.status == "1" ? (
                        ""
                      ) : (
                        <p
                          className="hover-effect text-xs cursor-pointer text-[#207384]"
                          onClick={resendLink}
                        >
                          {loading ? (
                            <Shimmer color={"#898D9E"} height={10} />
                          ) : (
                            "Resend Activation Link"
                          )}
                        </p>
                      )}

                      <p
                        className="hover-effect text-xs cursor-pointer text-[#207384]"
                        onClick={deactivateAdmin}
                      >
                        {loading ? (
                          <Shimmer color={"#898D9E"} height={10} />
                        ) : data?.status == "1" ? (
                          "Deactivate"
                        ) : (
                          "Activate"
                        )}
                      </p>

                      {adminUserData.role === "super" && (
                        <p
                          className="text-xs cursor-pointer text-[#207384]"
                          onClick={readyToUpdate}
                        >
                          Update
                        </p>
                      )}

                      <p
                        className="hover-effect text-xs cursor-pointer text-[#FF0101]"
                        onClick={() => handleDelete(data)}
                      >
                        {loading ? (
                          <Shimmer color={"#898D9E"} height={10} />
                        ) : (
                          "Delete"
                        )}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Admin;

const AdminForm = ({
  addAdminClick,
  handleChange,
  formState,
  editMode,
  updateAdmin,
  selectedItem,
}) => {
  return (
    <div className="h-[75vh] ">
      <h1>{editMode ? "Update selected Amdin" : "Create New Admin"}</h1>

      <form
        onSubmit={editMode ? updateAdmin : addAdminClick}
        action=""
        className="adminForm w-full h-[90%] bg-mainWhite p-10 rounded-lg"
      >
        <div className="flex items-center gap-10">
          <div className="w-1/2">
            <label htmlFor="name">Full Name</label> <br />
            <input
              value={formState.name}
              placeholder={editMode ? selectedItem.name : ""}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              id="name"
              name="name"
              className="w-full py-2 px-5 mt-2 border-2 border-inactiveText rounded-lg"
            />
          </div>

          <div className="w-1/2">
            <label htmlFor="email">Email Address</label> <br />
            <input
              value={formState.email}
              placeholder={editMode ? selectedItem.email : ""}
              onChange={(e) => {
                handleChange(e);
              }}
              type="email"
              name="email"
              id="email"
              className="w-full py-2 px-5 mt-2 border-2 border-inactiveText rounded-lg"
            />
          </div>
        </div>

        <div className="mt-5 py-2">
          <label htmlFor="role">
            Role:{" "}
            {editMode ? `current role of admin is ${selectedItem.role}` : ""}
          </label>
          <br />
          <select
            value={formState.role}
            placeholder={editMode ? selectedItem.role : ""}
            onChange={(e) => {
              handleChange(e);
            }}
            name="role"
            id="role"
            className="bg-transparent w-[150px] px-3 py-2 border-2 mt-3 border-inactiveText rounded-lg"
          >
            <option value="ordinary">Ordinary</option>
            <option value="super">Super</option>
          </select>
        </div>

        <div className="flex items-start gap-10 mt-5">
          <Button
            btnText="Add Admin"
            btntype={"submit"}
            btnStyle="bg-primary cursor-pointer"
          />
          <Button
            btnText="Cancel"
            btnClick={() => window.location.reload()}
            btnStyle="bg-inactiveText cursor-pointer"
          />
        </div>
      </form>
    </div>
  );
};
