import React, { useEffect, useState, useRef } from "react";
import BoardData from "./BoardData";
import { PlusIcon, QuoteIcon, TipIcon } from "../Assets";
import Button from "./Button";
import ResourceListHead from "./ResourceListHead";
import { getQuote } from "../Features/QuoteSlice";
import { getTip } from "../Features/TipSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewQuote,
  AddNewTip,
  updateTip,
  updateQuote,
} from "../Features/CreatQuoteSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import { deleteQuote, deleteTip } from "../Features/QuoteTipDelete";
import { quoteTipSummary } from "../Features/QouteTipSummary";
import Shimmer from "./Shimmer";

const Quote = () => {
  const ThStyle = `py-2 px-3 text-primary`;
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [switchQuote, setSwitchQuote] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formState, setFormState] = useState({
    id: "",
    quote: "",
    author: "",
  });

  // // // // // SUMMARY // // // // //
  const quoteTipSummaryData = useSelector(
    (state) => state?.quoteTipSummary?.quoteTipSummary?.data
  );
  useEffect(() => {
    dispatch(quoteTipSummary());
  }, [dispatch]);
  // Delete QUOTE
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteQuote({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          showToast("Quote Deleted successfully.");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Delete Tip
  const handleTipDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteTip({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          showToast("Quote Deleted successfully.");
          setLoading(false);
        })
        .then(() => window.location.reload())
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // modal, selected Item
  const resourceInfo = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeResourceInfo = () => {
    setModalIsOpen(!modalIsOpen);
  };

  // Add quote and Tip
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  // quote ADD ACTION
  const createNewQuote = (e) => {
    e.preventDefault();

    if (!formState.quote) {
      alert("quote is requireed");
    } else if (!formState.author) {
      alert("Author is requireed");
    } else {
      setLoading(true);
      dispatch(AddNewQuote(formState))
        .unwrap()
        .then(() => {
          showToast("A new Quote has been added");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => console.error("Error creating Quote", error));
    }
  };

  // Tip add action
  const createNewTip = (e) => {
    e.preventDefault();

    const payLoad = {
      ...formState,
      tip: editorRef.current.target.getContent(),
    };

    if (!payLoad.tip) {
      alert("Tip is requireed");
    } else {
      setLoading(true);
      dispatch(AddNewTip(payLoad))
        .unwrap()
        .then(() => {
          showToast("A new Tip has been added");
          setLoading(false);
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2200);
        })
        .catch((error) => console.error("Error creating Tip", error));
    }
  };

  // Get all Quotes
  useEffect(() => {
    dispatch(getQuote());
  }, [dispatch]);
  const quoteData = useSelector((state) => state.quote.quoteData);
  const AllQuote = quoteData?.map((quote, index) => (
    <QuoteTableRow
      key={quote.id}
      index={index + 1}
      {...quote}
      updated_at={new Date(quote.updated_at).toDateString()}
      tableRowclick={() => resourceInfo(quote)}
    />
  ));

  // Get all Tips
  useEffect(() => {
    dispatch(getTip());
  }, [dispatch]);
  const tipData = useSelector((state) => state.tip.tipData);
  const AllTips = tipData?.map((tip, index) => (
    <QuoteTableRow
      key={tip.id}
      {...tip}
      quote={tip.tip}
      index={index + 1}
      updated_at={new Date(tip.updated_at).toDateString()}
      tableRowclick={() => resourceInfo(tip)}
    />
  ));

  const changeSwitch = (e) => {
    e.preventDefault();
    setSwitchQuote(!switchQuote);
  };
  const openFormClick = (e) => {
    e.preventDefault();
    setOpenForm(true);
  };
  const closeFormClick = (e) => {
    e.preventDefault();
    setOpenForm(false);
    window.location.reload();
  };

  // Update Quote an tip
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
  }, [editMode, selectedItem]);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setOpenForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
    setSwitchQuote((prev) => (selectedItem.tip ? !prev : prev));
  };
  // for Quote uodate
  const handleQuoteUpate = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const updateData = {
      id: selectedItem.id,
      quote: formData.get("quote"),
      author: formData.get("author"),
    };
    setLoading(true);
    dispatch(updateQuote(updateData))
      .unwrap()
      .then(() => {
        showToast("Quote updated successfully");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => console.error("Error updating Quote", error));
  };

  // For Tip update
  const handleTipUpate = (e) => {
    e.preventDefault();

    const updateData = {
      id: selectedItem.id,
      tip: editorRef.current.target.getContent(),
    };
    setLoading(true);
    dispatch(updateTip(updateData))
      .unwrap()
      .then(() => {
        showToast("Tip Updated successfully");
        setLoading(false);
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2200);
      })
      .catch((error) => console.error("Error updating Tip", error));
  };

  // toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <div className={`flex flex-col gap-10 ${openForm ? "" : "hidden"}`}>
        <div>
          <button
            onClick={changeSwitch}
            className={`${
              switchQuote
                ? "bg-primary text-mainWhite"
                : "bg-mainWhite text-primary"
            } px-5 py-1 rounded-l-lg`}
          >
            Quotes
          </button>

          <button
            onClick={changeSwitch}
            className={`${
              !switchQuote
                ? "bg-primary text-mainWhite"
                : "bg-mainWhite text-primary"
            } px-5 py-1 rounded-r-lg`}
          >
            Tip
          </button>
        </div>

        <form
          action=""
          onSubmit={
            switchQuote
              ? editMode
                ? handleQuoteUpate
                : createNewQuote
              : editMode
              ? handleTipUpate
              : createNewTip
          }
        >
          <div className="border-inactiveText py-3 px-10 bg-mainWhite rounded-xl shadow-lg flex flex-col gap-5">
            <h1 className="text-primary text-lg border-b-2">
              {switchQuote ? "Quote" : "Tip"}
            </h1>

            <div className={`my-7 flex gap-10 ${switchQuote ? "" : "hidden"}`}>
              <div className="flex flex-col gap-5 w-1/2">
                <label htmlFor="quote" className="font-bold">
                  Add quote
                </label>

                <textarea
                  type="text"
                  placeholder="Write your quote here"
                  name="quote"
                  id="quote"
                  cols="50"
                  rows="7"
                  value={formState.quote}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="rounded-lg border-2 border-inactive px-5 py-2"
                ></textarea>
              </div>

              <div className="flex flex-col gap-5 w-1/2">
                <label htmlFor="author" className="font-bold">
                  Author
                </label>

                <input
                  type="text"
                  placeholder="Enter author author"
                  name="author"
                  id="author"
                  value={formState.author}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="rounded-lg border-2 border-inactive px-3 py-1"
                ></input>
              </div>
            </div>

            {/* for Tip feilds */}
            <div className={`${switchQuote ? "hidden" : ""}`}>
              <label htmlFor="tip" className="font-bold">
                Tip
              </label>

              <Editor
                tinymceScriptSrc={
                  "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                  "/tinymce/tinymce.min.js"
                }
                initialValue={editMode ? formState?.tip : ""}
                onInit={(editor) => {
                  editorRef.current = editor;
                }}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "Link Checker",
                    "Page Embed",
                    "image",
                    "charmap",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "preview",
                    "help",
                    "wordcount",
                    "fontselect",
                    "fontsize",
                  ],
                  toolbar:
                    "undo redo | formatselect | fontselect | fontsizeselect | " +
                    "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | removeformat | link image | table media | " +
                    "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
                    "preview help wordcount",

                  content_style:
                    "body { font-family: Helvetica, Arial, sans-serif; font-size: 25px }",

                  setup: function (editor) {
                    editor.setContent("place your content here");
                  },
                }}
                id="tip"
              />
            </div>

            <div className="flex items-center gap-10">
              <button
                disabled={loading}
                btntype={"submit"}
                className={`text-mainWhite px-10 rounded-lg py-1 ${
                  loading ? "bg-inactiveText" : "bg-primary"
                }`}
              >
                {loading ? (
                  <Shimmer height={20} color={"#fff"} />
                ) : switchQuote ? (
                  editMode ? (
                    "Update Quote"
                  ) : (
                    "Add Quotes"
                  )
                ) : editMode ? (
                  "Update Tip"
                ) : (
                  "Add Tip"
                )}
                {/* {loading ? <Shimmer height={20} color={"#fff"} /> :} */}
              </button>

              <button
                onClick={closeFormClick}
                className="text-mainWhite px-10 rounded-lg py-1 bg-[#898D9E]"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        className={`relative flexflex-col gap-4 ${openForm ? "hidden" : ""}`}
      >
        <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
          <div className={`w-[60%]`}>
            {selectedItem && (
              <QuoteTipModal
                item={selectedItem}
                cancelAction={closeResourceInfo}
                tip={selectedItem?.tip}
                deleteitem={
                  selectedItem?.quote ? handleDelete : handleTipDelete
                }
                updateAction={toggleEditMode}
                loading={loading}
              />
            )}
          </div>
        </div>

        <div className="flex gap-5">
          <BoardData
            resourceName="Total Quotes"
            resourceTotal={quoteTipSummaryData?.total_quotes}
            resourceIcon={QuoteIcon}
          />
          <BoardData
            resourceName="Total Tip"
            resourceTotal={quoteTipSummaryData?.total_tips}
            resourceIcon={TipIcon}
          />
          <div className="ml-auto">
            <Button
              btnText="Add New Quote/Tip"
              btnClick={openFormClick}
              btnStyle="bg-primary"
              btnIcon={PlusIcon}
            />
          </div>
        </div>

        <div className="listOfAll mt-3 bg-mainWhite rounded-lg shadow-xl">
          <ResourceListHead resourceListType="Qoute" />
          <div className="w-full overflow-x-scroll py-3 px-5">
            <table className="w-full table-auto">
              <thead className="text-center bg-[#F6F7FA]">
                <tr>
                  <th className={ThStyle}>S/N</th>
                  <th className={ThStyle}>Quote</th>
                  <th className={ThStyle}>Last Updated</th>
                </tr>
              </thead>

              <tbody>{AllQuote}</tbody>
            </table>
          </div>
        </div>

        <div className="listOfAll mt-3 bg-mainWhite rounded-lg shadow-xl">
          <ResourceListHead resourceListType="Tips" />
          <div className="w-full overflow-x-scroll py-3 px-5">
            <table className="w-full table-auto">
              <thead className="text-center bg-[#F6F7FA]">
                <tr>
                  <th className={ThStyle}>S/N</th>
                  <th className={ThStyle}>Tips</th>
                  <th className={ThStyle}>Last Updated</th>
                </tr>
              </thead>

              <tbody>{AllTips}</tbody>
            </table>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Quote;

const QuoteTableRow = ({ index, quote, updated_at, tableRowclick }) => {
  const TdStyle = `border-b border-inactiveText bg-trasnparent p-2 text-center text-sm font-medium text-[#2E2F32]`;
  return (
    <tr className="cursor-pointer hover:bg-[#E9F3F3]" onClick={tableRowclick}>
      <td className={TdStyle}>{index}</td>
      <td className={TdStyle}>
        <p dangerouslySetInnerHTML={{ __html: quote }} />
      </td>
      <td className={`w-[20%] ${TdStyle} text-end`}>{updated_at}</td>
    </tr>
  );
};

const QuoteTipModal = ({
  item,
  tip,
  updateAction,
  cancelAction,
  deleteitem,
  ifContentStyle,
  loading,
}) => {
  return (
    <div
      className={`modal bg-mainWhite w-[100%] flex flex-col gap-5 py-10 items-center justify-around px-5 rounded-lg shadow-lg ${ifContentStyle}`}
    >
      <div className="text-center mx-auto text-xs">
        <p dangerouslySetInnerHTML={{ __html: tip }} />
        <p>{item?.quote}</p>
      </div>

      <div className="flex items-center gap-5">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateAction} />

        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteitem}
        />
      </div>
    </div>
  );
};
