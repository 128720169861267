import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";
const BASE_URL = "https://psychinsightsapp.net/api";

const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;

const initialState = {
  status: StatusCode.IDLE,
  userToken,
  userInfo: null,
  error: null,
};

export const adminLogin = createAsyncThunk(
  "admin/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data: response } = await axios.post(
        `${BASE_URL}/admin/login`,
        { email, password },
        config
      );
      const userToken = response?.data?.authorization?.token;
      localStorage.setItem("userToken", userToken);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("initialPage");
      state.status = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.status = StatusCode.LOADING;
      })

      .addCase(adminLogin.fulfilled, (state, { payload }) => {
        state.status = StatusCode.IDLE;
        state.userInfo = payload;
        state.userToken = payload;
      })
      .addCase(adminLogin.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.IDLE;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

export const changePassword = createAsyncThunk(
  "passwordUpdate",
  async (data) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(`${BASE_URL}/admin/change-password`, config);
      const final = await response.json();
      const errorMessage = final.message;
      if (!response.ok) {
        alert(errorMessage);
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);
