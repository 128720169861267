import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  data: [],
  error: null,
};
const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchSubPlan = createAsyncThunk("get/getSubPlan", async () => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/subscription-packages`,
      config
    );
    const final = response?.data;
    return final;
  } catch (error) {
    alert(`Unable to fetch`, error);
  }
});

const subPlanSlice = createSlice({
  name: "subPlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubPlan.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchSubPlan.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchSubPlan.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default subPlanSlice.reducer;
