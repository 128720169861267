import Shimmer from "./Shimmer";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProfile } from "../Features/ProfileUpdate";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const adminUserData = useSelector((state) => state.auth.userInfo.data);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [newFormState, setNewFormState] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    setFormState({ ...adminUserData });
  }, [adminUserData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewFormState((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    if (newFormState.name.trim() === "") {
      alert("Name can not be empty");
    } else if (newFormState.email.trim() === "") {
      alert("Email can not be empty");
    } else {
      setIsLoading(true);
      dispatch(UpdateProfile(newFormState))
        .unwrap()
        .then(() => {
          showToast(`Your details has been upated successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error updateing user:", error);
        });
    }
  };

  // toast
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-5 w-full">
        <div className="flex flex-col w-full">
          <label htmlFor="name" className="capitalize mb-3 text-lg font-medium">
            Full name
          </label>
          <input
            id="name"
            required
            placeholder={formState?.name}
            onChange={(e) => handleChange(e)}
            type="text"
            className="border rounded-xl bg-[#F0F1F9] px-2 h-[35px]"
          />
        </div>

        <div className="flex flex-col w-full">
          <label
            htmlFor="email"
            className="capitalize mb-3 text-lg font-medium"
          >
            email address
          </label>
          <input
            id="email"
            required
            placeholder={
              formState.email?.slice(0, 5) +
              "*".repeat(7) +
              formState.email?.slice(formState.email?.indexOf("@"))
            }
            onChange={(e) => handleChange(e)}
            type="text"
            className="border rounded-xl bg-[#F0F1F9] px-2 h-[35px]"
          />
        </div>
      </div>
      <div className="mt-10">
        <button
          onClick={submit}
          disabled={isLoading}
          className="bg-primary text-white px-5 py-1 text-base capitalize rounded-lg "
        >
          {isLoading ? <Shimmer color={"#fff"} height={20} /> : "update"}
        </button>
      </div>
    </div>
  );
};

export default ProfileSetting;
