import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  status2: StatusCode.IDLE,
  status3: StatusCode.IDLE,
  userData: [],
  userItem: [],
  userDetail: [],
  itemPerPage: 10,
  currentPage: 1,
  totalPages: 1,
  totalUsers: 1,
  error: null,
  error2: null,
  error3: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchUsers = createAsyncThunk("get/usersDetail", async () => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/user-summary`, config);

    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const getUserInfos = createAsyncThunk(
  "get/getUserInfos",
  async (page) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/users?page=${page}`,
        config
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getUserDetails = createAsyncThunk(
  "get/getUserDetail",
  async (id) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(`${BASE_URL}/admin/users/${id}`, config);

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        state.userData = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchUsers.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      })
      .addCase(getUserInfos.pending, (state) => {
        state.status2 = StatusCode.LOADING;
      })
      .addCase(getUserInfos.fulfilled, (state, { payload }) => {
        state.userItem = payload.data;
        state.itemPerPage = payload.per_page;
        state.currentPage = payload.current_page;
        state.totalPages = payload.last_page;
        state.totalUsers = payload.total;
        state.status2 = StatusCode.IDLE;
      })
      .addCase(getUserInfos.rejected, (state, { payload }) => {
        state.error2 = payload;
        state.status2 = StatusCode.ERROR;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.status3 = StatusCode.LOADING;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.userDetail = payload;
        state.status3 = StatusCode.IDLE;
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        state.error3 = payload;
        state.status3 = StatusCode.ERROR;
      });
  },
});

export default userSlice.reducer;
