import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  audiosData: {},
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const getAudios = createAsyncThunk("get/getAudios", async (current) => {
  try {
    const userToken = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/audios?page=${current ?? 1}`,
      config
    );
    const final = response?.data?.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const audioSlice = createSlice({
  name: "audio",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAudios.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getAudios.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.audiosData = payload;
      });
  },
});

export default audioSlice.reducer;
