import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  videosData: {},
  error: null,
};

export const getVideos = createAsyncThunk("get/getVideos", async (current) => {
  try {
    const userToken = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/videos?page=${current ?? 1}`,
      config
    );
    const final = response.data.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const videoSlice = createSlice({
  name: "video",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVideos.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.videosData = payload;
      });
  },
});

export default videoSlice.reducer;
