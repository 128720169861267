import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: StatusCode.IDLE,
  error: null,
  message: "",
  forgetPass: {},
};

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async ({ email }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data: response } = await axios.post(
        `${BASE_URL}/admin/forgot-password`,
        { email },
        config
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const forgetPasswordSlice = createSlice({
  name: "forgetPass",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(forgetPassword.fulfilled, (state, { payload }) => {
        state.message = payload?.response?.data?.message;
        state.forgetPass = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(forgetPassword.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default forgetPasswordSlice.reducer;
