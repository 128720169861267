import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StatusCode from "../Ultilities/StatusCode";
import axios from "axios";

const initialState = {
  status: StatusCode.IDLE,
  notification: [],
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const fetchNotification = createAsyncThunk("get", async () => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/notifications`, config);
    const final = response?.data?.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchNotification.fulfilled, (state, { payload }) => {
        state.notification = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchNotification.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default notificationSlice.reducer;
