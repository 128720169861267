import React from "react";

const InputCat = ({ id, category, checked, onChange }) => {
  return (
    <span className="mr-5">
      <input
        type="checkbox"
        id={id}
        name="categories"
        value={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="ml-1  font-bold">
        {category}
      </label>
    </span>
  );
};

export default InputCat;
