import React, { useEffect, useRef } from "react";
import BoardData from "./BoardData";
import { EyeIcon, PlusIcon, VideoIcon, TagIcon } from "../Assets";
import Button from "./Button";
import ResourceList from "./ResourceList";
import ResourceListHead from "./ResourceListHead";
import TableMain from "./TableMain";
import TableRow from "./TableRow";
import { useState } from "react";
import ReactPagination from "./ReactPagination";
import { useDispatch, useSelector } from "react-redux";
import { getVideos } from "../Features/VideoSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { videoSummary } from "../Features/VideoSummary";
import { deleteVideo } from "../Features/VideoDeleteSlice";
import Shimmer from "./Shimmer";
import { fetchCategory } from "../Features/CategorySlice";
import { fetchSubPlan } from "../Features/SubPlanSlice";
import StatusCode from "../Ultilities/StatusCode";
import InputCat from "./InputCat";
import axios from "axios";

const Video = () => {
  const userToken = localStorage.getItem("userToken");
  const mediaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  // const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    release_date: "",
    subscription_level: 0,
    duration: "",
    video_file: "",
  });

  const closeResourceInfo = () => {
    setModalIsOpen(!modalIsOpen);
    mediaRef.current?.pause();
    setSelectedItem(null);
  };

  const resourceInfo = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };

  // fetch videos
  const videoData = useSelector((state) => state.video.videosData);
  const categoriesArray = useSelector((state) => state.category.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideos());
  }, [dispatch]);

  const allVideos = videoData?.data?.map((video, index) => (
    <TableRow
      key={video.id}
      {...video}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(video.updated_at).toDateString()}
      tableRowclick={() => resourceInfo(video)}
    />
  ));

  // Update
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setAddFormOpen((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updateVideoResource = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const updateData = {
      id: selectedItem.id,
      title: formData.get("title"),
      author: formData.get("author"),
      summary: formData.get("summary"),
      price: formData.get("price"),
      publication_year: formData.get("publication_year"),
      purchase_link: formData.get("purchase_link"),
      subscription_level: formData.get("subscription_level"),
      video_file: formData.get("video_file"),
    };

    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("photo", selectedFile);
    // selectedVideoFile && formData.append("video_file", selectedVideoFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (selectedItem.id !== null) {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        // onUploadProgress: (progressEvent) => {
        //   const percentCompleted = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   setProgress(percentCompleted);
        // },
      };

      try {
        setLoading(true);
        const response = await axios.post(
          `https://psychinsightsapp.net/api/admin/videos/${selectedItem.id}`,
          formData,
          config
        );

        if (response?.status === 200) {
          showToast(`Video updated successfully`);
          setTimeout(() => {
            window.location.reload();
          }, 2100);
        } else {
          alert("Error creating post: ", response);
        }
      } catch (error) {}
    }
  };

  // Add new video
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  // const handleVideoFileChange = (event) => {
  //   const videoFile = event.target.files[0];
  //   setSelectedVideoFile(videoFile);
  // };
  const openAddForm = (e) => {
    e.preventDefault();
    setAddFormOpen(true);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    setAddFormOpen(!addFormOpen);
    window.location.reload();
  };

  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  const createNewVideo = async (e) => {
    e.preventDefault();
    const payLoad = { ...formState, categories };
    const formData = new FormData();
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields before submitting.");
      return;
    } else if (!selectedFile) {
      return alert("please selecte an image file");
    }
    // else if (!selectedVideoFile) {
    //   return alert("please selecte a video file");
    // }
    // formData.append("video_file", selectedVideoFile);

    formData.append("photo", selectedFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      // onUploadProgress: (progressEvent) => {
      //   const percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   setProgress(percentCompleted);
      // },
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "https://psychinsightsapp.net/api/admin/videos",
        formData,
        config
      );

      if (response?.status === 200) {
        showToast(
          `A new Video titled ${formState.title} has been added successfully`
        );
        setTimeout(() => {
          window.location.reload();
        }, 2100);
      } else {
        alert("Error creating post: ", response);
      }
    } catch (error) {}
  };

  // Delete Video
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteVideo({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          showToast("Video Deleted successfully, reload to update data list");
          setLoading(false);
        })
        .then(() => window.location.reload())
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Summary
  const videoSummaryData = useSelector(
    (state) => state.videoSummary?.videoSummary
  );
  useEffect(() => {
    dispatch(videoSummary());
  }, [dispatch]);
  const popularVideo = videoSummaryData?.popular_video;

  // toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  // the video display to admin
  const videoId = selectedItem?.video_file?.split("/").pop();
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div>
      <div className={`${addFormOpen ? "" : "hidden"}`}>
        <AddForm
          cancelAction={closeAddForm}
          createVideo={createNewVideo}
          updateVideo={updateVideoResource}
          editMode={editMode}
          setCategories={setCategories}
          formState={formState}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          // handleVideoFileChange={handleVideoFileChange}
          selectedItem={selectedItem}
          categories={categories}
          loading={loading}
          progress={progress}
        />
      </div>

      <div
        className={`relative flex flex-col gap-4 ${
          addFormOpen ? "hidden" : ""
        }`}
      >
        <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
          <div className={`w-[60%]`}>
            {selectedItem && (
              <Modal
                item={selectedItem}
                cancelAction={closeResourceInfo}
                updateAction={toggleEditMode}
                deleteitem={handleDelete}
                loading={loading}
                mediaRef={mediaRef}
                video_file={embedUrl}
              />
            )}
          </div>
        </div>
        <div className="flex gap-5">
          <BoardData
            resourceIcon={VideoIcon}
            resourcesIconAlt="VideoIcon"
            resourceTotal={videoSummaryData?.total_video}
            resourceName="Total Videos"
            textStyle="text-[#B0173C]"
          />

          <BoardData
            resourceIcon={EyeIcon}
            resourcesIconAlt="EyeIcon"
            resourceTotal={videoSummaryData?.total_views}
            resourceName="Total views"
            textStyle="text-[#0F2851]"
          />

          <div className="ml-auto">
            <Button
              btnClick={openAddForm}
              btnIcon={PlusIcon}
              btnText="Add new video"
              btnStyle="bg-primary"
            />
          </div>
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular videos</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularVideo?.map((video) => (
              <div key={video.id}>
                <ResourceList
                  {...video}
                  photo={video?.photo?.url}
                  publication_date={video?.release_date}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead resourceListType="Videos" />
          <TableMain
            resourceType="Videos"
            subLevel="Sub Level"
            TableRows={allVideos}
          />

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={videoData?.last_page}
              onChanges={(i) => {
                dispatch(getVideos(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allVideos.length * videoData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Video;

const Modal = ({
  loading,
  item,
  updateAction,
  cancelAction,
  deleteitem,
  content,
  ifContentStyle,
  video_file,
}) => {
  return (
    <div
      className={`modal bg-mainWhite w-full flex flex-col items-center px-5 py-12 rounded-lg shadow-lg  ${ifContentStyle} `}
    >
      <div className="w-[50%] h-[250px] flex items-center justify-center">
        {/* <video controls className="w-full" ref={mediaRef}>
          <source src={item.video_file?.url} />
        </video> */}

        <iframe
          className="w-full h-full"
          title="YouTube video player"
          src={video_file}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <h1 className="font-bold text-lg text-center">{item.title}</h1>

      <div className="text-inactiveTest text-sm flex items-start gap-2">
        <p>{item.author}</p>

        <li>{item.publication_date}</li>

        <p className="flex">
          <span className=" w-[20px] flex items-center justify-center ml-2">
            <img src={TagIcon} alt="tag-Icon" className="" />
          </span>

          <span>{item.categories}</span>
        </p>
      </div>

      <div className="text-center w-[85%] mx-auto my-5 text-xs">
        <p className="description text-center">{item.description}</p>

        <div className="text-inactiveTest flex items-start justify-center gap-5 mt-2">
          <p>{item.release_date}</p>

          <p>{item.subscription_level}</p>
          {/* <p>
            $ <span>{item.price}</span>
          </p> */}
        </div>

        <p
          className="content text-start mt-5"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      <div className="flex items-center gap-5">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateAction} />
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />

        <Button
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteitem}
        />
      </div>
    </div>
  );
};

const AddForm = ({
  editMode,
  createVideo,
  cancelAction,
  formState,
  handleChange,
  setCategories,
  categories,
  handleFileChange,
  handleVideoFileChange,
  handleCheckboxChange,
  selectedItem,
  updateVideo,
  loading,
  progress,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);

  return (
    <div className="update-modal bg-mainWhite w-full rounded-lg shadow-lg">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Update Video" : "Add Video"}
        </h1>
      </div>

      <form
        action=""
        className="p-5"
        onSubmit={editMode ? updateVideo : createVideo}
      >
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              <span>Video</span> title
            </label>
            <input
              value={formState?.title}
              onChange={(e) => handleChange(e)}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="video_file" className="text-sm font-bold">
              Video link
              {/* <span className="text-red-500">
                (video must be a file type of: mp4, mov)
              </span> */}
            </label>
            <input
              value={formState?.video_file}
              type="text"
              name="video_file"
              id="video_file"
              onChange={(e) => handleChange(e)}
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter video link</span>
            {/* Vedio upload Progress */}
            {/* <div className="progress-bg">
              <div className="progress" style={{ width: `${progress}%` }} />
            </div>
            <div className="check-circle">{`${progress}%`}</div> */}
          </div>

          <div>
            <label htmlFor="description" className="text-sm font-bold">
              Description
            </label>
            <textarea
              value={formState?.description}
              onChange={(e) => {
                handleChange(e);
              }}
              name="description"
              id="description"
              cols="30"
              rows="4"
              placeholder="Type or Paste audio description"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            ></textarea>
            <span className="text-inactiveText text-xs">
              Description <span>Article</span>
            </span>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <label htmlFor="photo" className="text-sm font-bold">
                photo file
                <span className="text-red-500">
                  (photo must be a file type of:png, jpeg, jpg, 500killobite or
                  less )
                </span>
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>

            <div className="flex gap-10">
              <div className="w-1/2">
                <label htmlFor="release_date" className="text-sm font-bold">
                  Release date
                </label>
                <input
                  value={formState?.release_date}
                  onChange={(e) => handleChange(e)}
                  type="date"
                  name="release_date"
                  id="release_date"
                  className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
                />
              </div>

              <div className="w-1/2">
                <label htmlFor="price">duration</label>

                <input
                  value={formState?.duration}
                  onChange={(e) => handleChange(e)}
                  type="number"
                  name="duration"
                  id="duration"
                  className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div>
            <h1 className="text-lg font-bold">Category</h1>
            <span className="text-inactiveText text-xs">
              Check associated categories
            </span>

            <div className="flex items-center justify-between mb-5">
              <div className="w-[50%]">
                {catStatus === StatusCode.LOADING ? (
                  <div>
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <div className="">
                    {Data?.map((category) => (
                      <InputCat
                        key={category.id}
                        {...category}
                        checked={categories?.includes(category.id)}
                        onChange={() => handleCheckboxChange(category.id)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="flex items-center w-[50%]">
                <div className="w[15%]">
                  <label htmlFor="subscription_level">Subscription level</label>
                  <select
                    value={formState?.subscription_level}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="subscription_level"
                    id="subscription_level"
                    className=" border-2 py-2 w-full rounded-lg"
                  >
                    <option value="0">Basic</option>
                    {SubPlanData?.map((subPlan) => (
                      <option value={subPlan?.level}>{subPlan?.package}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="btns flex items-center gap-10">
              <Button
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
                btntype={"submit"}
                btnText={
                  loading ? (
                    <Shimmer height={20} color={"#fff"} />
                  ) : editMode ? (
                    "Update Video"
                  ) : (
                    "Add Video"
                  )
                }
              />

              <Button
                btnClick={cancelAction}
                btnStyle="bg-inactiveText"
                btnText="Cancel"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
