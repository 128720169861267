import React, { useState } from "react";
import AddPlan from "./AddPlan";
import Subs from "./Subs";

const Plans = () => {
  const [plans, setPlans] = useState(false);

  const showPlan = () => {
    setPlans(!plans);
  };

  const hidePlan = () => {
    setPlans(!plans);
  };

  return (
    <div>
      {!plans ? <Subs showPlan={showPlan} /> : <AddPlan onCancle={hidePlan} />}
    </div>
  );
};

export default Plans;
