import React, { useState } from "react";
import { Sidebar, TopNav } from "../Components";
import { Outlet } from "react-router-dom";
import { logout } from "../Features/AuthSlice";
import { useDispatch } from "react-redux";
import { logoutIcon } from "../Assets";
import { ToastContainer } from "react-toastify";

const Layout = () => {
  const [readyToLogout, setReadyToLogout] = useState(false);
  const dispatch = useDispatch();
  const [notify, setNotify] = useState(false);
  const onNotify = () => {
    setNotify(() => !notify);
  };
  return (
    <div
      className={`w-full relative flex items-start bg-secondary ${
        readyToLogout ? "h-[100vh] overflow-hidden" : ""
      }`}
    >
      <ToastContainer />
      <div className="w-[16%]">
        <Sidebar logOut={() => setReadyToLogout(!readyToLogout)} />
      </div>

      <div className="w-full">
        <div className="w-full bg-white fixed z-10 top-0 shadow-custom">
          <TopNav onNotify={onNotify} notify={notify} />
        </div>

        <div
          className="w-[92%] mx-auto mt-20 pb-8"
          onClick={() => setNotify(false)}
        >
          <Outlet />
        </div>
      </div>

      <div className={`${readyToLogout ? "modalfloat2 z-40" : "hidden"}`}>
        <div className="px-5 py-10 bg-white w-[35%] flex flex-col items-center justify-center gap-7 rounded-2xl">
          <img src={logoutIcon} alt="logoutIcon" />
          <p>Are you sure you want to log out ?</p>

          <div className="mt-5 flex items-center justify-center gap-5">
            <button
              className="bg-[#F43F5E] py-2 px-7 rounded-2xl text-white font-bold"
              onClick={() => {
                dispatch(logout());
                setReadyToLogout(!readyToLogout);
              }}
            >
              Yes
            </button>

            <button
              className="bg-primary py-2 px-7 rounded-2xl text-white font-bold"
              onClick={() => setReadyToLogout(!readyToLogout)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
