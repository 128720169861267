import React, { useEffect, useState } from "react";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { adminLogin } from "../Features/AuthSlice";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DummyUserImage } from "../Assets";
import { fetchNotification } from "../Features/NotificationSlice";
import ReactPagination from "./ReactPagination";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const TopNav = ({ notify, onNotify }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [lastPage, setLastPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [notification, setNotification] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [notificationCount, setNotificationCount] = useState(null);

  const userData = userInfo?.data;
  const userName = userData.name;
  const name = userName.slice(0, 1);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    dispatch(adminLogin());
    dispatch(fetchNotification());
  }, [dispatch]);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const config = {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken,
            Accept: "application/json",
            Connection: "keep-alive",
          },
        };
        const response = await fetch(
          `https://psychinsightsapp.net/api/admin/notification-count`,
          config
        );
        const data = await response.json();
        setNotificationCount(data?.data?.notification_count);
      } catch (error) {}
    };
    fetchNotificationCount();

    const fetchNotificationData = async (current) => {
      try {
        const config = {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken,
            Accept: "application/json",
            Connection: "keep-alive",
          },
        };
        const response = await fetch(
          `https://psychinsightsapp.net/api/admin/notifications?page=${current}`,
          config
        );
        const data = await response.json();
        setLastPage(data?.data?.last_page);
        setNotification(data?.data?.data);
      } catch (error) {}
    };
    fetchNotificationData(currentPage);
  }, [userToken, currentPage, fetchTrigger]);

  const handleClick = (index) => {
    setClickedIndex(index === clickedIndex ? null : index);
  };
  const handleMarkAllRead = async () => {
    try {
      const config = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken,
          Accept: "application/json",
          Connection: "keep-alive",
        },
      };
      const response = await fetch(
        `https://psychinsightsapp.net/api/admin/notifications/mark-all-as-read`,
        config
      );
      const data = await response.json();
      showToast(data.message);
      setFetchTrigger((prev) => !prev);
    } catch (error) {}
  };
  const handleMarkRead = async (id) => {
    try {
      const config = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken,
          Accept: "application/json",
          Connection: "keep-alive",
        },
      };
      const response = await fetch(
        `https://psychinsightsapp.net/api/admin/notifications/${id}/mark-as-read`,
        config
      );
      const data = await response.json();
      showToast(data.message);
      setFetchTrigger((prev) => !prev);
    } catch (error) {}
  };
  const handleDelete = async (id) => {
    try {
      const config = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken,
          Accept: "application/json",
          Connection: "keep-alive",
        },
      };
      const response = await fetch(
        `https://psychinsightsapp.net/api/admin/notifications/${id}/cancel`,
        config
      );
      const data = await response.json();
      showToast(data.message);
      setFetchTrigger((prev) => !prev);
    } catch (error) {}
  };

  // Toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div className="flex items-center h-[60px] justify-center w-[80%] my-0 mx-auto">
      <div className="flex items-center justify-center w-[54%]"></div>
      <div className="flex items-center gap-8">
        <div className="relative cursor-pointer">
          <IoNotificationsOutline size={24} onClick={onNotify} />
          <div className="absolute flex items-center justify-center top-[-8px] right-[-10px] w-[20px] h-[20px] rounded-full bg-primary">
            <p className="text-sm text-white">{notificationCount}</p>
          </div>
          {notify && (
            <div className="w-[300px] h-[400px] bg-white shadow-custom absolute top-10 right-[-100px]">
              <div className="w-full bg-primary py-[10px]">
                <div className=" flex items-center justify-between text-[15px] w-[90%] mx-auto">
                  <h2>Notifications</h2>
                  <button
                    type="button"
                    onClick={handleMarkAllRead}
                    className="bg-secondary text-primary font-semibold text-[14px] text-xs rounded-lg py-[5px] px-4"
                  >
                    mark all as read
                  </button>
                </div>
              </div>

              <div className="h-[350px] overflow-y-auto">
                {notification?.map((data, index) => (
                  <div
                    key={index}
                    className={` ${
                      data.opened === 0
                        ? "bg-secondary border-primary"
                        : "bg-whiteb"
                    } mt-[1px] w-full mb-[2px] border-y-2`}
                  >
                    <div className="w-[92%] mx-auto flex items-center gap-2">
                      <div className="w-[45px] h-[45px] rounded-full ">
                        <img
                          src={data?.image_url || DummyUserImage}
                          alt="image_url"
                          className="rounded-full w-full h-full"
                        />
                      </div>
                      <div className="w-[77%] py-[6px]">
                        <h3 className="text-[13px] capitalize text-[#2E2F32] font-semibold ">
                          {data?.title}
                        </h3>
                        <div>
                          <div onClick={() => handleClick(index)}>
                            <p
                              className={`text-[11px] text-[#898D9E] font-semibold w-[180px] ${
                                clickedIndex === index ? "" : "truncate"
                              }`}
                            >
                              {data?.body}
                            </p>
                            <p className="text-[10px] text-[#898D9E] font-semibold">
                              {data?.time}{" "}
                              {moment(data?.created_at).startOf("ss").fromNow()}
                            </p>
                          </div>
                          <div className="flex items-center justify-end gap-2">
                            {data.opened === 0 ? (
                              <button
                                className="bg-[#78B7C5] text-white font-semibold text-[14px] text-xs rounded-lg py-[4px] px-4"
                                onClick={() => handleMarkRead(data.id)}
                              >
                                mark as read
                              </button>
                            ) : (
                              ""
                            )}

                            <button
                              className="bg-[#E47D78] text-white font-semibold text-[14px] text-xs rounded-lg py-[4px] px-4"
                              onClick={() => handleDelete(data?.id)}
                            >
                              delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-primary flex justify-center items-center w-full p-5">
                <ReactPagination
                  total={lastPage}
                  onChanges={(i) => {
                    setCurrentPage(i.selected + 1);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center gap-1">
          <Link
            className="flex items-center justify-center w-10 h-10 rounded-full
            bg-iconColor font-[500] text-[22px] text-white capitalize "
            to="/profile"
          >
            {name}
          </Link>
          <div className="flex items-center justify-center flex-col">
            <h3 className="capitalize font-semibold text-base">
              {userData?.name}
            </h3>
            <p className="text-[14px] font-semibold mt[-2px] capitalize">
              {userData?.role} admin
            </p>
          </div>
        </div>
        <div className="">
          <IoSettingsOutline size={22} />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default TopNav;
