import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const deleteAdmin = createAsyncThunk(
  "delete/deleteAdmin",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(`${BASE_URL}/admin/admins/${id}`, config);

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

const adminDelete = createSlice({
  name: "deleteAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteAdmin.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(deleteAdmin.fulfilled, (state) => {
        state.status = "Fulfilled";
      })
      .addCase(deleteAdmin.rejected, (state) => {
        state.status = "Failed";
      });
  },
});

export default adminDelete.reducer;

export const sendActivationLink = createAsyncThunk(
  "resendLink",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/admins/${id}/resend-link`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to perform action");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const activateDeactivate = createAsyncThunk(
  "activate",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/admins/${id}/activation`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to perform action");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE ADMIN by super admin // // // // // //
export const UpdateSelectedAdmin = createAsyncThunk(
  "update/adminUser",
  async (payload) => {
    try {
      const config = {
        method: "PUT",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${BASE_URL}/admin/admins/${payload.id}`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
