import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  readReflectSummary: {},
  error: null,
};
export const readReflectSummary = createAsyncThunk(
  "get/getsummary",
  async () => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/read-and-reflect-summary`,
      config
    );
    const final = response.data;
    return final;
  }
);
const readReflectSummarySlice = createSlice({
  name: "readReflectSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(readReflectSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(readReflectSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.readReflectSummary = payload;
      });
  },
});

export default readReflectSummarySlice.reducer;
