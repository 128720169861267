import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  goals: [],
  error: null,
};

// Fetch all goals category
export const getGoals = createAsyncThunk("get/goals", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/goals`, config);
  const final = response.data;
  return final;
});

const goalSlice = createSlice({
  name: "goals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getGoals.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getGoals.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.goals = payload;
      });
  },
});

export default goalSlice.reducer;

// // Add/Store/create goals category
export const addGoals = createAsyncThunk("add/goals", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_URL}/admin/goals`, config);
    return response;
  } catch (error) {
    throw error;
  }
});

// // UPDATE goals category
export const updateGoals = createAsyncThunk("update/goals", async (data) => {
  try {
    const config = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_URL}/admin/goals/${data.id}`, config);
    return response;
  } catch (error) {
    throw error;
  }
});

// // DELETE goals category
export const deleteGoals = createAsyncThunk("delete", async ({ id }) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const responce = await fetch(`${BASE_URL}/admin/goals/${id}`, config);
    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail to delete");
    }
  } catch (error) {
    throw error;
  }
});
// .........................................................
// // Add/Store/create Reflection to goals
export const addReflectionGoal = createAsyncThunk("add", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      `${BASE_URL}/admin/goals/${id}/reflections`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
});

// // UPDATE Reflection goals
export const updateReflectionGoals = createAsyncThunk(
  "update",
  async (data) => {
    const { id, payload } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${BASE_URL}/admin/goals/reflections/${id}`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// // DELETE Reflection goals
export const deleteReflectionGoals = createAsyncThunk(
  "delete",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/goals/reflections/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// .........................................................
// // Add/Store/create Question to goals
export const addQuestionGoal = createAsyncThunk("add", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      `${BASE_URL}/admin/goals/${id}/questions`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
});

// // UPDATE Question goals
export const updateQuestionGoals = createAsyncThunk("update", async (data) => {
  const { id, payload } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      `${BASE_URL}/admin/goals/questions/${id}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
});

// // DELETE Question goals
export const deleteQuestionGoals = createAsyncThunk(
  "delete",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/goals/questions/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // publish and unpublished goals
export const unpublishGoals = createAsyncThunk("publish", async ({ id }) => {
  try {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const responce = await fetch(
      `${BASE_URL}/admin/goals/${id}/publish`,
      config
    );
    if (responce.ok) {
      const data = await responce.json();
      return data;
    } else {
      alert("Fail");
    }
  } catch (error) {
    throw error;
  }
});
