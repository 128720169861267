import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  podcastsData: {},
  error: null,
};

export const getPodcasts = createAsyncThunk(
  "get/getPodcasts",
  async (current) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/podcasts?page=${current ?? 1}`,
        config
      );
      const final = response?.data?.data;
      return final;
    } catch (error) {
      throw error;
    }
  }
);

const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPodcasts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPodcasts.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.podcastsData = payload;
      });
  },
});

export default podcastSlice.reducer;
