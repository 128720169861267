import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  data: [],
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const fetchAdmins = createAsyncThunk("get/admin", async () => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(`${BASE_URL}/admin/admins`, config);

    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchAdmins.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchAdmins.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default adminSlice.reducer;
