import { useState } from "react";
import { DummyUserImage } from "../Assets";

const AccordionItem = ({ fullName, email, text, profile_photo, remarks }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="w-full rounded-lg bg-white shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)]">
      <div
        className={`faq-btn flex w-full text-left items-center`}
        onClick={() => handleToggle()}
      >
        <div className="w-full flex items-center gap-3">
          <div className="w-[50px] h-[50px] bg-red-600 flex items-center justify-center">
            <img
              src={profile_photo || DummyUserImage}
              alt="profile"
              classNameName="rounded-full w-full"
            />
          </div>

          <div classNameName="w-[80%] ml-[-25px]">
            <h3 classNameName="capitalize text-sm font-semibold">{fullName}</h3>
            <p classNameName={`text-[10px]`}>{email}</p>
          </div>
        </div>

        <div className="mr-5 flex w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill=""
              stroke=""
            />
          </svg>
        </div>
      </div>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
          {text}
        </p>
        <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
          {remarks}
        </p>
      </div>
    </div>
  );
};
export default AccordionItem;
