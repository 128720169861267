import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogCategories } from "../Features/BlogCategoriesSlice";
import InputCat from "./InputCat";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import { Editor } from "@tinymce/tinymce-react";
import Button from "./Button";

const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const BlogForm = ({
  formState,
  handleChange,
  handleFileChange,
  editMode,
  categories,
  handleCheckboxChange,
  editorRef,
  loading,
  handleCreate,
  handleUpdate,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogCategories());
  }, [dispatch]);

  const blogCat = useSelector(
    (state) => state.blogCategories.blogCategoriesData.data
  );
  const blogCatStatus = useSelector(
    (state) => state.blogCategories.blogCategoriesData.status
  );
  return (
    <div className="w-full bg-white p-5 ">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Update Blog" : "Add Blog"}
        </h1>
      </div>

      <form className="">
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              Blog title
            </label>
            <input
              value={formState?.title}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="author" className="text-sm font-bold">
              Blog author
            </label>
            <input
              value={formState?.author}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="author"
              id="author"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter author</span>
          </div>

          <div className="flex gap-10">
            <div className="w-1/2">
              <label htmlFor="photo" className="text-sm font-bold">
                photo file
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>

            <div className="w-1/2">
              <label htmlFor="duration" className="text-sm font-bold">
                Duration
              </label>
              <input
                value={formState?.duration}
                onChange={(e) => {
                  handleChange(e);
                }}
                type="number"
                name="duration"
                id="duration"
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
          </div>

          <div>
            {blogCatStatus === StatusCode.LOADING ? (
              <div>
                <Shimmer height={38} width={38} color={"#207384"} />
              </div>
            ) : (
              <div className="grid grid-cols-2">
                {blogCat?.map((category) => (
                  <InputCat
                    key={category.id}
                    {...category}
                    checked={categories?.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="blogBody">
          <label htmlFor="body" className="font-bold">
            Blog body
          </label>
          <Editor
            tinymceScriptSrc={
              "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
              "/tinymce/tinymce.min.js"
            }
            onInit={(editor) => {
              editorRef.body = editor;
            }}
            initialValue={editMode ? formState?.body : ""}
            init={{
              height: 250,
              menubar: false,
              plugins,
              toolbar,
              content_style,
              setup: function (editor) {
                editor.setContent("place your content here");
              },
            }}
            id="body"
          />
        </div>

        <div className=" mt-5 flex items-center gap-5">
          <Button
            btnDisabled={loading}
            btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
            btnClick={editMode ? handleUpdate : handleCreate}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Blog"
              ) : (
                "Add Blog"
              )
            }
          />

          <Button
            btnClick={() => window.location.reload()}
            btnStyle="bg-inactiveText"
            btnText="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default BlogForm;
