import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeTrial,
  deleteFreeTrial,
  getFreeTrial,
} from "../Features/FreeTrialSlice";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import { toast } from "react-toastify";

const FreeTrial = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    duration_type: "",
    duration: "",
  });
  const freeTrialData = useSelector((state) => state.freeTrial.freeTrial);

  useEffect(() => {
    dispatch(getFreeTrial());
  }, [dispatch]);
  useEffect(() => {
    setFormState({ ...freeTrialData });
  }, [freeTrialData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prev) => ({ ...prev, [id]: value }));
  };

  const setFreeTrial = (e) => {
    e.preventDefault();
    const payLoad = {
      duration_type: formState.duration_type,
      duration: formState.duration,
    };
    setLoading(true);
    dispatch(addFreeTrial(payLoad))
      .unwrap()
      .then(() => {
        showToast("Free Trial created/updated successfully");
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setLoading(false);
      })
      .catch((error) => alert("error create post: ", error));
  };
  const deleteTrial = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteFreeTrial())
      .unwrap()
      .then(() => {
        showToast("Free Trial deleted successfully");
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setLoading(false);
      })
      .catch((error) => alert("error updating post: ", error));
  };

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <div>
      <div className="my-10 bg-mainWhite rounded-lg">
        <h1 className=" text-primary px-5 py-3 border-b-[1px] border-primary">
          Add free trial period
        </h1>

        <div className="p-5">
          <form action="">
            <div className="flex items-center gap-10">
              <div>
                <label htmlFor="duration_type">Duration type</label> <br />
                <select
                  name="duration_type"
                  id="duration_type"
                  value={formState?.duration_type}
                  onChange={(e) => handleChange(e)}
                  className="border-inactiveText border-2 rounded-lg w-[150px] text-center py-[2px]"
                >
                  <option value="month">Month</option>
                  <option value="week">Week</option>
                  <option value="day">Day</option>
                </select>
              </div>

              <div>
                <label htmlFor="duration">Duration</label> <br />
                <input
                  type="number"
                  id="duration"
                  name="duration"
                  placeholder="0"
                  value={formState?.duration}
                  onChange={(e) => handleChange(e)}
                  className="w-[100px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
            </div>

            <div className="flex items-center gap-5 my-5">
              <Button
                btnClick={setFreeTrial}
                btnDisabled={loading}
                btnText={
                  loading ? <Shimmer height={20} color={"#fff"} /> : "Add trial"
                }
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
              />
              <Button
                btnClick={deleteTrial}
                btnDisabled={loading}
                btnText={"Delete"}
                btnStyle={"bg-inactiveText"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreeTrial;
