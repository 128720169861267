import React, { useState } from "react";
import { Dot, TagIcon } from "../Assets";
import Button from "./Button";
import Shimmer from "./Shimmer";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { DeleteQuestion, updateQuestion } from "../Features/learnDoSlice";
import { showToast } from "./BlogCategories";
import InputTag from "./InputTag";

const StrategiesModal = ({
  title,
  categories,
  updateAction,
  cancelAction,
  deleteitem,
  content,
  overview,
  ifContentStyle,
  itemMedia,
  reflections,
  addReflectionAction,
  updated_at,
  subscription_level,
  moreAddition,
  audios,
  loading,
  activities,
  selectedActivity,
  openTheActModal,
  actModalIsOpen,
  updateActivity,
  deleteActivity,
  publishStatus,
  publishClick,
  itForPublish,
}) => {
  return (
    <div
      className={`relative bg-mainWhite w-full flex flex-col px-10 py-12 ${ifContentStyle} `}
    >
      <div className="w-[50%] h-[350px] mx-auto flex items-center justify-center">
        <img src={itemMedia} alt="" className="w-full h-full" />
      </div>

      <h1 className="font-bold text-2xl text-center">{title}</h1>
      <div className="text-inactiveTest text-sm flex items-center justify-center gap-2">
        <p className="flex gap-2">
          <span className="w-[20px] flex items-center justify-center ml-2">
            <img src={TagIcon} alt="tag-Icon" className="" />
          </span>
          <span className="text-center">{categories}</span>
        </p>
      </div>

      <p
        className="text-base text-inactiveText"
        dangerouslySetInnerHTML={{ __html: overview }}
      />
      <div className="text-inactiveText flex items-center gap-3 text-xs -mt-1">
        <p>{updated_at}</p>
        <p>{subscription_level}</p>
      </div>

      <div className="text-center  my-5 text-xs">
        <p
          className="content text-start mt-5"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      {reflections ? (
        <div>
          {reflections ? (
            <p className="text-[#2E2F32] text-lg font-bold my-3">Reflections</p>
          ) : (
            ""
          )}
          <div className="reflections flex flex-col gap-3 mb-20">
            {reflections}
          </div>
        </div>
      ) : (
        ""
      )}

      {audios && audios.length > 0 ? (
        <div className="w-[50%] mx-auto">
          <p className="text-[#2E2F32] text-lg font-bold my-3">Audios</p>
          <div className="reflections flex flex-col gap-3 mb-20">{audios}</div>
        </div>
      ) : (
        ""
      )}

      {activities ? (
        <div className="w-[75%] mx-auto">
          <p className="my-2 text-2xl font-bold">Activities</p>
          <div>
            {activities.map((activity, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-10 px-5 my-2"
              >
                <p className="textOverflow">
                  {index + 1}. {activity?.title}
                </p>
                <div className="w-[20%] flex justify-end">
                  <button
                    className="py-2 px-5 text-[#207384] bg-[#C9E7ED] rounded-lg hover:bg-[#207384] hover:text-[#C9E7ED] text-xs shadow-2xl"
                    onClick={() => openTheActModal(activity)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={`${actModalIsOpen ? "modalfloat" : "hidden"}`}>
        <div className={`w-[60%]`}>
          <ActivityModal
            item={selectedActivity}
            loading={loading}
            closeModal={openTheActModal}
            updateItem={updateActivity}
            deleteItem={deleteActivity}
          />
        </div>
      </div>

      <div className="flex items-center gap-5 justify-center mt-10">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateAction} />

        <Button
          btnClick={addReflectionAction}
          btnText={moreAddition}
          btnStyle="bg-[#78B7C5]"
        />

        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />
        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveTex" : "bg-red-500"}
          btnClick={deleteitem}
        />

        <Button
          btnDisabled={loading}
          btnText={
            itForPublish ? (
              <Shimmer height={20} color={"#fff"} />
            ) : publishStatus == "1" ? (
              "Unpublish"
            ) : (
              "Publish"
            )
          }
          btnStyle={
            itForPublish
              ? "bg-inactiveText"
              : publishStatus == "1"
              ? "bg-[#78B7C5]"
              : "bg-green"
          }
          btnClick={publishClick}
        />
      </div>
    </div>
  );
};

export default StrategiesModal;

const ActivityModal = ({
  item,
  loading,
  closeModal,
  deleteItem,
  updateItem,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [questionValues, setQuestionValues] = useState(null);

  const readyUpdate = (item) => {
    setIsUpdate(true);
    setQuestionValues(item);
  };
  const questionChange = (e) => {
    const { id, value } = e.target;
    setQuestionValues((prevData) => ({ ...prevData, [id]: value }));
  };
  const updateActQues = (item) => {
    const payload = {
      id: questionValues.id,
      question: questionValues.question,
      answer_type: questionValues.answer_type,
      maximum: questionValues.maximum,
      minimum: questionValues.minimum,
      number_of_list: questionValues.number_of_list,
    };
    if (payload.answer_type === "list" && !payload.number_of_list) {
      alert("Number of list is required");
      return;
    } else if (
      payload.answer_type === "number" &&
      (!payload.maximum || !payload.minimum)
    ) {
      alert(
        "Minimum and Maximum values are required for questions with number answer type."
      );
      return;
    }
    setIsLoading(true);
    dispatch(updateQuestion(payload))
      .unwrap()
      .then(() => {
        showToast(`Question updated successfully`);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  const deleteActQues = (item) => {
    setIsLoading(true);
    dispatch(DeleteQuestion({ id: item?.id }))
      .unwrap()
      .then(() => {
        showToast("Question Deleted successfully.");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  return isUpdate ? (
    <div className=" bg-white p-5 flex flex-col gap-5">
      <h1 className=" text-2xl text-primary">Update question</h1>

      <div>
        <label htmlFor="question">{`Activity Question (Optional)`}</label>
        <br />
        <textarea
          name="question"
          id="question"
          placeholder="Enter Question"
          value={questionValues?.question}
          onChange={(e) => questionChange(e)}
          className="border-2 px-3 py-1 border-inactiveText w-full h-[120px] rounded-lg"
        ></textarea>
      </div>

      <div className="flex gap-10">
        <div>
          <label htmlFor="answer_type">{`Select answer type for question`}</label>{" "}
          <br />
          <select
            name="answer_type"
            id="answer_type"
            value={questionValues?.answer_type || "text"}
            onChange={(e) => questionChange(e)}
            className="w-[70%] p-1 rounded-lg border-2"
          >
            <option value="text">Text</option>
            <option value="longtext">Long Text</option>
            <option value="time">Time</option>
            <option value="list">List</option>
            <option value="number">Number</option>
          </select>
        </div>

        {questionValues?.answer_type === "list" ? (
          <div>
            <InputTag
              labelFor={"number_of_list"}
              labelText={`Number_of_list for question`}
              inputType={"number"}
              forValue={questionValues?.number_of_list}
              forOnChange={(e) => questionChange(e)}
            />
          </div>
        ) : (
          ""
        )}

        {questionValues?.answer_type === "number" ? (
          <div className="flex gap-10">
            <InputTag
              labelFor={"minimum"}
              labelText={`Minimum value for question`}
              inputType={"number"}
              forValue={questionValues?.minimum}
              forOnChange={(e) => questionChange(e)}
            />
            <InputTag
              labelFor={"maximum"}
              labelText={`Maximum value for question`}
              inputType={"number"}
              forValue={questionValues?.maximum}
              forOnChange={(e) => questionChange(e)}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="flex items-center gap-5 justify-center">
        <Button
          btnText={
            isLoading ? <Shimmer height={20} color={"#fff"} /> : "Update"
          }
          btnStyle="bg-primary"
          btnClick={updateActQues}
        />
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={() => setIsUpdate(false)}
        />
      </div>
    </div>
  ) : (
    <div className="modal bg-mainWhite w-full p-12 shadow-lg rounded-b-[50px]">
      <h1 className="text-2xl font-bold">{item?.title}</h1>

      <div className="forOverview mb-5 text-[14px]">{item?.overview}</div>
      <div className="forexample my-5 font-light text-sm">{item?.example}</div>

      <div className="forQuestion">
        <ul>
          {item?.questions
            ? item?.questions.map((question, questionIndex) => (
                <li className="flex items-center justify-between gap-10 px-5 my-2">
                  <p className="text-xs textOverflow">
                    {questionIndex + 1}. {question?.question}
                  </p>
                  <div className="flex justify-end gap-10 items-center">
                    <span className="py-2 w-[100px] text-center text-[#207384] bg-[#BEC1CD] rounded-lg text-xs shadow-2xl">
                      {question?.answer_type}
                    </span>
                    <span
                      className=" cursor-pointer text-2xl text-primary"
                      onClick={() => readyUpdate(question)}
                    >
                      <MdModeEdit />
                    </span>
                    <span
                      className=" cursor-pointer text-2xl text-red-500"
                      onClick={() => deleteActQues(question)}
                    >
                      {isLoading ? (
                        <Shimmer height={20} color={"red"} />
                      ) : (
                        <MdDelete />
                      )}
                    </span>
                  </div>
                </li>
              ))
            : ""}
        </ul>
      </div>

      <div className="flex items-center gap-5 justify-center mt-10">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateItem} />

        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={closeModal}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveTex" : "bg-red-500"}
          btnClick={deleteItem}
        />
      </div>
    </div>
  );
};
