import React, { useEffect, useState } from "react";
import { HideEye, LockIcon } from "../Assets";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Shimmer from "./Shimmer";

const PasswordRest = () => {
  let { token } = useParams();
  const BASE_URL = "https://psychinsightsapp.net/api";
  const [isLoading, setIsLoading] = useState(false);
  const [feedBack, setFeedBack] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [formState, setFormState] = useState({
    password: "",
    password_confirmation: "",
    token: token,
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/admin/by-token/${token}`);
        const output = await response.json();
        if (response.ok) {
          showToast("Verification successful");
          setShowForm(true);
          setFeedBack(output.message);
        } else {
          setFeedBack(output.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [token]);

  const submitForm = async (e) => {
    e.preventDefault();
    if (formState.password !== formState.password_confirmation) {
      alert("Password does not match");
      return;
    } else if (formState.password.length < 8) {
      alert("Password must be at least 8 characters");
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/admin/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formState),
      });

      if (response.ok) {
        showToast("Password reset successfully");
        setTimeout(() => {
          window.location.href = "https://admin.psychinsightsapp.com/";
        }, 2000);
      } else {
        const output = await response.json();
        setFeedBack(output.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const showPassword = (inputId) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement.type === "password") {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
  };
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="h-[100vh] w-[100vw] flex justify-center items-center bg-[#cccccc]">
        <div className="container w-[40%] bg-[#ffffff] rounded-lg shadow-lg p-[40px]">
          <div>
            <h1 className="text-center text-[#207384]">Reset password</h1>
          </div>

          <div className="message1 h-[35vh] flex items-center justify-center gap-[25px]">
            <div className="message1">
              {showForm ? (
                <div>
                  <form className="form1" onSubmit={submitForm}>
                    <div className="formdiv">
                      <label className="label1 mt-[10px]" htmlFor="password">
                        Password
                      </label>
                      <br />

                      <div className="imput-wrap1">
                        <img src={LockIcon} alt="Lock" />
                        <input
                          className="input1"
                          id="password"
                          onChange={(e) => handleChange(e)}
                          name="password"
                          type="password"
                          placeholder="Enter new password here"
                        />
                        <img
                          onClick={() => showPassword("password")}
                          src={HideEye}
                          alt="Hide-Password"
                          className="eye-icon password-eye cursor-pointer"
                        />
                      </div>
                    </div>

                    <div className="formdiv mt-10">
                      <label
                        className="label1"
                        style={{ marginLeft: "10px" }}
                        htmlFor="password_confirmation"
                      >
                        Confirm Password
                      </label>
                      <br />

                      <div className="imput-wrap1">
                        <img src={LockIcon} alt="Lock" />
                        <input
                          className="input1"
                          onChange={(e) => handleChange(e)}
                          id="password_confirmation"
                          name="password_confirmation"
                          type="password"
                          placeholder="Confirm your password here"
                        />
                        <img
                          onClick={() => showPassword("password_confirmation")}
                          src={HideEye}
                          alt="Hide-Password"
                          className="eye-icon confirm-eye cursor-pointer"
                        />
                      </div>
                    </div>

                    <button type="submit" className="button1">
                      {isLoading ? (
                        <Shimmer color={"#fff"} height={20} />
                      ) : (
                        "Proceed"
                      )}
                    </button>
                  </form>
                </div>
              ) : (
                <div>
                  <p
                    className="first-text"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    {feedBack ? `${feedBack}` : " Verifying..."}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRest;
