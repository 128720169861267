import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";
const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: StatusCode.IDLE,
  freeTrial: {},
  error: null,
};

export const getFreeTrial = createAsyncThunk("get/freeTrial", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/free-trials`, config);
  const final = response.data.data;
  return final;
});

const freeTrialSlice = createSlice({
  name: "freeTrial",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFreeTrial.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFreeTrial.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.freeTrial = payload;
      });
  },
});

export default freeTrialSlice.reducer;

// Posting

export const addFreeTrial = createAsyncThunk("add", async (data) => {
  try {
    const config = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_URL}/admin/free-trials`, config);
    return response;
  } catch (error) {
    throw error;
  }
});

// // // // // // DELETE // // // // // //
export const deleteFreeTrial = createAsyncThunk("delete", async () => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const responce = await fetch(`${BASE_URL}/admin/free-trials`, config);
    return responce;
  } catch (error) {
    throw error;
  }
});
