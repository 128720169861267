import React from "react";

const AddPlan = () => {
  return (
    <div className="mt-7 py-3 bg-white shadow-custom rounded-lg">
      <div className="w-[95%] mx-auto">
        <h1>Add Plans</h1>
      </div>
      <hr className="w-full my-3" />
      <div className="w-[95%] mx-auto">
        <div className="flex items-start justify-between">
          <div className="w-full">
            <h2>plan name</h2>
            <input
              type="text"
              placeholder="Enter plan name"
              className="w-[90%] border-[1.8px] mt-2 pl-2 h-[35px] rounded-lg"
            />
          </div>
          <div className="w-full">
            <h2>subscription level</h2>
            <select className="mt-2 px-4 py-1 rounded-lg w-[30%]">
              <option value="">premium</option>
              <option value="">basic</option>
            </select>
          </div>
        </div>

        <div className="flex items-start justify-between mt-5">
          <div className="w-full">
            <h2>plan name</h2>
            <textarea
              placeholder="Describe plan"
              className="border-2 w-[90%] pl-2 mt-2 rounded-lg resize-none"
            />
          </div>
          <div className="w-full flex items-center gap-3">
            <div className="">
              <h2>First time promo</h2>
              <input
                type="text"
                placeholder="  20                                %  "
                className="border-2 mt-2 rounded-md h-[35px] "
              />
            </div>
            <div className="">
              <h2>Subsequent promo</h2>
              <input
                type="text"
                placeholder="  50                                %  "
                className="border-2 mt-2 rounded-md h-[35px] "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <hr className="w-full my-9" />
        <div className="w-[95%] mx-auto  ">
          <h2 className="text-primary capitaliz text-lg">Add trial period</h2>
          <p>(optional)</p>
          <div className="flex mt-2 items-start gap-4 w-[35%]">
            <div className="w-full">
              <h2 className="mb-1 ">Duration type</h2>
              <select className="px-[10px] py-[2px] ">
                <option value="">Weeks</option>
              </select>
            </div>
            <div className="w-[70%]">
              <h2 className="mb-1 ">Duration</h2>
              <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4">
                <button className="text-xl font-semibold">-</button>
                <div className="px-[10px] text-base border-l-2 border-r-2">
                  <h3>4</h3>
                </div>
                <button className="text-xl font-semibold">+</button>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full my-9" />
      </div>
    </div>
  );
};

export default AddPlan;
