import React, { useState, useEffect } from "react";
import { PlusIcon, pryPlusIcon } from "../Assets";
import AddDassQuest from "./AddDassQuest";
import { fetchDassQuest } from "../Features/DassSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Shimmer from "./Shimmer";
import StatusCode from "../Ultilities/StatusCode";
import { ToastContainer, toast } from "react-toastify";
import {
  deleteDassQuest,
  deleteDefaultOptions,
  postDassQuest,
  postDefaultOptions,
  updateDassQuest,
  updateDefaultOptions,
} from "../Features/DassQuestPostSlice";
import QuestionModal from "./QuestionModal";
import DefaultOption, { DefaultDassOption } from "./DefaultOption";

const DassQuest = () => {
  const dispatch = useDispatch();
  const DassData = useSelector((state) => state.dass.dass);
  const Status = useSelector((state) => state.dass.status);
  const dassOption = useSelector((state) => state.dassOption?.data);
  const categoriesArray = useSelector((state) => state.category.data);

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isOption, setIsOption] = useState(false);
  const [dassOpen, setDassOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [optionEditMode, setOptionEditMode] = useState(false);
  const [optionActionOpen, setOptionActionOpen] = useState(false);
  const [formState, setFormState] = useState({
    question: "",
  });
  const [optionValues, setOptionValues] = useState([
    {
      option: "",
      value: "0",
    },
  ]);
  const showDassForm = () => {
    setDassOpen(!dassOpen);
  };
  useEffect(() => {
    dispatch(fetchDassQuest());
  }, [dispatch]);

  const showFormEdit = () => {
    setEditMode(!editMode);
    setDassOpen(!dassOpen);
  };

  // /// // // // //  Create // // / // // /
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const createDassQuest = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
    };

    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length < 1) {
      alert("Category is required");
    } else {
      setLoading(true);
      dispatch(postDassQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Question Added successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // /// // // // // Modal display // // / // // /
  const pickQuestion = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  // /// // // // // Delete Question // // / // // /
  const deleteQuestion = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteDassQuest({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Question Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // /// // // // // Update Question // // / // // /
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const updateQuestion = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedItem.id,
      question: formState.question,
      categories: categories,
    };
    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length < 1) {
      alert("Category is required");
    } else {
      setLoading(true);
      dispatch(updateDassQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Question updated successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // // Adding Default Option // // // // // // //
  const showDefaultOptionForm = (e) => {
    e.preventDefault();
    setIsOption(!isOption);
  };
  const optionChange = (e, index) => {
    const { name, value } = e.target;
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [name]: value,
      };
      return newValues;
    });
  };
  const validate = (parrams) => {
    return parrams.some((option) => option.option === "");
  };
  const increase = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value < 15) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) + 1,
        };
      }
      return newValues;
    });
  };
  const decrease = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value > 0) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) - 1,
        };
      }
      return newValues;
    });
  };
  const addOptionField = () => {
    setOptionValues((prev) => [...prev, { option: "", value: "1" }]);
  };
  const removeOptionField = () => {
    setOptionValues((prev) => {
      const updatedNum = [...prev];
      updatedNum.pop();
      return updatedNum;
    });
  };

  const submitAddOption = (e) => {
    e.preventDefault();
    const options = { options: optionValues };
    if (validate(optionValues)) {
      alert("Option fields are required");
    } else {
      setLoading(true);
      dispatch(postDefaultOptions(options))
        .unwrap()
        .then((dataRes) => {
          if (dataRes.status || dataRes.status === 200) {
            showToast(`Options added successfully`);
            setTimeout(() => {
              window.location.reload();
            }, 2100);
          } else {
            alert(dataRes.message);
          }
          setLoading(false);
        });
    }
  };

  // toast
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  // // // //  Edit and Delete Default Option  // // // //
  const optionAction = (item) => {
    setOptionActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedOption(item);
  };
  // Delete
  const deleteOption = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteDefaultOptions({ id: selectedOption.id }))
      .unwrap()
      .then((data) => {
        if (data.status || data.status === 200) {
          showToast("Question Deleted successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2100);
        } else alert(data.message);
        setLoading(false);
      })
      .catch((error) => {
        alert("error deleting post: ", error);
        setLoading(false);
      });
  };
  // Update Edit
  useEffect(() => {
    if (!optionEditMode) return;
    setOptionValues(dassOption);
  }, [optionEditMode, dassOption]);
  const readyToEditOption = () => {
    setOptionEditMode(!optionEditMode);
  };
  const submitUpdateOption = (e) => {
    e.preventDefault();
    const options = {
      options: optionValues.map((each) => ({
        id: each.id,
        option: each.option,
        value: each.value,
      })),
    };

    if (validate(optionValues)) {
      alert("Option fields are required");
    } else {
      setLoading(true);
      dispatch(updateDefaultOptions(options))
        .unwrap()
        .then((dataRes) => {
          if (dataRes.status || dataRes.status === 200) {
            showToast(`Options Updated successfully`);
            setTimeout(() => {
              window.location.reload();
            }, 2100);
          } else {
            alert(dataRes.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <ToastContainer />
      {isOption ? (
        <DefaultOption
          optionValues={optionValues}
          optionChange={optionChange}
          decrease={decrease}
          increase={increase}
          addOptionField={addOptionField}
          removeOptionField={removeOptionField}
          handleSubmit={submitAddOption}
          loading={loading}
          specicalDefault={"0"}
          optionActionOpen={optionActionOpen}
          optionAction={optionAction}
          handleDeleteOption={deleteOption}
          optionEditMode={optionEditMode}
          toEditOptionMode={readyToEditOption}
          handleUpdate={submitUpdateOption}
        />
      ) : !dassOpen ? (
        <>
          <div className="flex items-center justify-end gap-5 w-full">
            <button
              className="text-primary bg-white text-base flex items-center gap-2 py-[4px] px-[15px] rounded-xl border-primary border-2"
              onClick={showDefaultOptionForm}
            >
              General option
            </button>
            <button
              className="bg-primary text-white text-base flex items-center gap-2 py-[6px] px-[15px] rounded-xl"
              onClick={showDassForm}
            >
              <img src={PlusIcon} alt="plus" />
              Add question
            </button>
          </div>
          <div className="relative">
            <div className="w-full py-2 mt-6 bg-white rounded-lg shadow-custom">
              <div className="w-[93%] mx-auto mt-2 mb-4">
                <h3 className="text-lg font-medium capitalize text-primary">
                  DASS-21 Questions
                </h3>
              </div>
              <table className="table w-full mx-auto divide-y divide-gray-200 ">
                <thead className="bg-[#ECECEC]">
                  <tr>
                    <th className="text-primary px-2 text-[14px] ">S/N</th>
                    <th className="text-primary px-2 text-[14px] ">Question</th>
                    <th className="text-primary px-2 text-[14px] ">Category</th>
                    <th className="text-primary px-2 text-[14px] ">
                      Create at
                    </th>
                    <th className="text-primary px-2 text-[14px] ">
                      Last Updated
                    </th>
                  </tr>
                </thead>
                {Status === StatusCode.LOADING ? (
                  <div className="fixed w-full top-[70%] left-[5%] ">
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <tbody>
                    {DassData.map((data, index) => (
                      <tr
                        className="text-center border-b hover:bg-[#E9F3F3]"
                        key={data.id}
                        onClick={() => pickQuestion(data)}
                      >
                        <td className="py-2 px-5 text-[13px] font-semibold border-r border-gray-400">
                          {index + 1}
                        </td>
                        <td className="py-2 px-5 text-[13px] font-semibold text-sm capitalize border-r border-gray-400">
                          {data.question}
                        </td>
                        <td className="py-2 px-5 text-[13px] font-semibold text-sm border-r border-gray-400">
                          {data.categories}
                        </td>
                        <td className="py-2 px-5 text-[13px] text-sm font-semibold border-r border-gray-400">
                          {moment(data.created_at).startOf("ss").fromNow()}
                        </td>
                        <td className="flex items-center justify-center gap-3 py-2 px-5 text-[13px] text-sm font-semibold">
                          {moment(data.updated_at).startOf("ss").fromNow()}
                          {/* <img src={Dot} alt="dot-icon" /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
              <QuestionModal
                selectedItem={selectedItem}
                loading={loading}
                updateItem={showFormEdit}
                closeModal={closeModal}
                deleteItem={deleteQuestion}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <AddDassQuest
            onCancle={() => window.location.reload()}
            formState={formState}
            loading={loading}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChange}
            handleCreate={createDassQuest}
            categories={categories}
            editMode={editMode}
            handleUpdate={updateQuestion}
          />
        </>
      )}
    </div>
  );
};

export default DassQuest;
