import React from "react";

const Button = ({
  btnIcon,
  btnText,
  btnStyle,
  btnClick,
  btntype,
  btnDisabled,
}) => {
  return (
    <button
      className={`${
        btnIcon ? "flex items-center" : ""
      } ${btnStyle} px-10 py-2 text-mainWhite rounded-lg hover-effect ${
        btnDisabled ? "" : "cursor-pointer"
      }`}
      onClick={btnClick}
      disabled={btnDisabled}
      type={btntype}
    >
      {btnIcon ? <img src={btnIcon} alt="" className="mr-2" /> : ""}
      <span>{btnText}</span>
    </button>
  );
};

export default Button;
