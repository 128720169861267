import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

const Subscription = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <h1 className="text-[#0F2851] text-xl font-semibold">Subscription</h1>
        <div className="flex items-center gap-3">
          <p>Home</p>
          <p>Subscription</p>
        </div>
      </div>
      <div className=" mt-9  flex items-center p-[1px] rounded-lg ">
        <Link
          to=""
          className={`px-4 py-[3px] text-base hover-effect rounded-l-lg ${
            pathname === "/sub"
              ? "bg-[#207384] text-white"
              : "bg-mainWhite text-[#207384]"
          }`}
        >
          Plans
        </Link>

        <Link
          to="promos"
          className={`px-3 py-[3px] text-base hover-effect ${
            pathname === "/sub/promos"
              ? "bg-[#207384] text-white"
              : "bg-mainWhite text-[#207384] "
          }`}
        >
          Promo code
        </Link>

        <Link
          to="free-trial"
          className={`px-3 py-[3px] text-base hover-effect ${
            pathname === "/sub/free-trial"
              ? "bg-[#207384] text-white"
              : "bg-mainWhite text-[#207384] "
          }`}
        >
          Free trial
        </Link>

        <Link
          to="free-package"
          className={`px-3 py-[3px] text-base hover-effect rounded-r-lg ${
            pathname === "/sub/free-package"
              ? "bg-[#207384] text-white"
              : "bg-mainWhite text-[#207384] "
          }`}
        >
          Free package
        </Link>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Subscription;
