import React, { useEffect } from "react";
import { AreaChart, BarChart, UserCard } from "../Components";
import moment from "moment";
import { IoChevronDownOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboard } from "../Features/DashSlice";
import { Buser, Dashuser, Puser, Revenue } from "../Assets";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dash = useSelector((state) => state.dash.data);
  const Recent = dash?.recent_signups;
  const dashData = [
    {
      id: 1,
      prefix: "$",
      title: "total revenue",
      icon: Revenue,
      value: `${dash?.total_revenue}`,
      period: "last week",
      durate: "in last week",
    },
    {
      id: 2,
      prefix: "",
      title: "users",
      icon: Dashuser,
      value: `${dash?.total_users}`,
      period: "today",
      durate: "today",
    },
    {
      id: 3,
      prefix: "",
      title: "premium users",
      icon: Puser,
      value: `${dash?.premium_users}`,
      period: "last week",
      durate: "in last week",
    },
    {
      id: 4,
      prefix: "",
      title: "basic users",
      icon: Buser,
      value: `${dash?.basic_users}`,
      period: "today",
      durate: "in last week",
    },
  ];
  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <h1>Dashboard</h1>
        <div className="flex items-center gap-3">
          <p>Home</p>
          <p>Dashboard</p>
        </div>
      </div>
      <div className="grid w-full grid-cols-2 mt-4 gap-7 ">
        {dashData.map((datas, index) => (
          <div key={index}>
            <UserCard {...datas} />
          </div>
        ))}
      </div>
      <div className="flex items-start w-full gap-4 mt-10">
        <div className="w-[70%] bg-white rounded-lg py-2">
          <div className="flex items-center justify-between w-[95%] mx-auto my-2">
            <h3 className="">overview of subscribers</h3>
            <div className="flex items-center gap-3 px-2 py-1 rounded-md bg-secondary">
              <p className="">this month</p>
              <IoChevronDownOutline />
            </div>
          </div>
          <div className="w-full my-3">
            <hr className="w-full" />
          </div>
          <div className="w-[95%] mx-auto my-2">
            <AreaChart />
          </div>
        </div>

        <div className="w-[35%] bg-white rounded-lg py-3">
          <h3 className="text-center">Overview of Users’ Interest </h3>
          <div className=" w-[92%] mx-auto py-2">
            <BarChart />
          </div>
        </div>
      </div>

      <div className="w-full mt-6 bg-white rounded-lg">
        <div className="w-[95%] mx-auto py-3">
          <h3 className="text-xl font-semibold capitalize">recent sign ups</h3>
          <div className="mt-4">
            <table className="table w-full divide-y divide-gray-200">
              <thead>
                <tr className="text-left">
                  <th className="pl-3 pb-[6px] text-base capitalize">Users</th>
                  <th className="pl-3 pb-[6px] text-base capitalize">Date</th>
                  <th className="pl-3 pb-[6px] text-base capitalize">Status</th>
                </tr>
              </thead>
              {Recent?.map((datas, index) => (
                <tbody key={index}>
                  <tr className="border-b">
                    <td className="py-[6px]">{datas.user}</td>
                    <td className="py-[6px]">
                      {moment(datas.date_joined).startOf("ss").fromNow()}
                    </td>
                    <td className="py-[6px]">{datas.status}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
