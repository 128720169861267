import React, { useState } from "react";
import { changePassword } from "../Features/AuthSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "./Button";
import Shimmer from "./Shimmer";

const PasswordSetting = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prev) => ({ ...prev, [id]: value }));
  };
  const update = (e) => {
    e.preventDefault();
    if (formState.password === formState.password_confirmation) {
      setIsLoading(true);
      dispatch(changePassword(formState))
        .unwrap()
        .then((response) => {
          if (response.ok) {
            showToast("Password changed successfully");
          }
        })
        .then(() => setIsLoading(false))
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        )
        .catch((error) => {
          alert("error updating post:");
          setIsLoading(false);
        });
    } else alert("Password does not match");
  };
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <div className=" w-full">
        <div className="flex flex-col w-full ">
          <label
            htmlFor="old_password"
            className="capitalize mb-3 text-lg font-medium"
          >
            current password
          </label>
          <input
            id="old_password"
            name="old_password"
            onChange={(e) => handleChange(e)}
            type="password"
            className="border px-2 rounded-xl bg-[#F0F1F9]  h-[35px]"
          />
        </div>

        <div className="flex flex-col w-full mt-6 ">
          <label
            htmlFor="password"
            className="capitalize mb-3 text-lg font-medium"
          >
            new password
          </label>
          <input
            id="password"
            name="password"
            onChange={(e) => handleChange(e)}
            type="password"
            className="border px-2 rounded-xl bg-[#F0F1F9]  h-[35px]"
          />
        </div>
        <div className="flex flex-col w-full mt-6 ">
          <label
            htmlFor="password_confirmation"
            className="capitalize mb-3 text-lg font-medium"
          >
            confirm new password
          </label>
          <input
            id="password_confirmation"
            name="password_confirmation"
            onChange={(e) => handleChange(e)}
            type="password"
            className="border px-2 rounded-xl bg-[#F0F1F9]  h-[35px]"
          />
        </div>
      </div>

      <div className="mt-12">
        <Button
          btnClick={update}
          btnDisabled={isLoading}
          btnStyle={isLoading ? "bg-inactiveText" : "bg-primary"}
          btnText={
            isLoading ? <Shimmer height={20} color={"#fff"} /> : "Update"
          }
        />
      </div>
    </div>
  );
};

export default PasswordSetting;
