import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  booksData: {},
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const getBooks = createAsyncThunk("get/getBooks", async (current) => {
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/books?page=${current ?? 1}`,
      config
    );

    const final = response.data.data;
    return final;
  } catch (error) {
    throw error;
  }
});

const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBooks.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.booksData = payload;
      });
  },
});

export const { setCurrentPage } = bookSlice.actions;

export default bookSlice.reducer;
