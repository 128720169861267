import React from "react";
import Shimmer from "./Shimmer";
import Button from "./Button";

const AdCategory = ({
  addOnClick,
  handleChange,
  formState,
  loading,
  editMode,
  updateOnClick,
}) => {
  return (
    <div>
      <div className="w-full flex items-start mb-5">
        <h3 className="w-full justify-end flex items-end text-primary text-lg">
          Add category
        </h3>
      </div>
      <div className="bg-white rounded-lg shadow-custom w-full py-3">
        <div className="w-[95%] mx-auto  ">
          <h3 className="text-primary text-lg font-semibold">
            {editMode ? "Update Category" : "Add Category"}
          </h3>
        </div>

        <hr className="w-full my-2" />
        <div className="w-[95%] mx-auto flex items-center">
          <div className="w-[70%] flex flex-col items-start">
            <label className="capitalize">category title</label>
            <div className="mt-3 w-full">
              <input
                type="text"
                id="category"
                value={formState?.category}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="border-[1px] pl-2 w-[70%] h-[35px] rounded-lg "
                placeholder="Enter Category Title"
              />
            </div>
          </div>

          <div>
            <label htmlFor="premium_category">Subscription Scope</label>
            <select
              value={formState?.premium_category}
              onChange={(e) => {
                handleChange(e);
              }}
              name="subscription_level"
              id="premium_category"
              className=" border-2 py-2 w-full rounded-lg"
            >
              <option value="0">Basic</option>
              <option value="1">Premium</option>
            </select>
          </div>
        </div>

        <div className="mt-8 pb-8 w-[95%] mx-auto flex items-start">
          <div className="w-[70%] flex flex-col items-start">
            <label className=" capitalize">description</label>
            <textarea
              id="description"
              value={formState?.description}
              onChange={(e) => {
                handleChange(e);
              }}
              className="h-[80px] w-[70%]  mt-[10px] border-[2px] rounded-lg resize-none p-2"
            />

            <div className="flex items-center gap-10 my-7">
              <button
                disabled={loading}
                className={`${
                  loading ? "bg-[#898D9E]" : "bg-primary"
                } px-5 py-[6px] hover-effect text-base text-white rounded-xl`}
                onClick={editMode ? updateOnClick : addOnClick}
              >
                {loading ? (
                  <Shimmer height={20} color={"#fff"} />
                ) : editMode ? (
                  "Update Category"
                ) : (
                  "Add category"
                )}
              </button>

              <button
                className="hover-effect bg-[#898D9E] px-5 capitalize py-[4px] rounded-xl text-white text-base"
                onClick={() => window.location.reload()}
              >
                cancle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdCategory;

export const ScopeForm = ({
  loading,
  editRangeMode,
  updateRange,
  submitAddScore,
  scoreState,
  handleChange,
  increaseMin,
  decreaseMin,
  increaseMax,
  decreaseMax,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-custom p-5 flex flex-col gap-4">
      <h3 className="text-primary mb-5">
        {editRangeMode ? "Update Score Range" : "Add Score Range"}
      </h3>

      <div className=" flex items-center justify-between">
        <div className="w-1/2">
          <label className="capitalize text-lg ">score range</label>
          <div className="flex items-center gap-4 mt-[10px]">
            <div className="flex flex-col w-full">
              <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[90%]">
                <button
                  className="hover-effect text-xl font-semibold cursor-pointer"
                  onClick={decreaseMin}
                >
                  -
                </button>
                <input
                  type="tex"
                  id="min"
                  className="w-1/2 border-x-2 text-center"
                  value={scoreState.min}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="hover-effect text-xl font-semibold cursor-pointer"
                  onClick={increaseMin}
                >
                  +
                </button>
              </div>

              <p>min value</p>
            </div>

            <div className="flex flex-col w-full">
              <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[90%]">
                <button
                  className="hover-effect text-xl font-semibold cursor-pointer"
                  onClick={decreaseMax}
                >
                  -
                </button>
                <input
                  type="tex"
                  id="max"
                  className="w-1/2 border-x-2 text-center"
                  value={scoreState.max}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="hover-effect text-xl font-semibold cursor-pointer"
                  onClick={increaseMax}
                >
                  +
                </button>
              </div>
              <p>max value</p>
            </div>
          </div>
        </div>

        <div className="w-1/2 flex flex-col">
          <label htmlFor="" className="capitalize text-lg">
            verdict
          </label>

          <select
            className="py-2 border-2 rounded-lg"
            value={scoreState?.verdict}
            id="verdict"
            onChange={(e) => handleChange(e)}
          >
            <option value="verdict">verdict</option>
            <option value="extremely severe">extremely severe</option>
            <option value="severe">severe</option>
            <option value="mild">mild</option>
            <option value="normal">normal</option>
            <option value="moderate">moderate</option>
          </select>
        </div>
      </div>

      <div className="flex items-center gap-5">
        <Button
          btnClick={editRangeMode ? updateRange : submitAddScore}
          btnDisabled={loading}
          btnStyle={loading ? "bg-[#898D9E]" : "bg-primary"}
          btnText={
            loading ? (
              <Shimmer height={20} color={"#FFF"} />
            ) : editRangeMode ? (
              "Update Score"
            ) : (
              "Add Score"
            )
          }
        />

        <Button
          btnClick={() => window.location.reload()}
          btnStyle={"bg-[#898D9E]"}
          btnText={"Cancel"}
        />
      </div>
    </div>
  );
};
