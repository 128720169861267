import React, { useEffect } from "react";
import AccordionItem from "./AccordionItem";
import ReactPagination from "./ReactPagination";
import { useDispatch, useSelector } from "react-redux";
import { getDeactivatedUser } from "../Features/DeactivateUserSlice";

const DeactivatedUser = () => {
  const dispatch = useDispatch();

  const deactivatedUsers = useSelector((state) => state.deactivtedUser);
  const deactivatedTotalPage = deactivatedUsers?.deactivtedUser?.last_page;
  const deactivatedUsersParPage = deactivatedUsers?.deactivtedUser.data;
  useEffect(() => {
    dispatch(getDeactivatedUser());
  }, [dispatch]);
  return (
    <div>
      <h1 className="my-5 text-2xl font-bold capitalize">Deactivated users</h1>
      {deactivatedUsersParPage ? (
        <div className="relative overflow-hidden">
          <div className="flex flex-col gap-3">
            {deactivatedUsersParPage?.map((eachUser) => (
              <AccordionItem
                key={eachUser}
                {...eachUser}
                profile_photo={eachUser.user.profile_photo}
                fullName={eachUser.user.name}
                email={eachUser.user.email}
                text={eachUser.reason}
                remarks={eachUser.remarks}
              />
            ))}
          </div>
          <div className="mt-6 flex justify-end items-end relative w-full">
            <ReactPagination
              total={deactivatedTotalPage}
              onChanges={(i) => {
                dispatch(getDeactivatedUser(i?.selected + 1));
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full text-center text-3xl font-bold">
          No user is deactivated yet
        </div>
      )}
    </div>
  );
};

export default DeactivatedUser;
