import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  error: null,
  message: "",
};
const userToken = localStorage.getItem("userToken");

const BASE_URL = "https://psychinsightsapp.net/api";
export const postDassQuest = createAsyncThunk(
  "post/dassQuest",
  async (data) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(`${BASE_URL}/admin/dass-questions`, config);

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const DassQuestPostSlice = createSlice({
  name: "postDassQuest",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(postDassQuest.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(postDassQuest.fulfilled, (state, { payload }) => {
        state.status = StatusCode.LOADING;
        state.message = payload?.message;
      })
      .addCase(postDassQuest.rejected, (state, { payload }) => {
        state.status = StatusCode.ERROR;
        state.error = payload;
      });
  },
});

export default DassQuestPostSlice.reducer;

export const deleteDassQuest = createAsyncThunk(
  "delete/question",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/dass-questions/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE // // // // // //
export const updateDassQuest = createAsyncThunk(
  "update/question",
  async (data) => {
    try {
      const config = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/dass-questions/${data.id}`,
        config
      );

      //   success
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const postDefaultOptions = createAsyncThunk(
  "post/dassQuest",
  async (data) => {
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `${BASE_URL}/admin/dass-question-options`,
        config
      );
      const dataRes = await response.json();
      return dataRes;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteDefaultOptions = createAsyncThunk(
  "delete",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/dass-question-options/${id}`,
        config
      );

      const data = await responce.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateDefaultOptions = createAsyncThunk(
  "post/dassQuest",
  async (data) => {
    try {
      const config = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `${BASE_URL}/admin/dass-question-options`,
        config
      );
      const dataRes = await response.json();
      return dataRes;
    } catch (error) {
      throw error;
    }
  }
);
