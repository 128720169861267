import Button from "./Button";
import TableMain from "./TableMain";
import { Dot, PlusIcon } from "../Assets";
import ResourceList from "./ResourceList";
import ResourceListHead from "./ResourceListHead";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewReadReflect,
  AddNewReflect,
  DeleteReflection,
  Publish,
  UpdateReflection,
  deleteReadReflect,
  getReadReflect,
  updateNewReadReflect,
} from "../Features/ReasReflectSlice";
import TableRow from "./TableRow";
import StrategisForm from "./StrategisForm";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import StrategiesModal from "./StrategiesModal";
import { ToastContainer, toast } from "react-toastify";
import ReactPagination from "./ReactPagination";
import { Editor } from "@tinymce/tinymce-react";
import InputTag from "./InputTag";
import { readReflectSummary } from "../Features/ReadReflectSummary";

const ReadReflect = () => {
  const dispatch = useDispatch();
  const categoriesArray = useSelector((state) => state.category.data);
  const readRflectData = useSelector((state) => state.readReflect?.readReflect);
  const readReflectStatus = useSelector((state) => state.readReflect.status);
  const readReflectSummaryData = useSelector(
    (state) => state.readReflectSummary?.readReflectSummary
  );
  const popularReadReflect = readReflectSummaryData?.data;

  const [loading, setLoading] = useState(false);
  const [openFom, setOpenForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refFormModal, setRefFormModal] = useState(false);
  const [itForPublish, setItForPublish] = useState(false);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [reflectionEditOpen, setReflectionEditOpen] = useState(false);
  const [selectedIReflection, setSelectedIReflection] = useState(null);
  const [editReflectionMode, setEditReflectionMode] = useState(false);
  const [reflectionCount, setReflectionCount] = useState(
    selectedItem?.reflections?.length || 1
  );
  const [reflectionValues, setReflectionValues] = useState(
    Array.from({ length: reflectionCount }, () => "")
  );
  const [formState, setFormState] = useState({
    title: "",
    subscription_level: 0,
  });
  const [reflectionFormState, setReflectionFormState] = useState({
    reflection: "",
  });
  const editorRefs = {
    protocols: useRef(null),
    overview: useRef(null),
  };

  useEffect(() => {
    dispatch(readReflectSummary());
  }, [dispatch]);
  useEffect(() => {
    setReflectionFormState(selectedIReflection);
  }, [selectedIReflection]);
  useEffect(() => {
    dispatch(getReadReflect());
  }, [dispatch]);

  // // // // // SUMMARY // // // // //

  // // // // // //Edit Single Reflection // // // // // //
  const openSingleRefEditForm = () => {
    setEditReflectionMode(!editReflectionMode);
  };

  const updateReflectionSubmit = () => {
    setLoading(true);
    dispatch(
      UpdateReflection({
        id: selectedIReflection.id,
        reflection: reflectionFormState.reflection,
      })
    )
      .unwrap()
      .then(() => {
        showToast(`Reflection Updated successfully`);
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // //Delete Single Reflection // // // // // //
  const handleDeleteReflection = (e) => {
    e.preventDefault();
    dispatch(DeleteReflection({ id: selectedIReflection.id }))
      .unwrap()
      .then(() => {
        setModalIsOpen(!modalIsOpen);
        showToast("A Read Reflect Deleted successfully.");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // Modal For Selected Item // // // // // //
  const openTheModal = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeTheModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const reflectionOption = (item) => {
    setReflectionEditOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedIReflection(item);
  };
  const handleFormOpen = (e) => {
    e.preventDefault();
    setOpenForm(!openFom);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    setOpenForm(!openFom);
    window.location.reload();
  };
  const addReflectInput = (e) => {
    e.preventDefault();
    setReflectionCount((prevCount) => prevCount + 1);
  };
  const removeReflectInput = (e) => {
    e.preventDefault();
    setReflectionCount((prevCount) => prevCount - 1);
  };

  // // // // // // Fetch // // // // // //
  const allStrategies = readRflectData?.data?.map((readReflect, index) => (
    <TableRow
      key={readReflect.id}
      {...readReflect}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(readReflect.created_at).toDateString()}
      subscription_level={new Date(readReflect.updated_at).toDateString()}
      tableRowclick={() => openTheModal(readReflect)}
    />
  ));

  // // // // // // Reflections // // // // // //
  const reflectionData = selectedItem?.reflections?.map((each) => (
    <div
      key={each?.id}
      className="refextion relative flex text-start justify-between gap-5 itemscenter py-1 px-3 bg-[#E2F8F9]"
    >
      <p>{each?.reflection}</p>
      <img
        src={Dot}
        alt="option_icon"
        className="cursor-pointer"
        onClick={() => reflectionOption(each)}
      />

      <div
        className={`forFelectionEdit ${
          reflectionEditOpen[each.id] ? "" : "hidden"
        } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
      >
        <p
          className="text-xs cursor-pointer text-[#0F2851]"
          onClick={() => reflectionOption(each)}
        >
          Close
        </p>
        <p
          className="text-xs cursor-pointer text-[#207384]"
          onClick={openSingleRefEditForm}
        >
          Edit
        </p>
        <p
          className="text-xs cursor-pointer text-[#FF0101]"
          onClick={handleDeleteReflection}
        >
          Delete
        </p>
      </div>
    </div>
  ));

  // // // // // // Create Read Reflect // // // // // //
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
    setReflectionFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const reflectionChange = (e, index) => {
    const newValues = [...reflectionValues];
    newValues[index] = e.target.value;
    setReflectionValues(newValues);
  };
  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  const createReadReflect = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      reflections: reflectionValues,
      protocols: editorRefs.protocols?.current.target.getContent(),
      overview: editorRefs.overview?.current.target.getContent(),
    };

    const formData = new FormData();
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields.");
      return;
    } else if (!selectedFile) {
      return alert("please select an image file");
    }
    formData.append("photo", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(AddNewReadReflect(formData))
      .unwrap()
      .then(() => {
        setLoading(false); // Add it here
        showToast(
          `A new Read & Reflect titiled ${formState.title} has been added successfully`
        );
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // // Update Read Reflect // // // // // //

  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setOpenForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updateReadReflect = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const updateData = {
      id: selectedItem.id,
      title: formState.title,
      reflections: reflectionValues,
      subscription_level: formState.subscription_level === "Basic" ? 0 : 1,
      overview: editorRefs.overview?.current.target.getContent(),
      protocols: editorRefs.protocols?.current.target.getContent(),
    };

    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("photo", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (selectedItem.id !== null) {
      setLoading(true);
      dispatch(
        updateNewReadReflect({ id: selectedItem.id, formData: formData })
      )
        .unwrap()
        .then(() => {
          showToast(
            `Read & Reflect titiled ${formState.title} has been updated successfully`
          );
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // DELETE // // // // // //
  const handleDeleteReadReflect = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };
    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteReadReflect({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setLoading(false);
          showToast("A Read Reflect Deleted successfully.");
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // // // // // // ADD SINGLE REFLECTION // // // // // //
  const openSingleRefForm = () => {
    setRefFormModal(!refFormModal);
    setModalIsOpen(!modalIsOpen);
  };
  const closeSingleRefForm = () => {
    setRefFormModal(!refFormModal);
    window.location.reload();
  };
  const submitNewReflection = () => {
    setLoading(true);
    dispatch(
      AddNewReflect({
        id: selectedItem?.id,
        reflection: reflectionFormState.reflection,
      })
    )
      .unwrap()
      .then(() => {
        showToast(`A new Reflection has been added successfully`);
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // // // // // // PUBLISH UNPUBLISH // // // // // //
  const publishUnpublish = (e) => {
    e.preventDefault();
    if (selectedItem.id !== null) {
      setItForPublish(true);
      dispatch(Publish({ id: selectedItem.id }))
        .unwrap()
        .then(() => {
          showToast(
            selectedItem.published == "1"
              ? "Strategy unpublished successfully"
              : "Strategy published successfully"
          );
          setItForPublish(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => showToast("error:", error));
    }
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <>
      <div
        className={`forAddNewReflection bg-mainWhite px-5 rounded-lg pb-10 pt-5 
         ${refFormModal || editReflectionMode ? "" : "hidden"}`}
      >
        <div className="reflectionsWrap px-5 my-5 w-1/2">
          <InputTag
            labelFor="reflection"
            labelText={
              editReflectionMode
                ? `Update Reflection`
                : `Add Reflection ${selectedItem?.reflections?.length + 1}`
            }
            inputType="text"
            forValue={reflectionFormState?.reflection}
            forOnChange={handleChange}
          />
        </div>

        <div className="flex items-center gap-10 px-5">
          <Button
            btnClick={
              editReflectionMode ? updateReflectionSubmit : submitNewReflection
            }
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editReflectionMode ? (
                "Update Reflection"
              ) : (
                "Add Reflection"
              )
            }
            btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
          />
          <Button
            btnClick={closeSingleRefForm}
            btnText="Cancle"
            btnStyle="bg-inactiveText"
          />
        </div>
      </div>

      <div
        className={`form ${
          openFom ? "" : "hidden"
        } bg-mainWhite pb-5 rounded-lg`}
      >
        <StrategisForm
          strategisType="Read & Reflect"
          formState={formState}
          categories={categories}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editorRefs={editorRefs}
          currentSub={selectedItem?.subscription_level}
          editMode={editMode}
          selectedItem={selectedItem}
        />

        <div className="px-5 ">
          <label htmlFor="protocols" className="mb-5">
            Add protocol/activity
          </label>
          <Editor
            tinymceScriptSrc={
              "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
              "/tinymce/tinymce.min.js"
            }
            initialValue={editMode ? formState?.protocols : ""}
            onInit={(editorProtocol) => {
              editorRefs.protocols.current = editorProtocol;
            }}
            init={{
              height: 250,
              menubar: true,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "Link Checker",
                "Page Embed",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
                "fontselect",
                "fontsize",
              ],
              toolbar:
                "undo redo | formatselect | fontselect | fontsizeselect | " +
                "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
                "bullist numlist outdent indent | removeformat | link image | table media | " +
                "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
                "preview help wordcount",

              content_style:
                "body { font-family: Helvetica, Arial, sans-serif; font-size: 25px }",

              // setup: function (editor) {
              //   editor.setContent("place your content here");
              // },
            }}
            id="protocols"
          />
        </div>

        <div className="reflectionsWrap px-5 my-5">
          {editMode
            ? ""
            : Array.from({ length: reflectionCount }, (_, index) => (
                <div key={index}>
                  <div className="w-[40%]">
                    <label htmlFor={`reflection-${index + 1}`}>
                      Add reflection {index + 1}
                    </label>
                    <br />
                    <input
                      name="refections"
                      id={`reflection-${index + 1}`}
                      type="text"
                      placeholder=""
                      value={reflectionValues[index]}
                      onChange={(e) => reflectionChange(e, index)}
                      className="bg-transparent rounded-lg px-3 py-[4px] border-2 border-inactiveText w-full"
                    />
                  </div>
                </div>
              ))}
        </div>

        <div className="px-5 my-5">
          {editMode ? (
            ""
          ) : (
            <div className="flex items-center gap-5">
              <Button
                btnClick={addReflectInput}
                btnText="Add more reflection"
                btnStyle="bg-[#78B7C5]"
              />
              {reflectionCount > 1 ? (
                <Button
                  btnClick={removeReflectInput}
                  btnText="Remove a reflection"
                  btnStyle="bg-inactiveText"
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        <div className="px-5 flex gap-10 mt-20">
          <Button
            btnDisabled={loading}
            btnClick={editMode ? updateReadReflect : createReadReflect} //
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Read & Reflect"
              ) : (
                "Add Read & Reflect"
              )
            }
            btnStyle={loading ? "bg-inactiveTex" : "bg-primary"}
            btntype={"submit"}
          />

          <Button
            btnClick={closeAddForm}
            btnText="Cancle"
            btnStyle="bg-inactiveText"
          />
        </div>
      </div>

      <div
        className={`${editReflectionMode ? "hidden" : ""} ${
          modalIsOpen ? "" : "hidden"
        }`}
      >
        {selectedItem && (
          <StrategiesModal
            itemMedia={selectedItem?.photo?.url}
            title={selectedItem?.title}
            categories={selectedItem?.categories}
            overview={selectedItem?.overview}
            content={selectedItem?.protocols}
            reflections={reflectionData}
            subscription_level={selectedItem?.subscription_level}
            publishStatus={selectedItem?.published}
            updated_at={new Date(selectedItem?.updated_at).toDateString()}
            moreAddition="Add Reflection"
            // btns
            publishClick={publishUnpublish}
            updateAction={() => toggleEditMode()}
            addReflectionAction={openSingleRefForm}
            cancelAction={closeTheModal}
            deleteitem={handleDeleteReadReflect}
            loading={loading}
            itForPublish={itForPublish}
          />
        )}
      </div>

      <div
        className={`flex flex-col gap-5 ${editReflectionMode ? "hidden" : ""} ${
          refFormModal ? "hidden" : ""
        } ${modalIsOpen ? "hidden" : ""} ${openFom ? "hidden" : ""}`}
      >
        <div className="ml-auto">
          <Button
            btnText="Add Read & Reflect"
            btnIcon={PlusIcon}
            btnStyle="bg-primary"
            btnClick={handleFormOpen}
          />
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular Strategies</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularReadReflect?.map((strategy) => (
              <div key={strategy.id}>
                <ResourceList
                  {...strategy}
                  photo={strategy?.photo?.url}
                  publication_date={new Date(
                    strategy?.updated_at
                  ).toDateString()}
                  level={strategy.plan}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead
            resourceListType="Read & Reflect"
            placeholder="search Strategy"
          />

          {readReflectStatus === StatusCode.LOADING ? (
            <div>
              <Shimmer height={38} width={38} color={"#207384"} />
            </div>
          ) : (
            <TableMain
              resourceType="Strategies"
              TableRows={allStrategies}
              subLevel="Last Updated"
            />
          )}
          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={readRflectData?.last_page}
              onChanges={(i) => {
                dispatch(getReadReflect(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allStrategies.length * readRflectData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ReadReflect;
