import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategory } from "../Features/CategorySlice";
import StatusCode from "../Ultilities/StatusCode";
import { AdCategory, Button, Shimmer } from "../Components";
import {
  AddNewCategory,
  deleteCategory,
  updateCategory,
} from "../Features/CreateCaterorySlice";
import { ToastContainer, toast } from "react-toastify";
import { ScopeForm } from "../Components/AdCategory";
import {
  addScope,
  deleteScope,
  fetchScope,
  updateScope,
} from "../Features/ScopeSlice";
import { Dot } from "../Assets";

const Category = () => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.category.data);
  const scope = useSelector((state) => state.scope?.scope);
  const Status = useSelector((state) => state.category.status);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [scoreFormOpen, setScoreFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [codeActionOpen, setCodeActionOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [editRangeMode, setEditRangeMode] = useState(false);
  const [formState, setFormState] = useState({
    category: "",
    description: "",
    premium_category: "0",
  });
  const [scoreState, setScoreState] = useState({
    // category_id: selectedItem?.id,
    min: 0,
    max: 1,
    verdict: "",
  });

  const showAddCategory = () => {
    setAddCategory(!addCategory);
  };

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  // Add new category
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
    setScoreState((prevData) => ({ ...prevData, [id]: value }));
  };

  const createNewCategory = (e) => {
    e.preventDefault();
    if (!formState.description) {
      alert("Description is requireed");
    } else if (!formState.category) {
      alert("Category Title is requireed");
    } else {
      setLoading(true);
      dispatch(AddNewCategory(formState))
        .unwrap()
        .then(() => {
          showToast("A new Category has been added");
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
            setLoading(false);
          }, 2100)
        )
        .catch((error) => console.error("Error creating Quote", error));
    }
  };

  // Delete Category
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      dispatch(deleteCategory({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setModalIsOpen(!modalIsOpen);
          showToast(`${selectedItem.category} category deleted successfully.`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error deleting post: ", error);
        });
    }
  };

  const showModal = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };

  // Update
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
  }, [editMode, selectedItem, setFormState]);
  const readyForUpdate = () => {
    setModalIsOpen(false);
    setAddCategory(!addCategory);
    setEditMode(!editMode);
  };
  const updateOnClick = (e) => {
    e.preventDefault();
    const payLoad = {
      category: formState.category,
      description: formState.description,
      premium_category: formState.premium_category,
    };
    setLoading(true);
    dispatch(updateCategory({ id: selectedItem.id, payLoad: payLoad }))
      .unwrap()
      .then(() => {
        showToast("Category Updated");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2100)
      )
      .catch((error) => console.error("Error creating Quote", error));
  };

  // add scope
  const increaseMin = (e) => {
    e.preventDefault();
    setScoreState((prev) => {
      const newLimit = { ...prev };
      newLimit.min = Number(newLimit.min) + 1;
      return newLimit;
    });
  };
  const decreaseMin = (e) => {
    e.preventDefault();
    setScoreState((prev) => {
      const newLimit = { ...prev };
      newLimit.min = Number(newLimit.min) - 1;
      return newLimit;
    });
  };
  const increaseMax = (e) => {
    e.preventDefault();
    setScoreState((prev) => {
      const newLimit = { ...prev };
      newLimit.max = Number(newLimit.max) + 1;
      return newLimit;
    });
  };
  const decreaseMax = (e) => {
    e.preventDefault();
    setScoreState((prev) => {
      const newLimit = { ...prev };
      newLimit.max = Number(newLimit.max) - 1;
      return newLimit;
    });
  };
  const opensScoreForm = (e) => {
    e.preventDefault();
    setScoreFormOpen(!scoreFormOpen);
    setModalIsOpen(false);
  };
  const submitAddScope = () => {
    const payLoad = {
      category_id: selectedItem?.id,
      ...scoreState,
    };
    setLoading(true);
    dispatch(addScope(payLoad))
      .unwrap()
      .then(() => {
        showToast("Score added to category");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2100)
      )
      .catch((error) => console.error("Error creating Quote", error));
  };

  // Fetch scope category
  useEffect(() => {
    dispatch(fetchScope());
  }, [dispatch]);

  // DISPLALE RANGE
  const showRange = (item) => {
    setSelectedScope(item);
  };

  // PERFORM ACTION ON EACH RANGE
  const action = (item) => {
    setCodeActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedRange(item);
  };
  // Delete score range
  const deleteRange = () => {
    setLoading(true);
    dispatch(deleteScope({ id: selectedRange.id }))
      .unwrap()
      .then(() => {
        showToast(`Rancge deleted successfully.`);
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        alert("Error deleting post: ", error);
      });
  };
  // Update score range
  const readyToUpdateRange = () => {
    setScoreState(selectedRange);
    setScoreFormOpen(!scoreFormOpen);
    setEditRangeMode(!editRangeMode);
  };
  const updateRange = () => {
    const payLoad = {
      category_id: scoreState.category_id,
      min: scoreState.min,
      max: scoreState.max,
      verdict: scoreState.verdict,
    };
    setLoading(true);
    dispatch(updateScope({ id: scoreState.id, payLoad: payLoad }))
      .unwrap()
      .then(() => {
        showToast("Score updated successfully");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2100)
      )
      .catch((error) => console.error("Error creating Quote", error));
  };

  // toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <>
      <div className={`relative`}>
        {!addCategory ? (
          <>
            <div>
              <div className="flex items-center justify-between w-full">
                <h1>Category</h1>
                <div className="flex items-center gap-3">
                  <p>Home</p>
                  <p className="text-primary">Category</p>
                </div>
              </div>

              <div className="flex items-center justify-end my-4">
                <button
                  className="hover-effect bg-primary py-[6px] px-5 rounded-xl text-white text-sm"
                  onClick={showAddCategory}
                >
                  Add Category
                </button>
              </div>

              <div className="flex flex-col gap-5 mt-12">
                <div className="catWitScop">
                  <div className="mt-5">
                    <div className="flex gap-5 w-full">
                      <div className="w-1/3 bg-white shadow-lg p-5">
                        <h1 className="text-2xl capitalize mb-3">
                          Category with score ranges
                        </h1>

                        <table className="w-full">
                          <thead className="bg-[#ECECEC] p-2">
                            <tr>
                              <th className="text-primary border-r border-gray-400 ">
                                S/N
                              </th>
                              <th className="text-primary border-r border-gray-400 ">
                                Category
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {scope.map((category, index) => (
                              <tr
                                className="text-center border-b cursor-pointer hover:bg-[#E9F3F3]"
                                key={category.id}
                                onClick={() => showRange(category)}
                              >
                                <td className=" py-2 border-r border-gray-400">
                                  {index + 1}
                                </td>
                                <td className=" py-2 border-r border-gray-400">
                                  {category.category}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="w-2/3 bg-white shadow-lg p-5 rounded-lg">
                        {!selectedScope ? (
                          <div className="flex items-center justify-center h-full w-full text-primary text-2xl text-center">
                            Select a category to see Score Ranges
                          </div>
                        ) : (
                          <div className="w-full">
                            <h1 className="text-2xl capitalize mb-3">
                              {selectedScope?.category}
                            </h1>

                            {selectedScope?.ranges?.length < 1 ? (
                              <div className="flex items-center justify-center h-full w-full text-primary text-2xl text-center">
                                The selected category does not have score range
                                yet
                              </div>
                            ) : (
                              <table className="w-full">
                                <thead className="bg-[#ECECEC] p-2">
                                  <tr>
                                    <th className="text-primary border-r border-gray-400 ">
                                      S/N
                                    </th>
                                    <th className="text-primary border-r border-gray-400 ">
                                      Min
                                    </th>
                                    <th className="text-primary border-r border-gray-400 ">
                                      Max
                                    </th>
                                    <th className="text-primary border-r border-gray-400 ">
                                      Verdit
                                    </th>
                                    <th className="text-primary border-r border-gray-400 ">
                                      Last Updated
                                    </th>
                                    <th className="text-primary border-r border-gray-400 ">
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {selectedScope?.ranges.map((range, index) => (
                                    <tr
                                      className="text-center border-b hover:bg-[#E9F3F3]"
                                      key={range.id}
                                      onClick={() => ""}
                                    >
                                      <td className=" py-2 border-r border-gray-400">
                                        {index + 1}
                                      </td>
                                      <td className=" py-2 border-r border-gray-400">
                                        {range.min}
                                      </td>
                                      <td className=" py-2 border-r border-gray-400">
                                        {range.max}
                                      </td>
                                      <td className=" py-2 border-r border-gray-400">
                                        {range.verdict}
                                      </td>
                                      <td className=" py-2 border-r border-gray-400">
                                        {new Date(
                                          range.updated_at
                                        ).toDateString()}
                                      </td>
                                      <td className=" py-2 border-r border-gray-400 flex items-center justify-center">
                                        <img
                                          src={Dot}
                                          alt=""
                                          className="cursor-pointer"
                                          onClick={() => action(range)}
                                        />

                                        <div
                                          className={`${
                                            codeActionOpen?.[range?.id]
                                              ? ""
                                              : "hidden"
                                          } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-5 shadow-lg z-10`}
                                        >
                                          <p
                                            className="text-xs cursor-pointer text-[#0F2851]"
                                            onClick={() => action(range)}
                                          >
                                            Close
                                          </p>
                                          <p
                                            className="text-xs cursor-pointer text-[#207384]"
                                            onClick={readyToUpdateRange}
                                          >
                                            Update
                                          </p>
                                          <p
                                            className="text-xs cursor-pointer text-[#FF0101]"
                                            onClick={deleteRange}
                                          >
                                            {loading ? (
                                              <Shimmer
                                                color={"#898D9E"}
                                                height={10}
                                              />
                                            ) : (
                                              "Delete"
                                            )}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white py-4 w-full shadow-custom rounded-xl">
                  <div className="w-[95%] mx-auto">
                    <h2 className="text-primary font-semibold text-xl capitalize my-6">
                      Category
                    </h2>
                  </div>
                  <table className="table w-[95%] mx-auto divide-y divide-gray-200 ">
                    <thead className="bg-[#ECECEC]">
                      <tr>
                        <th className="text-primary border-r border-gray-400 ">
                          S/N
                        </th>
                        <th className="text-primary border-r border-gray-400 ">
                          Category
                        </th>
                        <th className="text-primary border-r border-gray-400 ">
                          Created at
                        </th>
                        <th className="text-primary border-r border-gray-400 ">
                          Updated at
                        </th>
                        <th className="text-primary ">Plan</th>
                      </tr>
                    </thead>
                    {Status === StatusCode.LOADING ? (
                      <div className="fixed w-full top-[60%] left-[5%] ">
                        <Shimmer height={38} width={38} color={"#207384"} />
                      </div>
                    ) : (
                      <tbody>
                        {Data.map((data, index) => (
                          <tr
                            className="text-center border-b cursor-pointer hover:bg-[#E9F3F3]"
                            key={data.id}
                            onClick={() => showModal(data)}
                          >
                            <td className=" py-2 border-r border-gray-400">
                              {index + 1}
                            </td>
                            <td className=" py-2 border-r border-gray-400">
                              {data.category}
                            </td>
                            <td className=" py-2 border-r border-gray-400">
                              {new Date(data.created_at).toDateString()}
                            </td>
                            <td className=" py-2 border-r border-gray-400">
                              {new Date(data.updated_at).toDateString()}
                            </td>
                            <td className=" py-2 flex items-center justify-center gap-9">
                              {`${
                                data.premium_category == "1"
                                  ? "Premium"
                                  : "Basic"
                              }`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <AdCategory
            updateOnClick={updateOnClick}
            editMode={editMode}
            loading={loading}
            addOnClick={createNewCategory}
            handleChange={handleChange}
            formState={formState}
          />
        )}

        <div className={`${modalIsOpen ? "modalfloat" : "hidden"} `}>
          <div className="modal bg-mainWhite w-[60%] flex flex-col items-center justify-center gap-10 p-10 rounded-lg shadow-lg">
            <p className="mb-5 text-center">
              You have selected the{" "}
              <span className="text-primary underline font-bold">{`${selectedItem?.category}`}</span>{" "}
              category, which is a{" "}
              {selectedItem?.premium_category == "1"
                ? "Premium category, will you like to add score ?"
                : "Basic category, update to premium if you want to add score"}
            </p>

            <div className="flex items-center gap-5">
              <Button
                btnText="Update"
                btnStyle="bg-primary"
                btnClick={readyForUpdate}
              />
              {selectedItem?.premium_category == "1" ? (
                <Button
                  btnText="Add Score"
                  btnStyle="bg-primary"
                  btnClick={opensScoreForm}
                />
              ) : (
                ""
              )}

              <Button
                btnText="Cancel"
                btnStyle="bg-inactiveText"
                btnClick={() => showModal()}
              />

              <Button
                btnText="Delete"
                btnStyle="bg-red-500"
                btnClick={handleDelete}
              />
            </div>
          </div>
        </div>

        <div className={`${scoreFormOpen ? "modalfloat" : "hidden"} w-full`}>
          <div className="modal bg-mainWhite w-[80%] shadow-lg">
            <ScopeForm
              increaseMin={increaseMin}
              decreaseMin={decreaseMin}
              increaseMax={increaseMax}
              decreaseMax={decreaseMax}
              loading={loading}
              editRangeMode={editRangeMode}
              updateRange={updateRange}
              scoreState={scoreState}
              handleChange={handleChange}
              submitAddScore={submitAddScope}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Category;
