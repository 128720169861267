import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const updateBook = createAsyncThunk("update/create", async (data) => {
  const { id, formData } = data;
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      method: "POST",
      headers: {
        // "content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: formData,
    };

    const response = await fetch(`${BASE_URL}/admin/books/${id}`, config);

    //   success
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

const BookPostSlice = createSlice({
  name: "updateBook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBook.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(updateBook.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updateBook.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export default BookPostSlice.reducer;
