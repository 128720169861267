import React, { useState, useEffect, useRef } from "react";
import { PlusIcon, pryPlusIcon } from "../Assets";
import AddBasicQuest from "./AddBasicQuest";
import DefaultOption from "./DefaultOption";
import {
  deleteBasicQuest,
  fetchBasicQuest,
  postBasicQuest,
  postOptions,
  updateBasicQuest,
} from "../Features/BasicSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import QuestionModal from "./QuestionModal";
import { ToastContainer, toast } from "react-toastify";
import BasicDistress from "./BasicDistress";
import {
  createDistress,
  deleteDistress,
  getDistress,
  updateDistress,
} from "../Features/DistressSlice";

const BasicQuest = () => {
  const dispatch = useDispatch();
  const BasicData = useSelector((state) => state.basic.basic);
  const Status = useSelector((state) => state.basic.status);
  const categoriesArray = useSelector((state) => state.category.data);
  const [isBasic, setIsBasic] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isOption, setIsOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categories, setCategories] = useState([]);
  const [formState, setFormState] = useState({
    question: "",
    is_k10: "0",
    special_options: "0",
  });
  const [optionValues, setOptionValues] = useState([
    {
      option: "",
      value: "1",
    },
  ]);

  useEffect(() => {
    dispatch(fetchBasicQuest());
  }, [dispatch]);

  // // // // // // // Get distress call // // // // // // //
  useEffect(() => {
    dispatch(getDistress());
  }, [dispatch]);
  const distrassInfo = useSelector((state) => state?.distress);
  const distrassData = distrassInfo?.distress;

  // // // // // // // Create distress call // // // // // // //
  const showDisrest = () => {
    setDistressIsOpen(true);
    setIsBasic(!isBasic);
  };
  const editorRef = {
    welcome_message: useRef(null),
  };
  const [distressIsOpen, setDistressIsOpen] = useState(false);
  const [distressEditMode, setDistressEditMode] = useState(false);
  const [selectedDistress, setSelectedDistress] = useState();
  const [distressActionOpen, setDistressActionOpen] = useState(false);
  const [distressState, setDistressState] = useState({
    min: "0",
    max: "1",
    verdict: "",
    welcome_message: "",
  });

  const handledDistressChange = (e) => {
    const { id, value } = e.target;
    setDistressState((prevData) => ({ ...prevData, [id]: value }));
  };
  const submitDistress = (e) => {
    e.preventDefault();
    const payload = {
      // welcome_message: editorRef.welcome_message?.current.target.getContent(),
      ...distressState,
    };
    if (distressState.verdict === "") {
      alert("Verdict is required");
    } else if (distressState.min >= distressState.max) {
      alert("Min value must be less than max value");
    } else {
      setLoading(true);
      dispatch(createDistress(payload))
        .unwrap()
        .then(() => {
          showToast(`A new Distress score has been added successfully`);
          setLoading(false);
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // // Update distress call // // // // // // //
  const distressAction = (item) => {
    setDistressActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedDistress(item);
  };
  useEffect(() => {
    if (!distressEditMode) return;
    setDistressState(selectedDistress);
  }, [selectedDistress, distressEditMode]);
  const submitUpdateDistress = (e) => {
    e.preventDefault();
    const payload = {
      id: selectedDistress.id,
      min: distressState.min,
      max: distressState.max,
      verdict: distressState.verdict,
      welcome_message: distressState.welcome_message,
      // welcome_message: editorRef.welcome_message?.current.target.getContent(),
    };
    if (payload.verdict === "") {
      alert("Verdict is required");
    } else if (payload.min >= payload.max) {
      alert("Min value must be less than max value");
    } else {
      setLoading(true);
      dispatch(updateDistress(payload))
        .unwrap()
        .then(() => {
          showToast(`Distress score updated successfully`);
          setLoading(false);
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          alert("Error updating post:", error);
        });
    }
  };

  // // // // // // // delete distress call // // // // // // //
  const handleDeleteDistress = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteDistress({ id: selectedDistress.id }))
      .unwrap()
      .then(() => {
        showToast(`Distress deleted successfully`);
        setLoading(false);
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        alert("Error deleting post:", error);
      });
  };

  // // // // // // // Show Form // // // // // // //
  const showBasicForm = () => {
    setIsBasic(!isBasic);
    setDistressIsOpen(false);
  };

  // // // // // // ADD QUESTION // // // // // //
  const increase = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value < 5) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) + 1,
        };
      }
      return newValues;
    });
  };
  const decrease = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value > 1) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) - 1,
        };
      }
      return newValues;
    });
  };
  const addOptionField = () => {
    setOptionValues((prev) => [...prev, { option: "", value: "1" }]);
  };
  const removeOptionField = () => {
    setOptionValues((prev) => {
      const updatedNum = [...prev];
      updatedNum.pop();
      return updatedNum;
    });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const optionChange = (e, index) => {
    const { name, value } = e.target;
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [name]: value,
      };
      return newValues;
    });
  };
  const validate = (parrams) => {
    return parrams.some((option) => option.option === "");
  };
  const createBasicQuest = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      // options: optionValues, to be added if special options is 1
    };

    if (payLoad.special_options == "1") {
      payLoad["options"] = optionValues;
    }

    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length === 0) {
      alert("Category is required");
    } else if (payLoad.special_options == "1" && validate(payLoad.options)) {
      alert("Option fields are required");
    } else {
      setLoading(true);
      dispatch(postBasicQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`A new Question has been added successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // // Show Selected Item modal // // // // // // //
  const pickQuestion = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  // // // // // // // Edit Selected Item // // // // // // //
  const showFormEdit = () => {
    setEditMode(!editMode);
    setIsBasic(!isBasic);
  };
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    setOptionValues(selectedItem.options);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);
  const updateQuestion = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedItem.id,
      categories: [...new Set(categories)],
      question: formState.question,
      special_options: formState.special_options,
      is_k10: formState.is_k10,
    };

    if (payLoad.special_options == "1") {
      payLoad["options"] = optionValues;
    }

    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length === 0) {
      alert("Category is required");
    } else if (payLoad.special_options == "1" && validate(payLoad.options)) {
      alert("all option fields are required");
    } else {
      setLoading(true);
      dispatch(updateBasicQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Question updated successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // // Delete Selected Item // // // // // // //
  const deleteQuestion = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteBasicQuest({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Question Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // // Adding Default Option // // // // // // //
  const showDefaultOptionForm = (e) => {
    e.preventDefault();
    setIsOption(!isOption);
  };
  const submitAddOption = (e) => {
    e.preventDefault();
    const options = optionValues;
    if (validate(options)) {
      alert("all option fields are required");
    } else {
      setLoading(true);
      dispatch(postOptions(options))
        .unwrap()
        .then(() => {
          showToast(`Options added successfully`);
          setLoading(false);
        })
        // .then(() =>
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 2100)
        // )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // toast
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <ToastContainer />
      {isOption ? (
        <DefaultOption
          optionValues={optionValues}
          optionChange={optionChange}
          decrease={decrease}
          increase={increase}
          addOptionField={addOptionField}
          removeOptionField={removeOptionField}
          handleSubmit={submitAddOption}
          loading={loading}
        />
      ) : !isBasic ? (
        <div className="relative">
          <div className="flex items-center justify-end w-full gap-5">
            <button
              className="text-primary bg-white text-base py-[4px] px-[15px] rounded-xl border-primary border-2"
              onClick={showDisrest}
            >
              Distress score range
            </button>

            <button
              className="text-primary bg-white text-base flex items-center gap-2 py-[4px] px-[15px] rounded-xl border-primary border-2"
              onClick={showDefaultOptionForm}
            >
              <img src={pryPlusIcon} alt="plus" />
              General option
            </button>

            <button
              className="bg-primary text-white text-base flex items-center gap-2 py-[6px] px-[15px] rounded-xl"
              onClick={showBasicForm}
            >
              <img src={PlusIcon} alt="plus" />
              Add question
            </button>
          </div>

          <div className="w-full py-2 mt-6 bg-white rounded-lg shadow-custom">
            <div className="w-[73%] ml-3 mt-2 mb-4 flex items-center justify-between">
              <h3 className="text-lg font-medium capitalize text-primary">
                Basic question, K10
              </h3>
            </div>
            <table className="table w-full mx-auto divide-y divide-gray-200 ">
              <thead className="bg-[#ECECEC]">
                <tr>
                  <th className="text-primary text-[15px] font-semibold px-2 ">
                    S/N
                  </th>
                  <th className="text-primary text-[15px] font-semibold px-2 ">
                    Question
                  </th>
                  <th className="text-primary text-[15px] font-semibold px-2 ">
                    Category
                  </th>
                  <th className="text-primary text-[15px] font-semibold px-2 ">
                    Create at
                  </th>
                  <th className="text-primary text-[15px] font-semibold px-2 ">
                    Last Updated
                  </th>
                </tr>
              </thead>
              {Status === StatusCode.LOADING ? (
                <div className="fixed w-full top-[70%] left-[5%] ">
                  <Shimmer height={38} width={38} color={"#207384"} />
                </div>
              ) : (
                <tbody>
                  {BasicData.map((data) => (
                    <tr
                      className="text-center border-b hover:bg-[#E9F3F3]"
                      key={data.id}
                      onClick={() => pickQuestion(data)}
                    >
                      <td className="py-2 px-5  text-[13px] font-semibold border-r border-gray-400">
                        {data.id}
                      </td>
                      <td className="py-2 px-5  text-[13px] font-semibold capitalize border-r border-gray-400">
                        {data.question}
                      </td>
                      <td className="py-2 px-5  text-[13px] font-semibold border-r border-gray-400">
                        {data.categories}
                      </td>
                      <td className="py-2 px-5  text-[13px] font-semibold border-r border-gray-400">
                        {moment(data.created_at).startOf("ss").fromNow()}
                      </td>
                      <td className="flex items-center justify-center gap-3 py-2 px-5 text-[13px] font-semibold">
                        {moment(data.updated_at).startOf("ss").fromNow()}
                        {/* <img src={Dot} alt="dot-icon" /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
            <QuestionModal
              selectedItem={selectedItem}
              loading={loading}
              updateItem={showFormEdit}
              closeModal={closeModal}
              deleteItem={deleteQuestion}
              prerequisite={true}
              handleMakePrerequisite={""}
            />
          </div>
        </div>
      ) : (
        <>
          {distressIsOpen ? (
            <div>
              <BasicDistress
                loading={loading}
                editorRef={editorRef}
                distrassData={distrassData}
                distrassInfo={distrassInfo}
                distressState={distressState}
                handleSubmit={submitDistress}
                handleUpdate={submitUpdateDistress}
                distressEditMode={distressEditMode}
                handledDistressChange={handledDistressChange}
                distressAction={distressAction}
                distressActionOpen={distressActionOpen}
                handleDistresDelete={handleDeleteDistress}
                readyDistresupdate={() => setDistressEditMode(true)}
              />
            </div>
          ) : (
            <div>
              <AddBasicQuest
                optionValues={optionValues}
                handleCreate={createBasicQuest}
                editMode={editMode}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                increase={increase}
                decrease={decrease}
                optionChange={optionChange}
                categories={categories}
                loading={loading}
                formState={formState}
                handleUpdate={updateQuestion}
                addOptionField={addOptionField}
                removeOptionField={removeOptionField}
                // optionslength={selectedItem?.options?.length}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BasicQuest;
