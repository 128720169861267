import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { PlusIcon } from "../Assets";
import AddDailyQuest from "./AddDailyQuest";
import { useDispatch, useSelector } from "react-redux";
import { fetchDailyQuest, fetchTotalCheckin } from "../Features/DailySlice";
import { Shimmer } from "../Components";
import StatusCode from "../Ultilities/StatusCode";
import QuestionModal from "./QuestionModal";
import {
  DeleteDailyQuest,
  postDailyQuest,
  updateDailyQuest,
} from "../Features/DailyPostSlice";
import { ToastContainer, toast } from "react-toastify";

const DailyQuest = () => {
  const dispatch = useDispatch();
  const QuestData = useSelector((state) => state.daily.quest);
  const Status = useSelector((state) => state.daily.status);
  const checkIn = useSelector((state) => state.daily.checkIn);
  const categoriesArray = useSelector((state) => state.category.data);
  const [loading, setLoading] = useState();
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formState, setFormState] = useState({
    question: "",
  });
  const [optionValues, setOptionValues] = useState(
    Array.from({ length: 5 }).map(() => ({
      option: "",
      value: "1",
    }))
  );

  const totalCheckin = checkIn?.total_checkins;
  const showFormHandler = () => {
    setShowForm(!showForm);
  };
  const showFormEdit = () => {
    setEditMode(!editMode);
    setShowForm(!showForm);
  };
  useEffect(() => {
    dispatch(fetchTotalCheckin());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDailyQuest());
  }, [dispatch]);

  // // // // // // ADD QUESTION // // // // // //
  const increase = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value < 5) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) + 1,
        };
      }
      return newValues;
    });
  };
  const decrease = (e, index) => {
    e.preventDefault();
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[index].value > 1) {
        newValues[index] = {
          ...newValues[index],
          value: Number(newValues[index].value) - 1,
        };
      }
      return newValues;
    });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const optionChange = (e, index) => {
    const { name, value } = e.target;
    setOptionValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [name]: value,
      };
      return newValues;
    });
  };
  const validate = (parrams) => {
    return parrams.some((option) => option.option === "");
  };
  const addQuestion = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      options: optionValues,
    };
    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length === 0) {
      alert("Category is required");
    } else if (validate(payLoad.options)) {
      alert("all option fields are required");
    } else {
      setLoading(true);
      dispatch(postDailyQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`A new Question has been added successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  //  SELECTE ONE QUESTION.
  const pickQuestion = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  const closeModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  // // // // // // Delete Question // // // // // //
  const deleteQuestion = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(DeleteDailyQuest({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Question Deleted successfully.");
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // // // // // // Edit Question // // // // // //
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    setOptionValues(selectedItem.options);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const updateQuestion = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedItem.id,
      question: formState.question,
      categories: categories,
      options: optionValues,
    };
    if (payLoad.question === "") {
      alert("Question is required");
    } else if (payLoad.categories.length === 0) {
      alert("Category is required");
    } else if (validate(payLoad.options)) {
      alert("all option fields are required");
    } else {
      setLoading(true);
      dispatch(updateDailyQuest(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Question updated successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <>
      {showForm ? (
        <div>
          <AddDailyQuest
            onCancle={() => window.location.reload()}
            handleChange={handleChange}
            handleSubmit={addQuestion}
            formState={formState}
            handleCheckboxChange={handleCheckboxChange}
            optionValues={optionValues}
            optionChange={optionChange}
            increase={increase}
            decrease={decrease}
            loading={loading}
            categories={categories}
            editMode={editMode}
            handleUpdate={updateQuestion}
          />
        </div>
      ) : (
        <div className="relative">
          <div className="relative flex items-start justify-between w-full">
            <div className="flex items-center px-4 py-4 bg-white rounded-lg gap-9">
              <h4 className="text-lg font-medium">Total check in</h4>
              <h3 className="text-3xl font-semibold">
                <CountUp
                  separator=","
                  start={0}
                  end={totalCheckin}
                  duration={2.5}
                />
              </h3>
            </div>
            <button
              className="bg-primary text-white text-base flex items-center gap-2 py-[6px] px-[15px] rounded-xl"
              onClick={showFormHandler}
            >
              <img src={PlusIcon} alt="plus" />
              Add question
            </button>
          </div>

          <div className="w-full py-2 mt-6 bg-white rounded-lg shadow-custom">
            <div className="w-[93%] mx-auto mt-2 mb-4">
              <h3 className="text-lg font-medium capitalize text-primary">
                Daily questions
              </h3>
            </div>
            <table className="table w-full mx-auto divide-y divide-gray-200  ">
              <thead className="bg-[#ECECEC]">
                <tr>
                  <th className="text-primary ">S/N</th>
                  <th className="text-primary ">Question</th>
                  <th className="text-primary ">Category</th>
                  <th className="text-primary ">Create at</th>
                  <th className="text-primary ">Last Updated</th>
                </tr>
              </thead>
              {Status === StatusCode.LOADING ? (
                <div className="fixed w-full top-[70%] left-[5%] ">
                  <Shimmer height={38} width={38} color={"#207384"} />
                </div>
              ) : (
                <tbody>
                  {QuestData?.map((data, index) => (
                    <tr
                      className="text-center border-b hover:bg-[#E9F3F3]"
                      key={data.id}
                      onClick={() => pickQuestion(data)}
                    >
                      <td className="py-2 px-4 font-semibold border-r border-gray-400">
                        {index + 1}
                      </td>
                      <td className="py-2 px-4 font-semibold text-sm capitalize border-r border-gray-400">
                        {data.question}
                      </td>
                      <td className="py-2 px-4 font-semibold text-sm border-r border-gray-400">
                        {data.categories}
                      </td>
                      <td className="py-2 px-4 text-sm font-semibold border-r border-gray-400">
                        {new Date(data.created_at).toDateString()}
                        {/* {moment(data.created_at).startOf("ss").fromNow()} */}
                      </td>
                      <td className=" py-2 px-4 text-sm font-semibold">
                        {/* {moment(data.updated_at).startOf("ss").fromNow()} */}
                        {new Date(data.updated_at).toDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
            <QuestionModal
              selectedItem={selectedItem}
              loading={loading}
              updateItem={showFormEdit}
              closeModal={closeModal}
              deleteItem={deleteQuestion}
            />
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default DailyQuest;
