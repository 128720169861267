import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  distress: {},
  error: null,
};

export const getDistress = createAsyncThunk("get", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/basic-question-score-ranges`,
    config
  );
  const final = await response.data.data;
  return final;
});

const distressSlice = createSlice({
  name: "distress",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDistress.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getDistress.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.distress = payload;
      });
  },
});

export default distressSlice.reducer;

// Create distress
export const createDistress = createAsyncThunk("post", async (data) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASE_URL}/admin/basic-question-score-ranges`,
    config
  );
  return response;
});

// Update distress
export const updateDistress = createAsyncThunk("put", async (data) => {
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASE_URL}/admin/basic-question-score-ranges/${data.id}`,
    config
  );
  return response;
});

// Delete distress
export const deleteDistress = createAsyncThunk("delete", async ({ id }) => {
  const config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await fetch(
    `${BASE_URL}/admin/basic-question-score-ranges/${id}`,
    config
  );
  return response;
});
