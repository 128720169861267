import React from "react";

const ThStyle = `py-2 px-3 text-primary`;

const TableMain = ({ resourceType, TableRows, subLevel }) => {
  return (
    <div className="w-full overflow-x-scroll py-3 px-5">
      <table className="w-full table-auto">
        <thead className="text-center bg-[#F6F7FA]">
          <tr>
            <th className={ThStyle}>S/N</th>
            <th className={ThStyle}>{resourceType}</th>
            <th className={ThStyle}>Categories</th>
            <th className={ThStyle}>Last Updated</th>
            <th className={ThStyle}>{subLevel}</th>
          </tr>
        </thead>

        <tbody>{TableRows}</tbody>
      </table>
    </div>
  );
};

export default TableMain;
