import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";

export const updateAudio = createAsyncThunk("update/create", async (data) => {
  const { id, formData } = data;
  try {
    const userToken = localStorage.getItem("userToken");
    const config = {
      method: "POST",
      headers: {
        // "content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: formData,
    };
    const response = await fetch(`${BASE_URL}/admin/audios/${id}`, config);

    //   success
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});

const AudioPostSlice = createSlice({
  name: "updateAudio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAudio.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(updateAudio.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updateAudio.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});

export default AudioPostSlice.reducer;
