import Modal from "./Modal";
import Button from "./Button";
import TableRow from "./TableRow";
import BoardData from "./BoardData";
import TableMain from "./TableMain";
import ResourceList from "./ResourceList";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import ResourceListHead from "./ResourceListHead";
import { toast, ToastContainer } from "react-toastify";
import { getArticles } from "../Features/ArticleSlice";
import { EyeIcon, PlusIcon, ArticleIcon } from "../Assets";
import React, { useEffect, useState, useRef } from "react";
import { articleSummary } from "../Features/ArticleSummary";
import { updateArticle } from "../Features/ArticlePostSlice";
import { AddNewArticle } from "../Features/CreateArticleSlice";
import { deleteArticle } from "../Features/ArticleDeleteSlice";
import ReactPagination from "./ReactPagination";
import Shimmer from "./Shimmer";
import StatusCode from "../Ultilities/StatusCode";
import InputCat from "./InputCat";
import { fetchCategory } from "../Features/CategorySlice";
import { fetchSubPlan } from "../Features/SubPlanSlice";

const Article = () => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updateForm, setUpdateForm] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [formState, setFormState] = useState({
    // id: "",
    title: "",
    author: "",
    summary: "",
    publication_date: "",
    content: "",
    duration: "",
    subscription_level: 0,
  });

  const openAddForm = () => {
    setAddForm(true);
    setModalIsOpen(false);
    setUpdateForm(false);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    setAddForm(false);
    window.location.reload();
  };

  const closeResourceInfo = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    dispatch(getArticles(1));
  }, [dispatch]);

  const resourceInfo = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };

  const articleData = useSelector((state) => state.article.articlesData);
  const categoriesArray = useSelector((state) => state.category.data);
  const AllArticleList = articleData?.data?.map((article, index) => (
    <TableRow
      key={article?.id}
      {...article}
      index={lastSerialNumber + index + 1}
      tableRowclick={() => resourceInfo(article)}
    />
  ));
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setAddForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };

  const articleUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const articleContent = editorRef.current.target.getContent();
    formData.set("content", articleContent);

    const updateData = {
      id: selectedItem.id,
      title: formData.get("title"),
      author: formData.get("author"),
      summary: formData.get("summary"),
      publication_date: formData.get("publication_date"),
      photo: formData.get("photo"),
      subscription_level: formData.get("subscription_level"),
      content: articleContent,
    };

    const payLoad = { ...updateData, categories };
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(updateArticle({ id: selectedItem.id, formData }))
      .unwrap()
      .then(() => {
        showToast("Update was successfull");
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2200);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  };

  // delete
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteArticle({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          setModalIsOpen(!modalIsOpen);
          showToast("Article Deleted successfully.");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Create New Article
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  // Add new article {onSubmit}
  const createNewArticle = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      categories,
      content: editorRef.current.target.getContent(),
    };
    const formData = new FormData();
    formData.append("photo", selectedFile);

    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields before submitting.");
      return;
    } else if (!selectedFile) {
      return alert("please selecte an image file");
    }

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(AddNewArticle(formData))
      .unwrap()
      .then(() => {
        showToast(
          `A new Article titiled ${formState.title} has been added successfully, reload to get updated data`
        );
      })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2100);
      })
      .catch((error) => {
        alert("Error creating post:", error);
      });
  };

  // summary
  const articleSummaryData = useSelector(
    (state) => state.articleSummary.articleSummary
  );
  useEffect(() => {
    dispatch(articleSummary());
  }, [dispatch]);
  const popularArticles = articleSummaryData?.popular_articles;
  const articleContent = selectedItem?.content;

  // Toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <>
      <div className={`addForm ${addForm ? "" : "hidden"}`}>
        <AddResourceForm
          createArticle={createNewArticle}
          cancelAction={closeAddForm}
          formState={formState}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editMode={editMode}
          selectedItem={selectedItem}
          categories={categories}
          articleUpdate={articleUpdate}
          editorRef={editorRef}
          loading={loading}
        />
      </div>

      <div
        className={`relative flex flex-col gap-4 ${
          updateForm ? "hidden" : ""
        } ${addForm ? "hidden" : ""}`}
      >
        <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
          <div className={`h-full w-[60%]`}>
            {selectedItem && (
              <Modal
                itemMedia={selectedItem.photo.url}
                ifContentStyle={`${
                  articleContent ? "h-[97%] overflow-y-scroll" : ""
                }`}
                content={articleContent}
                item={selectedItem}
                cancelAction={closeResourceInfo}
                updateAction={toggleEditMode}
                deleteitem={handleDelete}
                loading={loading}
              />
            )}
          </div>
        </div>

        <div className="flex gap-5">
          <BoardData
            resourceIcon={ArticleIcon}
            resourcesIconAlt="ArticleIcon"
            resourceTotal={articleSummaryData.total_articles}
            resourceName="Total Articles"
            textStyle="text-[#0B1CE2]"
          />

          <BoardData
            resourceIcon={EyeIcon}
            resourcesIconAlt="eye-Icon"
            resourceTotal={articleSummaryData.total_views}
            resourceName="Total Views"
            textStyle="text-[#0F2851]"
          />

          <div className=" ml-auto">
            <Button
              btnText="Add new article"
              btnIcon={PlusIcon}
              btnStyle="bg-primary"
              btnClick={openAddForm}
            />
          </div>
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-xl">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular articles</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularArticles?.map((article) => (
              <div key={article.id}>
                <ResourceList {...article} photo={article.photo.url} />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllArticles mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead resourceListType="Articles" />
          <TableMain
            resourceType="Articles"
            subLevel="Sub Level"
            TableRows={AllArticleList}
          />

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={articleData?.last_page}
              onChanges={(i) => {
                dispatch(getArticles(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    AllArticleList.length * articleData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Article;

const AddResourceForm = ({
  createArticle,
  cancelAction,
  formState,
  handleChange,
  categories,
  handleFileChange,
  handleCheckboxChange,
  editMode,
  selectedItem,
  articleUpdate,
  editorRef,
  loading,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);

  const plugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "Link Checker",
    "Page Embed",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "preview",
    "help",
    "wordcount",
    "fontselect",
    "fontsize",
  ];
  const toolbar =
    "undo redo | formatselect | fontselect | fontsizeselect | " +
    "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
    "bullist numlist outdent indent | removeformat | link image | table media | " +
    "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
    "preview help wordcount";

  const content_style =
    "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

  return (
    <div className="update-modal bg-mainWhite w-full rounded-lg shadow-lg">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Edit Article" : "Add Article"}
        </h1>
      </div>

      <form
        action=""
        className="p-5"
        onSubmit={editMode ? articleUpdate : createArticle}
      >
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              <span>Article</span> title
            </label>
            <input
              value={formState.title}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="author" className="text-sm font-bold">
              <span>Article</span> author
            </label>
            <input
              value={formState.author}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="author"
              id="author"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter author</span>
          </div>

          <div>
            <label htmlFor="summary" className="text-sm font-bold">
              Description
            </label>
            <textarea
              value={formState.summary}
              onChange={(e) => {
                handleChange(e);
              }}
              name="summary"
              id="summary"
              cols="30"
              rows="4"
              placeholder="Type or Paste article summary"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            ></textarea>
            <span className="text-inactiveText text-xs">
              Description <span>Article</span>
            </span>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <label htmlFor="publication_date" className="text-sm font-bold">
                Publication date
              </label>
              <input
                value={formState.publication_date}
                onChange={(e) => {
                  handleChange(e);
                }}
                type="date"
                name="publication_date"
                id="publication_date"
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>

            <div>
              <label htmlFor="photo" className="text-sm font-bold">
                Add image
                <span className="text-red-500">
                  (Image must be less than 500 killobyte)
                </span>
              </label>
              <p className={`font-bold ${editMode ? "" : "hidden"}`}>
                Current image{" "}
                <span className="text-primary">
                  <a
                    href={selectedItem?.photo?.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {selectedItem?.photo?.filename}
                  </a>
                </span>
              </p>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div>
            <h1 className="text-lg font-bold">Category</h1>
            <span className="text-inactiveText text-xs">
              Check associated categories
            </span>

            <div className="flex items-center justify-between mb-5">
              <div className="w-[50%]">
                {catStatus === StatusCode.LOADING ? (
                  <div>
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <div className="">
                    {Data.map((category) => (
                      <InputCat
                        key={category.id}
                        {...category}
                        checked={categories?.includes(category.id)}
                        onChange={() => handleCheckboxChange(category.id)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="flex items-center gap-10 w-[50%]">
                <div className="w[15%]">
                  <label htmlFor="subscription_level">Subscription level</label>
                  <select
                    value={formState.subscription_level}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="subscription_level"
                    id="subscription_level"
                    className=" border-2 py-2 w-full rounded-lg"
                  >
                    <option value="0">Basic</option>
                    {SubPlanData?.map((subPlan) => (
                      <option value={subPlan?.level}>{subPlan?.package}</option>
                    ))}
                  </select>
                </div>

                <div className="w[15%]">
                  <label htmlFor="price">duration</label>
                  <div className="px-2 py-1 w-full rounded-lg border-2 flex items-center bg-tranparent">
                    <input
                      value={formState.duration}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="number"
                      name="duration"
                      id="duration"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="content" className="text-sm font-bold">
                Article Content
              </label>
              <Editor
                tinymceScriptSrc={
                  "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                  "/tinymce/tinymce.min.js"
                }
                onInit={(editor) => {
                  editorRef.current = editor;
                }}
                initialValue={editMode ? formState?.content : ""}
                init={{
                  height: 500,
                  menubar: false,
                  plugins,
                  toolbar,
                  content_style,
                  setup: function (editor) {
                    editor.setContent("place your content here");
                  },
                }}
                id="content"
              />
            </div>

            <div className="btns flex items-center gap-10">
              <Button
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
                btntype={"submit"}
                btnText={
                  loading ? (
                    <Shimmer height={20} color={"#fff"} />
                  ) : editMode ? (
                    "Update Article"
                  ) : (
                    "Add Article"
                  )
                }
              />

              <Button
                btnClick={cancelAction}
                btnStyle="bg-inactiveText"
                btnText="Cancel"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
