import React from "react";
import { TagIcon } from "../Assets";
import Button from "./Button";
import Shimmer from "./Shimmer";

const Modal = ({
  loading,
  item,
  updateAction,
  cancelAction,
  deleteitem,
  content,
  ifContentStyle,
  itemMedia,
  body,
}) => {
  return (
    <div
      className={`modal bg-mainWhite w-full flex flex-col items-center px-5 py-12 rounded-lg shadow-lg  ${ifContentStyle} `}
    >
      <div className="w-[50%] flex items-center justify-center">
        <img src={itemMedia} alt="" className="w-full" />
      </div>

      <h1 className="font-bold text-lg text-center">{item?.title}</h1>

      <div className="text-inactiveText text-sm flex items-start gap-2">
        <p>{item?.author}</p>

        {item?.publication_date ? <li>{item?.publication_date}</li> : ""}

        {item?.categories ? (
          <p className="flex">
            <span className=" w-[20px] flex items-center justify-center ml-2">
              <img src={TagIcon} alt="tag-Icon" className="" />
            </span>

            <span>{item?.categories.join(", ")}</span>
          </p>
        ) : (
          ""
        )}
      </div>

      {item.category ? (
        <div className="flex items-center justify-center flex-col gap-5">
          <p>
            Category:{" "}
            <span className="text-xl font-bold text-primary">
              {item.category}
            </span>
          </p>
          <p>
            Describtion:{" "}
            <span className="text-xl font-bold text-primary">
              {item.description}
            </span>
          </p>
        </div>
      ) : (
        ""
      )}

      <div className="text-center w-[85%] mx-auto my-5 text-xs">
        {item.summary ? (
          <p className="description text-center">{item?.summary}</p>
        ) : (
          ""
        )}

        {item?.publication_date && item?.subscription_level ? (
          <div className="text-inactiveTest flex items-start justify-center gap-5 mt-2">
            <p>{item?.publication_date}</p>

            <p>{item?.subscription_level}</p>
          </div>
        ) : (
          ""
        )}

        {content ? (
          <p
            className="content text-start mt-5"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          ""
        )}

        {body ? (
          <p
            className="content text-start mt-5"
            dangerouslySetInnerHTML={{ __html: body }}
          />
        ) : (
          ""
        )}
      </div>

      <div className="flex items-center gap-5">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateAction} />
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteitem}
        />
      </div>
    </div>
  );
};

export default Modal;
