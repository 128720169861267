import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  blogSummary: {},
  error: null,
};

export const blogSummary = createAsyncThunk("get/summary", async () => {
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/blog-summary`, config);
  return response.data;
});

const blogSummarySlice = createSlice({
  name: "blogSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(blogSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(blogSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.blogSummary = payload;
      });
  },
});

export default blogSummarySlice.reducer;
