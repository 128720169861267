import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  podcastSummary: {},
  error: null,
};

// Handle Podcast summary
export const podcastSummary = createAsyncThunk("get/getsummary", async () => {
  const userToken = localStorage.getItem("userToken");

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const response = await axios.get(`${BASE_URL}/admin/podcast-summary`, config);
  const final = response.data.data;
  return final;
});

const podcastSummarySlice = createSlice({
  name: "podcastSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(podcastSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(podcastSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.podcastSummary = payload;
      });
  },
});

export default podcastSummarySlice.reducer;
