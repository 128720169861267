import React from "react";
const TdStyle = `border-b border-inactiveText bg-trasnparent p-2 text-center text-sm font-medium text-[#2E2F32]`;

const TableRow = ({
  index,
  title,
  categories,
  publication_date,
  subscription_level,
  tableRowclick,
}) => {
  return (
    <tr className="cursor-pointer hover:bg-[#E9F3F3]" onClick={tableRowclick}>
      <td className={`${TdStyle} border-r`}>{index}</td>
      <td className={`${TdStyle} border-r`}>{title}</td>
      <td className={`${TdStyle} border-r`}>{categories?.join(", ")}</td>
      <td className={`${TdStyle} border-r`}>{publication_date}</td>
      <td className={TdStyle}>{subscription_level}</td>
    </tr>
  );
};

export default TableRow;
