import React from "react";
import ReactPaginate from "react-paginate";
const ReactPagination = ({ total, onChanges, forcePage }) => {
  return (
    <div>
      <ReactPaginate
        pageCount={total}
        nextLabel=">"
        previousLabel="<"
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        onPageChange={onChanges}
        pageClassName="flex items-center text-black cursor-pointer text-[14px] font-semibold bg-white border-[1px] px-2 "
        activeClassName="active"
        className="flex items-center gap-2"
        previousClassName=" border-[1px] px-2 bg-gray-200 text-xl font-semibold rounded-lg"
        nextClassName=" border-[1px] px-2 bg-gray-200 text-xl font-semibold rounded-lg"
        forcePage={forcePage}
      />
    </div>
  );
};

export default ReactPagination;
