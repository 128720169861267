import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  audioSummary: {},
  error: null,
};

// Handle Podcast summary
export const audioSummary = createAsyncThunk("get/getsummary", async () => {
  const userToken = localStorage.getItem("userToken");

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const response = await axios.get(`${BASE_URL}/admin/audio-summary`, config);
  const final = response.data.data;
  return final;
});

const audioSummarySlice = createSlice({
  name: "audioSummary",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(audioSummary.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(audioSummary.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.audioSummary = payload;
      });
  },
});

export default audioSummarySlice.reducer;
