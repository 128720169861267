import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartData } from "./DummyData";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
        text: "",
      },
    },
  };

  const data = {
    labels: ChartData.map((data) => data.year),
    datasets: [
      {
        fill: true,
        label: "Dataset 2",
        data: ChartData.map((data) => data.userLost),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Line data={data} options={options} height={35} width={100} />
    </div>
  );
};

export default AreaChart;
