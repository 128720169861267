import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  notificationSetting: {},
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const fetchNotificationSetting = createAsyncThunk(
  "get/admin",
  async () => {
    try {
      const config = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken,
          Accept: "application/json",
          Connection: "keep-alive",
        },
      };
      const response = await fetch(
        `${BASE_URL}/admin/notification-settings`,
        config
      );
      const final = await response.json();
      return final.data;
    } catch (error) {
      throw error;
    }
  }
);

const notificationSettingSlice = createSlice({
  name: "notificationSetting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationSetting.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchNotificationSetting.fulfilled, (state, { payload }) => {
        state.notification = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchNotificationSetting.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = StatusCode.ERROR;
      });
  },
});

export default notificationSettingSlice.reducer;

export const updateNotificationSettings = createAsyncThunk(
  "put/admin",
  async (data) => {
    try {
      const config = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/notification-settings`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
