import React, { useState, useEffect, useRef } from "react";
import { BookIcon, EyeIcon, PlusIcon } from "../Assets";
import BoardData from "./BoardData";
import Button from "./Button";
import ResourceList from "./ResourceList";
import TableMain from "./TableMain";
import TableRow from "./TableRow";
import ResourceListHead from "./ResourceListHead";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { getBooks } from "../Features/BookSlice";
import { updateBook } from "../Features/BookPostSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddNewBook } from "../Features/CreateBookSlice";
import { bookSummary } from "../Features/BookSummary";
import { deleteBook } from "../Features/BookDeleteSlice";
import ReactPagination from "./ReactPagination";
import InputCat from "./InputCat";
import { fetchSubPlan } from "../Features/SubPlanSlice";
import { fetchCategory } from "../Features/CategorySlice";
import Shimmer from "./Shimmer";
import StatusCode from "../Ultilities/StatusCode";
import { Editor } from "@tinymce/tinymce-react";

const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const Book = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [bookForm, setBookForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formState, setFormState] = useState({
    // id: "",
    title: "",
    author: "",
    price: "",
    publication_year: "",
    subscription_level: 1,
    purchase_link: "",
  });
  const editorRefs = {
    summary: useRef(null),
  };
  const closeResourceInfo = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const resourceInfo = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };
  // Fetch books
  const bookData = useSelector((state) => state.book.booksData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBooks());
  }, [dispatch]);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const AllBookList = bookData?.data?.map((book, index) => (
    <TableRow
      key={book.id}
      {...book}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(book.updated_at).toDateString()}
      tableRowclick={() => resourceInfo(book)}
    />
  ));

  // Update book
  const categoriesArray = useSelector((state) => state.category.data);
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setBookForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updateBookResource = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const updateData = {
      id: selectedItem.id,
      title: formData.get("title"),
      author: formData.get("author"),
      summary: editorRefs.summary?.current.target.getContent(),
      price: formData.get("price"),
      publication_year: formData.get("publication_year"),
      purchase_link: formData.get("purchase_link"),
      photo: !selectedFile && selectedItem.photo,
      subscription_level: formData.get("subscription_level"),
    };

    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("photo", selectedFile);

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (selectedItem.id !== null) {
      const id = selectedItem.id;
      const data = { id, formData };
      setLoading(true);
      dispatch(updateBook(data))
        .unwrap()
        .then(() => {
          showToast(`Book Updated Successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post", error);
        });
    }
  };

  // Add Books
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const openAddForm = (e) => {
    e.preventDefault();
    setBookForm(true);
  };
  const closeAddForm = (e) => {
    e.preventDefault();
    setBookForm(!bookForm);
    window.location.reload();
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const validateForm = (formData) => {
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  const createNewBook = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      summary: editorRefs.summary?.current.target.getContent(),
      categories,
    };
    const formData = new FormData();
    formData.append("book_cover", selectedFile);
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields before submitting.");
      return;
    } else if (!selectedFile) {
      return alert("please selecte an image file");
    }

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoading(true);
    dispatch(AddNewBook(formData))
      .unwrap()
      .then(() => {
        showToast(
          `A new Book titiled ${formState.title} has been added successfully, reload to get updated data`
        );
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  };

  // delete  book
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deleteBook({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          showToast("Book Deleted successfully, reload to update data list");
          setLoading(false);
        })
        .then(() => window.location.reload())
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  //  Get summary
  const bookSummaryData = useSelector(
    (state) => state.bookSummary?.bookSummary
  );
  useEffect(() => {
    dispatch(bookSummary());
  }, [dispatch]);
  const popularBook = bookSummaryData?.popular_books;

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  useEffect(() => {
    if (editMode && categories === undefined) {
      return null;
    }
  }, [editMode, categories]);

  return (
    <>
      <div className={`${bookForm ? "" : "hidden"}`}>
        <BookForm
          cancelAction={closeAddForm}
          createBook={createNewBook}
          editBook={updateBookResource}
          formState={formState}
          handleChange={handleChange}
          setCategories={setCategories}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editMode={editMode}
          selectedItem={selectedItem}
          categories={categories}
          loading={loading}
          editorRefs={editorRefs}
        />
      </div>

      <div
        className={`relative flex flex-col gap-4 ${bookForm ? "hidden" : ""}`}
      >
        <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
          <div className={`w-[60%] `}>
            {selectedItem && (
              <Modal
                itemMedia={selectedItem?.photo?.url}
                item={selectedItem}
                cancelAction={closeResourceInfo}
                updateAction={toggleEditMode}
                deleteitem={handleDelete}
                loading={loading}
              />
            )}
          </div>
        </div>
        <div className="flex gap-5">
          <BoardData
            resourceIcon={BookIcon}
            resourcesIconAlt="book-Icon"
            resourceTotal={bookSummaryData?.total_books}
            resourceName="Total Books"
            textStyle="text-[#7890A5]"
          />

          <BoardData
            resourceIcon={EyeIcon}
            resourcesIconAlt="eye-Icon"
            resourceTotal={bookSummaryData?.total_views}
            resourceName="Total Views"
            textStyle="text-[#0F2851]"
          />

          <div className="ml-auto">
            <Button
              btnText="Add new book"
              btnIcon={PlusIcon}
              btnStyle="bg-primary"
              btnClick={openAddForm}
            />
          </div>
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular books</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularBook?.map((book) => (
              <div key={book.id}>
                <ResourceList
                  {...book}
                  photo={book?.photo?.url}
                  publication_date={new Date(book.updated_at).toDateString()}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead resourceListType="Books" />
          <TableMain resourceType="Books" TableRows={AllBookList} />

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={bookData?.last_page}
              onChanges={(i) => {
                dispatch(getBooks(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    AllBookList.length * bookData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Book;

const BookForm = ({
  createBook,
  editBook,
  cancelAction,
  formState,
  handleChange,
  categories,
  handleFileChange,
  handleCheckboxChange,
  editMode,
  selectedItem,
  loading,
  editorRefs,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);

  return (
    <div className="update-modal bg-mainWhite w-full rounded-lg shadow-lg">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Edit Book" : "Add Book"}
        </h1>
      </div>

      <form
        action=""
        className="p-5"
        onSubmit={editMode ? editBook : createBook}
      >
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              <span>Book</span> title
            </label>
            <input
              value={formState?.title}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="author" className="text-sm font-bold">
              <span>Book</span> author
            </label>
            <input
              value={formState?.author}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="author"
              id="author"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter author</span>
          </div>

          <div>
            {/* <label htmlFor="summary" className="text-sm font-bold">
              Description
            </label>
            <textarea
              value={formState?.summary}
              onChange={(e) => {
                handleChange(e);
              }}
              name="summary"
              id="summary"
              cols="30"
              rows="4"
              placeholder="Type or Paste Podcast summary"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            ></textarea> */}

            <Editor
              tinymceScriptSrc={
                "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                "/tinymce/tinymce.min.js"
              }
              onInit={(bookSummary) => {
                editorRefs.summary.current = bookSummary;
              }}
              initialValue={editMode ? formState?.summary : ""}
              init={{
                height: 350,
                menubar: true,
                plugins,
                toolbar,
                content_style,
                setup: function (editor) {
                  editor.setContent("place your content here");
                },
              }}
            />

            <span className="text-inactiveText text-xs">
              Describe <span>Book</span>
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <div className="">
              <label htmlFor="publication_year" className="text-sm font-bold">
                Year of publication
              </label>
              <input
                value={formState?.publication_year}
                onChange={(e) => {
                  handleChange(e);
                }}
                type="number"
                name="publication_year"
                id="publication_year"
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>

            <div>
              <label htmlFor="book_cover" className="text-sm font-bold">
                Add Image
                <span className="text-red-500">
                  (Cover art must be less than 200 killobyte)
                </span>
              </label>
              <p className={`font-bold ${editMode ? "" : "hidden"}`}>
                Current image{" "}
                <span className="text-primary">
                  <a href={selectedItem?.photo?.url} target="_black">
                    {selectedItem?.photo?.filename}
                  </a>
                </span>
              </p>
              <input
                type="file"
                name="book_cover"
                id="book_cover"
                onChange={handleFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div>
            <h1 className="text-lg font-bold">Category</h1>
            <span className="text-inactiveText text-xs">
              Check associated categories
            </span>

            <div className="flex justify-between mb-5">
              <div className="w-[50%]">
                {catStatus === StatusCode.LOADING ? (
                  <div>
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <div className="">
                    {Data.map((category) => (
                      <InputCat
                        key={category.id}
                        {...category}
                        checked={categories?.includes(category.id)}
                        onChange={() => handleCheckboxChange(category.id)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-10 w-[50%]">
                <div className="">
                  <label htmlFor="purchase_link" className="font-bold">
                    Add Book URL
                  </label>
                  <div className="px-2 py-1 w-full rounded-lg border-2 flex items-center bg-tranparent">
                    <input
                      value={formState?.purchase_link}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                      name="purchase_link"
                      id="purchase_link"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-10">
                  <div className="w-1/3">
                    <label htmlFor="subscription_level" className="font-bold">
                      Subscription level
                    </label>
                    <select
                      value={formState?.subscription_level}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="subscription_level"
                      id="subscription_level"
                      className=" border-2 py-2 w-full rounded-lg"
                    >
                      <option value="0">Basic</option>
                      {SubPlanData?.map((subPlan) => (
                        <option value={subPlan?.level}>
                          {subPlan?.package}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-2/3">
                    <label htmlFor="price" className="font-bold">
                      Amount
                    </label>
                    <div className="px-2 py-1 w-full rounded-lg border-2 flex items-center bg-tranparent">
                      <input
                        value={formState?.price}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="text"
                        name="price"
                        id="price"
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="btns flex items-center gap-10">
              <Button
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
                btntype={"submit"}
                btnText={
                  loading ? (
                    <Shimmer height={20} color={"#fff"} />
                  ) : editMode ? (
                    "Update Book"
                  ) : (
                    "Add Book"
                  )
                }
              />

              <Button
                btnClick={cancelAction}
                btnStyle="bg-inactiveText"
                btnText="Cancel"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
