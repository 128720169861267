import React, { useState } from "react";
import { DummyUserImage, VerifyIcon } from "../Assets";
import moment from "moment";
import { Activation } from "../Features/UserActivation";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Shimmer from "./Shimmer";
import { getUserInfos } from "../Features/UserSlice";

const UserDetails = ({ data }) => {
  const { userItem, currentPage } = useSelector((state) => state.user);
  const adminUserData = useSelector((state) => state.auth.userInfo?.data);

  if (data === undefined) {
    data = userItem[0];
  }
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const activateDeactivate = (e) => {
    e.preventDefault();
    if (adminUserData.role === "super") {
      if (data.id !== null) {
        setLoading(true);
        dispatch(Activation({ id: data.id }))
          .unwrap()
          .then(() => {
            showToast("Action successful");
            setLoading(false);
          })
          .then(() => {
            setTimeout(() => {
              dispatch(getUserInfos(currentPage));
            }, 2000);
          })
          .catch((error) => showToast("error deleting post: ", error));
      }
    } else {
      alert("Sorry only super admin can activate or deactivate users");
    }
  };

  // // // // // // TOASTIFY // // // // // //
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <div className="w-[90%] mx-auto ">
        <h3 className="text-xl font-semibold capitalize">profile details</h3>
        {data === null ? (
          <div className="w-full h-[500px] flex items-center justify-center text-center text-primary text-2xl">
            Please selet a user to see details
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="w-[105px] h-[105px] mx-auto flex items-center justify-center  rounded-full">
              <img
                src={data?.profile_photo || DummyUserImage}
                alt="profile"
                className="w-[100%] h-[100%] rounded-full"
              />
            </div>
            <div className="mt-3 text-center">
              <h2 className="text-lg font-semibold capitalize">{data?.name}</h2>
              <div className="flex items-center gap-3">
                <p className="text-sm text-[#898D9E]">{data?.email}</p>
                {data?.email_verified == "1" ? (
                  <div className="w-[20px] h-[20px] flex items-center justify-center">
                    <img
                      src={VerifyIcon}
                      alt=""
                      className="w-[100%] cursor-pointer"
                      title="Email is verified"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <p className="text-sm text-[#898D9E]">
                user ID:{" "}
                <span className="text-black font-medium">
                  {" "}
                  #{data?.recent_activity?.id}
                </span>
              </p>
            </div>
            <div className="w-full mt-8 flex items-center justify-between">
              <div className="w-[50%]">
                <h3 className="text-base mb-2 font-semibold w-full">
                  Country(Sign up):{" "}
                </h3>
                <h3 className="text-base mb-2 font-semibold w-full">
                  Country(Login):{" "}
                </h3>
                <h3 className="text-base mb-2 font-semibold w-full">
                  status:{" "}
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Subscription Plan:
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Subscription Duration:
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Goals Completed (%):
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Recent Login:
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Total Checkins:
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  Start date:
                </h3>
                <h3 className="text-base mb-2  w-full font-semibold">
                  End date
                </h3>
              </div>

              <div className=" w-[35%] flex items-start justify-start  flex-col">
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.signup_country || "N/A"}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.last_country || "N/A"}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.status == "1" ? "Active" : "Inactive"}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.subscription_package}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.subscription_duration}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.goals_completed}%
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {moment(data?.recent_activity?.created_at)
                    .startOf("ss")
                    .fromNow()}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {data?.checkins}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {moment(data?.created_at).startOf("ss").fromNow()}
                </p>
                <p className="mb-2 font-medium text-[15px] w-full">
                  {!data?.end_date ? "N/A" : data?.end_date}
                </p>
              </div>
            </div>
            <div className="w-[95%] flex items-center justify-between mt-3  gap-3">
              <button
                className="bg-primary rounded-xl py-1 px-5 capitalize text-white"
                onClick={activateDeactivate}
              >
                {loading ? (
                  <Shimmer height={20} color={"#fff"} />
                ) : data?.status == "1" ? (
                  "Deactivate User"
                ) : (
                  "Activate User"
                )}
              </button>
              <button
                disabled
                className="bg-red-600 text-white py-1 px-5 rounded-xl"
              >
                Delete user
              </button>
            </div>
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default UserDetails;
