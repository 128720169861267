import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "https://psychinsightsapp.net/api";

const initialState = {
  status: "idle",
  articlesData: {},
  error: null,
};

export const getArticles = createAsyncThunk(
  "get/getArticles",
  async (current) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/admin/articles?page=${current ?? 1}`,
        config
      );

      const final = response.data.data;
      return final;
    } catch (error) {
      throw error;
    }
  }
);

const articleSlice = createSlice({
  name: "article",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getArticles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArticles.fulfilled, (state, { payload }) => {
        state.status = "done";
        state.articlesData = payload;
      });
  },
});

export default articleSlice.reducer;
