import { useDispatch, useSelector } from "react-redux";
import { Dot, MinusIcon, PlusIcon } from "../Assets";
import Button from "./Button";
import Shimmer from "./Shimmer";
import { useEffect } from "react";
import StatusCode from "../Ultilities/StatusCode";
import { fetchDassOption } from "../Features/DassOptionSlice";

const DefaultOption = ({
  optionValues,
  optionChange,
  decrease,
  increase,
  addOptionField,
  removeOptionField,
  optionEditMode,
  handleUpdate,
  handleSubmit,
  loading,
  specicalDefault,
  optionActionOpen,
  optionAction,
  toEditOptionMode,
  handleDeleteOption,
}) => {
  const dispatch = useDispatch();
  const dassOption = useSelector((state) => state.dassOption?.data?.data);
  const dassOptionStatus = useSelector((state) => state?.dassOption);
  useEffect(() => {
    dispatch(fetchDassOption());
  }, [dispatch]);

  return (
    <div className="bg-white py-5 px-10 flex flex-col gap-5">
      {optionEditMode ? (
        ""
      ) : (
        <div>
          <h1 className="mb-5 text-primary">Default Options</h1>
          <table className="table w-full mx-auto divide-y divide-gray-200 ">
            <thead className="bg-[#ECECEC]">
              <tr>
                <th className="text-primary px-2 text-[14px] ">S/N</th>
                <th className="text-primary px-2 text-[14px] ">Option</th>
                <th className="text-primary px-2 text-[14px] ">Value</th>
                <th className="text-primary px-2 text-[14px] ">Create at</th>
                <th className="text-primary px-2 text-[14px] ">Last Updated</th>
              </tr>
            </thead>

            {dassOptionStatus === StatusCode.LOADING ? (
              <div className="fixed w-full top-[70%] left-[5%] ">
                <Shimmer height={38} width={38} color={"#207384"} />
              </div>
            ) : (
              <tbody>
                {/* {dassOption?.map((data, index) => (
                  <tr
                    className="text-center border-b hover:bg-[#E9F3F3]"
                    key={data.id}
                  >
                    <td className="py-2 px-5 text-[13px] font-semibold border-x border-gray-400">
                      {index + 1}
                    </td>
                    <td className="py-2 px-5 text-[13px] font-semibold text-sm capitalize border-r border-gray-400">
                      {data.option}
                    </td>
                    <td className="py-2 px-5 text-[13px] font-semibold text-sm capitalize border-r border-gray-400">
                      {data.value}
                    </td>
                    <td className="py-2 px-5 text-[13px] font-semibold text-sm border-r border-gray-400">
                      {new Date(data.created_at).toDateString()}
                    </td>
                    <td className="py-2 px-5 text-[13px] text-sm font-semibold border-r border-gray-400 flex items-center justify-between">
                      {new Date(data.updated_at).toDateString()}
                      <div className="relative">
                        <img
                          src={Dot}
                          alt=""
                          className="cursor-pointer"
                          onClick={() => optionAction(data)}
                        />

                        <div
                          className={`${
                            optionActionOpen?.[data?.id] ? "" : "hidden"
                          } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
                        >
                          <p
                            className="text-xs cursor-pointer text-[#0F2851]"
                            onClick={() => optionAction(data)}
                          >
                            Close
                          </p>

                          <p
                            className="text-xs cursor-pointer text-[#FF0101]"
                            onClick={handleDeleteOption}
                          >
                            {loading ? (
                              <Shimmer height={10} color={"#FF0101"} />
                            ) : (
                              "Delete"
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            )}
          </table>
        </div>
      )}

      <div>
        <h1 className="pb-5 text-primary flex items-center justify-between">
          <span>Add Default Options</span>{" "}
          {optionEditMode ? (
            ""
          ) : (
            <span>
              <Button
                btnClick={toEditOptionMode}
                btnText={"Update Options"}
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
              />
            </span>
          )}
        </h1>

        <div className="grid grid-cols-2 ">
          {optionValues?.map((_, index) => (
            <div className="w-[98%]" key={index}>
              <h3>Option {index + 1}</h3>
              <div className="flex items-center gap-1">
                <div className="flex flex-col items-start w-[70%]">
                  <input
                    name="option"
                    id="option"
                    value={optionValues[index]?.option}
                    onChange={(e) => optionChange(e, index)}
                    type="text"
                    className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                  />
                  <label className="text-base text-[#94A3B8]">
                    Enter option
                  </label>
                </div>

                <div className="flex flex-col items-center w-[30%]">
                  <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[70%]">
                    <button
                      dangerouslySetInnerHTML={{ __html: "&minus;" }}
                      className="px-1"
                      onClick={(e) => decrease(e, index)}
                    />
                    <input
                      name="value"
                      type="tel"
                      placeholder="value"
                      value={
                        optionValues[index]?.value || specicalDefault || "0"
                      }
                      onChange={(e) => optionChange(e, index)}
                      className={`valueFeild-${index} w-full h-[25px] px-2 text-center border-x-2`}
                    />
                    <button
                      dangerouslySetInnerHTML={{ __html: "&plus;" }}
                      className="px-1"
                      onClick={(e) => increase(e, index)}
                    />
                  </div>
                  <p className="text-base text-[#94A3B8]">Enter value</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {optionEditMode ? (
          ""
        ) : (
          <div className="my-5 flex items-center gap-5">
            <Button
              btnClick={addOptionField}
              btnText="Option"
              btnStyle="bg-[#78B7C5]"
              btnIcon={PlusIcon}
            />

            {optionValues.length > 1 ? (
              <Button
                btnClick={removeOptionField}
                btnText="Option"
                btnStyle="bg-[#898D9E] "
                btnIcon={MinusIcon}
              />
            ) : (
              ""
            )}
          </div>
        )}

        <div className="mt-5 flex items-center  gap-5">
          <Button
            btnDisabled={loading}
            btnClick={optionEditMode ? handleUpdate : handleSubmit}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : optionEditMode ? (
                "Update Options"
              ) : (
                "Add Option"
              )
            }
            btnStyle={loading ? "bg-[#898D9E]" : "bg-primary"}
          />
          <Button
            btnClick={() => window.location.reload()}
            btnText="Cancel"
            btnStyle="bg-[#898D9E]"
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultOption;
