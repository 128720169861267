import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const Activation = createAsyncThunk(
  "activate/deactivate",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await fetch(
        `${BASE_URL}/admin/users/${id}/activation`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
