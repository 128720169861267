import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  readReflect: {},
  error: null,
};

export const getReadReflect = createAsyncThunk(
  "get/getReadReflex",
  async (current) => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/admin/read-and-reflects?page=${current ?? 1}`,
      config
    );
    const final = response.data.data;
    return final;
  }
);

const readReflectSlice = createSlice({
  name: "readReflect",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getReadReflect.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getReadReflect.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.readReflect = payload;
      });
  },
});

export default readReflectSlice.reducer;

// // // // // // DELETE // // // // // //
export const deleteReadReflect = createAsyncThunk(
  "delete/ReadReflect",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };
      const responce = await fetch(
        `${BASE_URL}/admin/read-and-reflects/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // ADD NEW READ & REFELECTION // // // // // //
export const AddNewReadReflect = createAsyncThunk(
  "add/createNewReadReflect",
  async (formData) => {
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}/admin/read-and-reflects`,
        config
      );
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE // // // // // //
export const updateNewReadReflect = createAsyncThunk(
  "update/readReflect",
  async (data) => {
    const { id, formData } = data;
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}/admin/read-and-reflects/${id}`,
        config
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // ADD REFELECTION // // // // // //
export const AddNewReflect = createAsyncThunk(
  "add/createNewReflection",
  async (data) => {
    const { id, reflection } = data;
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify({ reflection }),
      };

      const response = await fetch(
        `${BASE_URL}/admin/read-and-reflects/${id}/reflections`,
        config
      );
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE REFELECTION // // // // // //
export const UpdateReflection = createAsyncThunk(
  "update/cupdateReflection",
  async (data) => {
    const { id, reflection } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify({ reflection }),
      };

      const response = await fetch(
        `${BASE_URL}/admin/read-and-reflects/reflections/${id}`,
        config
      );
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // DELETE SINGLE REFLECTION // // // // // //
export const DeleteReflection = createAsyncThunk(
  "delete/Reflection",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };
      const responce = await fetch(
        `${BASE_URL}/admin/read-and-reflects/reflections/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // PUBLISH // // // // // //
export const Publish = createAsyncThunk("publish/unpublish", async ({ id }) => {
  try {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const response = await fetch(
      `${BASE_URL}/admin/read-and-reflects/${id}/publish`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
});
