import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Features/AuthSlice";
import { useIdleTimer } from "react-idle-timer";
import Modal from "react-modal";

Modal.setAppElement("#root");

const IdleTimerComponent = () => {
  const TimeoutRef = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [showModal, setShowModal] = useState(false);
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      setShowModal(true);
      TimeoutRef.current = setTimeout(killSession, 1000 * 30);
    },
  });

  const customStyles = {
    content: {
      top: "35%",
      left: "45%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "190px",
      width: "300px",
    },
  };

  const keepSession = () => {
    setShowModal(false);
    clearTimeout(TimeoutRef.current);
  };
  const killSession = () => {
    setShowModal(false);
    dispatch(logout());
    clearTimeout(TimeoutRef.current);
  };

  return (
    <div>
      {userInfo && (
        <Modal isOpen={showModal} style={customStyles}>
          <div className="flex flex-col items-center justify-center">
            <h2 className="mt-1 font-semibold text-lg">
              Are you still active?
            </h2>
            <p className="my-2">you'll be logout automatically</p>
            <div className="flex items-center gap-5 mt-6">
              <button
                onClick={keepSession}
                className="bg-[#047d1e] rounded-lg text-white px-6 border-none py-1 capitalize font-medium"
              >
                yes
              </button>
              <button
                onClick={killSession}
                className="bg-red-600 text-white rounded-lg px-6 py-1 capitalize font-medium border-none"
              >
                no
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default IdleTimerComponent;
