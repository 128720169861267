import { createAsyncThunk } from "@reduxjs/toolkit";

// const initialState = {
//   status: "idle",
//   error: null,
// };

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

export const AddNewQuote = createAsyncThunk(
  "quote/createNewQuote",
  async (payLoad) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(`${BASE_URL}/admin/daily-quotes`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateQuote = createAsyncThunk(
  "quote/updateQuote",
  async (data) => {
    const { id } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/daily-quotes/${id}`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const AddNewTip = createAsyncThunk(
  "tip/createNewTip",
  async (payLoad) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payLoad),
      };

      const response = await fetch(`${BASE_URL}/admin/daily-tips`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const updateTip = createAsyncThunk("tip/updateTip", async (data) => {
  const { id } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(`${BASE_URL}/admin/daily-tips/${id}`, config);
    return response;
  } catch (error) {
    throw error;
  }
});
