import React, { useEffect } from "react";
import { fetchCategory } from "../Features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Shimmer from "./Shimmer";

const AddDailyQuest = ({
  onCancle,
  handleSubmit,
  formState,
  handleChange,
  handleCheckboxChange,
  optionValues,
  optionChange,
  increase,
  decrease,
  loading,
  categories,
  editMode,
  handleUpdate,
}) => {
  const dispatch = useDispatch();
  const CategoriesData = useSelector((state) => state.category.data);
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  return (
    <div className="w-full py-2 bg-white shadow-custom rounded-xl">
      <div className="w-[95%] mx-auto">
        <h1 className="text-lg font-medium text-primary ">
          {editMode ? "Update Daily Question" : "Add Daily Question"}
        </h1>
      </div>
      <hr className="w-full bg-[#898D9E] my-3 " />

      <form>
        <div className="w-[95%] mx-auto flex items-start gap-5">
          <div className="flex flex-col items-start w-full">
            <h3 className="capitalize">question</h3>
            <div className="flex flex-col items-start w-full">
              <input
                id="question"
                value={formState?.question}
                onChange={(e) => handleChange(e)}
                type="text"
                className="border-[2px] pl-3 h-[30px] my-1 w-[80%] rounded-xl"
              />
              <label className="text-[#94A3B8]  capitalize text-sm">
                enter question
              </label>
            </div>
          </div>
          <div className="w-full">
            <h3 className="capitalize">Category</h3>
            <p className="text-[#94A3B8]  capitalize text-sm my-2">
              Check associated categories
            </p>
            <div className="grid grid-cols-3  w-full">
              {CategoriesData?.map((category) => (
                <div
                  key={category.id}
                  className="px-3 flex items-center gap-2 mb-2"
                >
                  <input
                    type="checkbox"
                    name="categories"
                    id="categories"
                    value={category?.id}
                    checked={categories?.includes(category.id)}
                    onChange={() => handleCheckboxChange(category?.id)}
                    className="w-5 h-5"
                  />
                  <label className="w-full text-sm">{category.category}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[95%] mx-auto mt-6">
          <div className="grid grid-cols-2 gap-4">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="mb-4 psr-5">
                <label htmlFor={`options[${index}].option`}>
                  Option {index + 1}
                </label>
                <div className="flex items-center mt-3 gap-5">
                  <div className="w-3/4">
                    <input
                      placeholder="Option"
                      name="option"
                      value={optionValues[index]?.option}
                      onChange={(e) => optionChange(e, index)}
                      className="w-full border h-[36px] rounded-xl px-2"
                    />
                  </div>

                  <div className="w-1/4 flex items-center border py-[1px] px-1  rounded-lg">
                    <button
                      dangerouslySetInnerHTML={{ __html: "&minus;" }}
                      className="px-2 py-1"
                      onClick={(e) => decrease(e, index)}
                    />

                    <input
                      name="value"
                      type="tel"
                      placeholder="value"
                      value={optionValues[index]?.value || "1"}
                      onChange={(e) => optionChange(e, index)}
                      className={`valueFeild-${index} w-full h-[36px] p-2 text-center border-x-2`}
                    />

                    <button
                      dangerouslySetInnerHTML={{ __html: "&plus;" }}
                      className="px-2 py-1"
                      onClick={(e) => increase(e, index)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center gap-10 my-7">
            <button
              disabled={loading}
              onClick={editMode ? handleUpdate : handleSubmit}
              type="submit"
              className={`px-4 py-[6px] text-base text-white rounded-xl ${
                loading ? "bg-[#898D9E]" : "bg-primary"
              } `}
            >
              {loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Question"
              ) : (
                "Add Question"
              )}
            </button>

            <button
              className="bg-[#898D9E] px-5 capitalize py-[4px] rounded-xl text-white text-base"
              onClick={onCancle}
            >
              cancle
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDailyQuest;
