import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import { PlusIcon } from "../Assets";
import {
  addGoals,
  addQuestionGoal,
  addReflectionGoal,
  deleteGoals,
  deleteQuestionGoals,
  deleteReflectionGoals,
  getGoals,
  unpublishGoals,
  updateGoals,
  updateQuestionGoals,
  updateReflectionGoals,
} from "../Features/GoalsSlice";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import GoalQuestionForm, {
  GoalForm,
  GoalModal,
  GoalReflectionForm,
} from "./GoalsForms";

const Goals = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [codeActionOpen, setCodeActionOpen] = useState(false);
  const [codeActionOpen2, setCodeActionOpen2] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [questionFormOpen, setQuestionFormOpen] = useState(false);
  const [questionEditMode, setQuestionEditMode] = useState(false);
  const [selectedReflection, setSelectedReflection] = useState(false);
  const [reflectionEditMode, setReflectionEditMode] = useState(false);
  const [reflectionFormOpen, setReflectionFormOpen] = useState(false);
  const editorRefs = {
    goal_setting_overview: useRef(null),
    pre_text: useRef(null),
    post_text: useRef(null),
    example: useRef(null),
  };

  const [questionValues, setQuestionValues] = useState({
    title: "",
    weekly_plan: 0,
  });
  const [reflectionValues, setReflectionValues] = useState({
    title: "",
    type: "range", //text or range
    min: 0,
    max: "",
  });

  const [formState, setFormState] = useState({
    category: "",
    goal_setting_overview: "",
  });

  useEffect(() => {
    dispatch(getGoals());
  }, [dispatch]);
  const goalsData = useSelector((state) => state.goals.goals.data);
  const openForm = () => {
    setFormIsOpen(!formIsOpen);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };

  // create Goal
  const createGoal = (e) => {
    e.preventDefault();
    const payLoad = {
      category: formState.category,
      goal_setting_overview:
        editorRefs.goal_setting_overview?.current.target.getContent(),
    };
    if (payLoad.category === "") {
      alert("Category Name is required");
    } else if (payLoad.goal_setting_overview === "") {
      alert("Goal Setting Overview is required");
    } else {
      setLoading(true);
      dispatch(addGoals(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Goal Added successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // selectedItem
  const pickGoal = (item) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedItem(item);
  };

  // Delete Goal
  const handleDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteGoals({ id: selectedItem.id }))
      .unwrap()
      .then(() => {
        showToast("Goal Deleted successfully.");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  // updste
  const readyUpdate = () => {
    setEditMode(!editMode);
    openForm();
  };
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
  }, [editMode, selectedItem]);
  const updateGoal = (e) => {
    e.preventDefault();
    const payLoad = {
      id: selectedItem.id,
      category: formState.category,
      goal_setting_overview:
        editorRefs.goal_setting_overview?.current.target.getContent(),
    };
    if (payLoad.category === "") {
      alert("Category Name is required");
    } else if (payLoad.goal_setting_overview === "") {
      alert("Goal Setting Overview is required");
    } else {
      setLoading(true);
      dispatch(updateGoals(payLoad))
        .unwrap()
        .then(() => {
          showToast(`Goal Updated Successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // add Questions
  const showQuestionForm = (e) => {
    e.preventDefault();
    setModalIsOpen(false);
    setQuestionFormOpen(!questionFormOpen);
  };

  // const addQuestionField = (forReflection) => {
  //   if (forReflection) {
  //     setReflectionValues((prev) => [
  //       ...prev,
  //       {
  //         title: "",
  //         pre_text: "",
  //         post_text: "",
  //         type: "range",
  //         min: 0,
  //         max: "",
  //       },
  //     ]);
  //   } else {
  //     setQuestionValues((prev) => [
  //       ...prev,
  //       { title: "", pre_text: "", example: "", weekly_plan: 0 },
  //     ]);
  //   }
  // };
  // const removeQuestionField = (isReflection) => {
  //   if (isReflection) {
  //     setReflectionValues((prev) => {
  //       const updatedData = [...prev];
  //       updatedData.pop();
  //       return updatedData;
  //     });
  //   } else {
  //     setQuestionValues((prev) => {
  //       const updatedData = [...prev];
  //       updatedData.pop();
  //       return updatedData;
  //     });
  //   }
  // };

  const validate = (array) => {
    let isValid = true;
    array.forEach((obj, index) => {
      if (obj.title?.trim() === "") {
        alert(`Title of reflection/question ${index + 1} must not empty`);
        isValid = false;
      }
      // if (obj.pre_text?.trim() === "") {
      //   alert(`Pre_text of reflection/question ${index + 1} must not empty`);
      //   isValid = false;
      // }
      if (obj.max?.trim() === "") {
        alert(`Max of reflection ${index + 1} must not empty`);
        isValid = false;
      }
      if (obj.min < 0 || obj.min >= obj.max) {
        alert(`Max must be grater than Min for reflection ${index + 1}`);
        isValid = false;
      }
    });
    return isValid;
  };
  const questionChnage = (e) => {
    const { id, value } = e.target;
    setQuestionValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleAddQuestions = (e) => {
    e.preventDefault();
    const payload = [
      {
        ...questionValues,
        pre_text: editorRefs.pre_text?.current.target.getContent(),
        example: editorRefs.example?.current.target.getContent(),
      },
    ];
    if (validate(payload)) {
      const dataToSubmit = {
        goal_questions: payload,
      };
      setLoading(true);
      dispatch(addQuestionGoal({ id: selectedItem.id, payload: dataToSubmit }))
        .unwrap()
        .then(() => {
          showToast(`Question Created Successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // Update Questions
  const openAction2 = (item) => {
    setCodeActionOpen2((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedQuestion(item);
  };
  const cancelAction2 = () => {
    setCodeActionOpen2(!codeActionOpen);
    setSelectedQuestion(null);
  };
  useEffect(() => {
    if (!questionEditMode) return;
    setQuestionValues(selectedQuestion);
  }, [questionEditMode, selectedQuestion]);
  const updateQuestion = (e) => {
    e.preventDefault();
    setModalIsOpen(false);
    setQuestionEditMode(true);
    setQuestionFormOpen(true);
  };

  const handleQuestionUpdate = (e) => {
    e.preventDefault();
    const payLoad = {
      title: questionValues.title,
      weekly_plan: questionValues.weekly_plan,
      pre_text: editorRefs.pre_text?.current.target.getContent(),
      example: editorRefs.example?.current.target.getContent(),
    };
    if (payLoad.title?.trim() === "") {
      alert(`Title of questioon must not empty`);
    } else if (payLoad.pre_text?.trim() === "") {
      alert(`Pre_text of question must not empty`);
    } else {
      setLoading(true);
      dispatch(
        updateQuestionGoals({
          id: selectedQuestion.id,
          payload: payLoad,
        })
      )
        .unwrap()
        .then(() => {
          showToast(`Question Updated Successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // Delete Question
  const handleQuestionDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      deleteQuestionGoals({
        id: selectedQuestion.id,
      })
    )
      .unwrap()
      .then(() => {
        showToast(`Question Deleted Successfully`);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        alert("Error Deleting post:", error);
      });
  };

  // Add reflection
  const showReflectionForm = (e) => {
    e.preventDefault();
    setModalIsOpen(false);
    setReflectionFormOpen(!reflectionFormOpen);
  };
  const reflectionChnage = (e) => {
    const { id, value } = e.target;
    setReflectionValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleAddReflection = (e) => {
    const payLoad = [
      {
        pre_text: editorRefs.pre_text?.current.target.getContent(),
        post_text: editorRefs.post_text?.current.target.getContent(),
        ...reflectionValues,
      },
    ];
    e.preventDefault();
    if (validate(payLoad)) {
      const dataToSubmit = {
        reflections: payLoad,
      };
      setLoading(true);
      dispatch(
        addReflectionGoal({ id: selectedItem.id, payload: dataToSubmit })
      )
        .unwrap()
        .then(() => {
          showToast(`Reflection Created Successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post:", error);
        });
    }
  };

  // Update reflection
  const openAction = (item) => {
    setCodeActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedReflection(item);
  };
  const cancelAction = () => {
    setCodeActionOpen(!codeActionOpen);
    setSelectedReflection(null);
  };
  useEffect(() => {
    if (!reflectionEditMode) return;
    setReflectionValues(selectedReflection);
  }, [reflectionEditMode, selectedReflection]);

  const updateReflection = (e) => {
    e.preventDefault();
    setReflectionEditMode(true);
    setReflectionFormOpen(true);
    setModalIsOpen(false);
  };
  const handleReflectionUpdate = (e) => {
    e.preventDefault();
    const payLoad = {
      title: reflectionValues.title,
      type: reflectionValues.type,
      min: reflectionValues.min,
      max: reflectionValues.max,
      pre_text: editorRefs.pre_text?.current.target.getContent(),
      post_text: editorRefs.post_text?.current.target.getContent(),
    };
    if (payLoad.title?.trim() === "") {
      alert(`Title of reflection must not empty`);
    } else if (payLoad.min > payLoad.max) {
      alert(`Maximum must be grater than Minimum`);
    } else {
      setLoading(true);
      dispatch(
        updateReflectionGoals({
          id: selectedReflection.id,
          payload: payLoad,
        })
      )
        .unwrap()
        .then(() => {
          showToast(`Reflection Updated Successfully`);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error updating post:", error);
        });
    }
  };

  // Delete Reflection
  const handleReflectionDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      deleteReflectionGoals({
        id: selectedReflection.id,
      })
    )
      .unwrap()
      .then(() => {
        showToast(`Reflection Deleted Successfully`);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        alert("Error Deleting post:", error);
      });
  };

  // uplish and unblish
  const publishUnpublish = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      unpublishGoals({
        id: selectedItem.id,
      })
    )
      .unwrap()
      .then(() => {
        showToast(
          selectedItem?.published == "1"
            ? `Goal Unpublished Successfully`
            : ` Goal Published Successfully`
        );
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        alert("Error post:", error);
      });
  };

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <ToastContainer />

      {formIsOpen ? (
        <GoalForm
          formState={formState}
          handleChange={handleChange}
          handleCreate={createGoal}
          loading={loading}
          editMode={editMode}
          handleUpdate={updateGoal}
          editorRefs={editorRefs}
        />
      ) : (
        <div className="relative">
          <div
            className={
              modalIsOpen || questionFormOpen || reflectionFormOpen
                ? `hidden`
                : ""
            }
          >
            <div className="flex justify-end">
              <Button
                btnText={"Add Goal"}
                btnIcon={PlusIcon}
                btnStyle={"bg-primary"}
                btnDisabled={""}
                btnClick={openForm}
              />
            </div>

            <div
              className={`w-full py-2 mt-6 bg-white rounded-lg shadow-custom`}
            >
              <div className="w-[93%] mx-auto mt-2 mb-4">
                <h3 className="text-lg font-medium capitalize text-primary">
                  Goals
                </h3>
              </div>
              <table className="table w-full mx-auto divide-y divide-gray-200 ">
                <thead className="bg-[#ECECEC]">
                  <tr>
                    <th className="text-primary px-2 text-[14px] ">S/N</th>
                    <th className="text-primary px-2 text-[14px] ">Category</th>
                    <th className="text-primary px-2 text-[14px] ">
                      Create at
                    </th>
                    <th className="text-primary px-2 text-[14px] ">
                      Last Updated
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {goalsData?.map((data, index) => (
                    <tr
                      className="text-center border-b hover:bg-[#E9F3F3]"
                      key={data.id}
                      onClick={() => pickGoal(data)}
                    >
                      <td className="py-2 px-5 text-[13px] font-semibold border-r border-gray-400">
                        {index + 1}
                      </td>
                      <td className="py-2 px-5 text-[13px] font-semibold text-sm capitalize border-r border-gray-400">
                        {data.category}
                      </td>
                      <td className="py-2 px-5 text-[13px] font-semibold text-sm border-r border-gray-400">
                        {new Date(data.created_at).toDateString()}
                      </td>
                      <td className="py-2 px-5 text-[13px] text-sm font-semibold border-r border-gray-400">
                        {new Date(data.updated_at).toDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={`${
              modalIsOpen ? "flex items-center justify-center" : "hidden"
            }`}
          >
            <GoalModal
              {...selectedItem}
              closeModal={() => setModalIsOpen(false)}
              deleteItem={handleDelete}
              updateItem={readyUpdate}
              goalQuestion={showQuestionForm}
              goalReflection={showReflectionForm}
              //
              loading={loading}
              openAction={openAction}
              cancelAction={cancelAction}
              updateReflection={updateReflection}
              codeActionOpen={codeActionOpen}
              deleteReflection={handleReflectionDelete}
              //
              openAction2={openAction2}
              codeActionOpen2={codeActionOpen2}
              cancelAction2={cancelAction2}
              updateQuestion={updateQuestion}
              deleteQuestion={handleQuestionDelete}
              //
              selectedItem={selectedItem}
              publishGoal={publishUnpublish}
            />
          </div>

          <div
            className={`${
              questionFormOpen ? "flex items-center justify-center" : "hidden"
            }`}
          >
            <GoalQuestionForm
              loading={loading}
              questionValues={questionValues}
              questionChnage={questionChnage}
              handleCreate={handleAddQuestions}
              handleQuestionUpdate={handleQuestionUpdate}
              questionEditMode={questionEditMode}
              editorRefs={editorRefs}
              // addQuestionField={() => addQuestionField()}
              // removeQuestionField={() => removeQuestionField()}
            />
          </div>

          <div
            className={`${
              reflectionFormOpen ? "flex items-center justify-center" : "hidden"
            }`}
          >
            <GoalReflectionForm
              reflectionValues={reflectionValues}
              reflectionChnage={reflectionChnage}
              loading={loading}
              handleCreate={handleAddReflection}
              reflectionEditMode={reflectionEditMode}
              handleReflectionUpdate={handleReflectionUpdate}
              editorRefs={editorRefs}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Goals;
