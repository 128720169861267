import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreePackage,
  deleteFreePackage,
  getFreePackage,
} from "../Features/FreePackageSlice";
import { toast } from "react-toastify";
import Shimmer from "./Shimmer";

const FreePackage = () => {
  const dispatch = useDispatch();
  const freePackageData = useSelector(
    (state) => state.freePackage.freePackage.data
  );
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    article_limit: "",
    audio_limit: "",
    book_limit: "",
    learn_and_do_limit: "",
    listen_and_learn_limit: "",
    podcast_limit: "",
    read_and_reflect_limit: "",
    video_limit: "",
  });
  useEffect(() => {
    dispatch(getFreePackage());
  }, [dispatch]);
  useEffect(() => {
    setFormState({ ...freePackageData });
  }, [freePackageData]);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prev) => ({ ...prev, [id]: value }));
  };

  const setFreePackage = (e) => {
    e.preventDefault();
    const payLoad = {
      article_limit: formState.article_limit,
      audio_limit: formState.audio_limit,
      book_limit: formState.book_limit,
      learn_and_do_limit: formState.learn_and_do_limit,
      listen_and_learn_limit: formState.listen_and_learn_limit,
      podcast_limit: formState.podcast_limit,
      read_and_reflect_limit: formState.read_and_reflect_limit,
      video_limit: formState.video_limit,
    };
    setLoading(true);
    dispatch(addFreePackage(payLoad))
      .unwrap()
      .then(() => {
        showToast("Free Package created/updated successfully");
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setLoading(false);
      })
      .catch((error) => alert("error updating post: ", error));
  };

  const deleteTrial = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteFreePackage())
      .unwrap()
      .then(() => {
        showToast("Free Package deleted successfully");
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setLoading(false);
      })
      .catch((error) => alert("error updating post: ", error));
  };

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };

  return (
    <div>
      <div className="my-10 bg-mainWhite rounded-lg">
        <h1 className=" text-primary px-5 py-3 border-b-[1px] border-primary">
          Set free package
        </h1>

        <div className="p-5">
          <div>
            <h1 className="text-primary">Restrictions limits</h1>
            <p className="text-xs font-light text-primary my-1">
              Enter number of resources available to plan
            </p>
          </div>

          <form action="">
            <div className="grid grid-cols-4 gap-10 my-10">
              <div>
                <label htmlFor="video_limit">Video</label> <br />
                <input
                  type="number"
                  id="video_limit"
                  name="video_limit"
                  placeholder="0"
                  value={formState?.video_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>

              <div>
                <label htmlFor="audio_limit">Audio</label> <br />
                <input
                  type="number"
                  id="audio_limit"
                  name="audio_limit"
                  placeholder="0"
                  value={formState?.audio_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="podcast_limit">Podcast</label> <br />
                <input
                  type="number"
                  id="podcast_limit"
                  name="podcast_limit"
                  placeholder="0"
                  value={formState?.podcast_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="book_limit">Book</label> <br />
                <input
                  type="number"
                  id="book_limit"
                  name="book_limit"
                  placeholder="0"
                  value={formState?.book_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="article_limit">Article</label> <br />
                <input
                  type="number"
                  id="article_limit"
                  name="article_limit"
                  placeholder="0"
                  value={formState?.article_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="learn_and_do_limit">Learn & Do</label> <br />
                <input
                  type="number"
                  id="learn_and_do_limit"
                  name="learn_and_do_limit"
                  placeholder="0"
                  value={formState?.learn_and_do_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="read_and_reflect_limit">Read & Reflect</label>
                <br />
                <input
                  type="number"
                  id="read_and_reflect_limit"
                  name="read_and_reflect_limit"
                  placeholder="0"
                  value={formState?.read_and_reflect_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
              <div>
                <label htmlFor="listen_and_learn_limit">Listen & Learn</label>{" "}
                <br />
                <input
                  type="number"
                  id="listen_and_learn_limit"
                  name="listen_and_learn_limit"
                  placeholder="0"
                  value={formState?.listen_and_learn_limit}
                  onChange={(e) => handleChange(e)}
                  className="w-[150px] rounded-lg border-2 text-center border-inactiveText"
                />
              </div>
            </div>

            <div className="flex items-center gap-5 my-5">
              <Button
                btnClick={setFreePackage}
                btnDisabled={loading}
                btnText={
                  loading ? <Shimmer height={20} color={"#fff"} /> : "Add trial"
                }
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
              />

              <Button
                btnClick={deleteTrial}
                btnDisabled={loading}
                btnText={"Delete"}
                btnStyle={"bg-inactiveText"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreePackage;
