import React from "react";
import Button from "./Button";
import Shimmer from "./Shimmer";

const CatForm = ({
  editMode,
  formState,
  handleChange,
  loading,
  handleCreate,
  handleUpdate,
}) => {
  return (
    <div className="w-full bg-white p-5 ">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Update Blog Category" : "Add Blog Category"}
        </h1>
      </div>

      <form className="">
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="category" className="text-sm font-bold">
              Category
            </label>
            <input
              value={formState?.category}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="category"
              id="category"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter category</span>
          </div>

          <div>
            <label htmlFor="description" className="text-sm font-bold">
              Description
            </label>
            <input
              value={formState?.description}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="description"
              id="description"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter description</span>
          </div>
        </div>

        <div className=" mt-5 flex items-center gap-5">
          <Button
            btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
            btnClick={editMode ? handleUpdate : handleCreate}
            btnText={
              loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Category"
              ) : (
                "Add Category"
              )
            }
          />

          <Button
            btnClick={() => window.location.reload()}
            btnStyle="bg-inactiveText"
            btnText="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default CatForm;
