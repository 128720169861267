import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

const Acod2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialClass = "flex items-center gap-2 text-[#94A3B8] cursor-pointer";
  const isOpenClass = "flex items-center gap-2 text-white cursor-pointer";
  const activeLink = "flex items-start gap-3 text-white";
  const normalLink = "flex items-start gap-3 text-[#94A3B8] hover:text-white";
  return (
    <div>
      <div
        className={`hover:text-white ${isOpen ? isOpenClass : initialClass}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        Strategies
        {isOpen ? <IoChevronDownOutline /> : <IoChevronUpOutline />}
      </div>

      {isOpen && (
        <ul className="ml-3 list-disc">
          <li className="mb-1 text-white">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="readReflect"
            >
              Read & Reflect
            </NavLink>
          </li>
          <li className="mb-1 text-white">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="learnDo"
            >
              Learn & Do
            </NavLink>
          </li>
          <li className="mb-1 text-white">
            <NavLink
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              to="/listenLearn"
            >
              Listen & Learn
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Acod2;
