// import React, { useEffect, useState, useRef } from "react";
import BoardData from "./BoardData";
import { BlogIcon, CategoriesIcon } from "../Assets";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { blogSummary } from "../Features/BlogSummay";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Blog = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(blogSummary());
  }, [dispatch]);
  const blogSummaryData = useSelector(
    (state) => state.blogSummary?.blogSummary
  );
  return (
    <div>
      <div className={`relative flex flex-col gap-7`}>
        <div className="flex gap-5">
          <BoardData
            textStyle={"text-[#9747FF]"}
            resourceName="Blog"
            resourceTotal={blogSummaryData?.data?.blogs}
            resourceIcon={BlogIcon}
          />
          <BoardData
            textStyle={"text-[#C380CE]"}
            resourceName="Categories"
            resourceTotal={blogSummaryData?.data?.categories}
            resourceIcon={CategoriesIcon}
          />
        </div>

        <div>
          <Link
            to=""
            className={`${
              pathname === "/blog"
                ? "bg-primary text-mainWhite"
                : "bg-mainWhite text-primary"
            } px-5 py-1 rounded-l-lg`}
          >
            Blogs
          </Link>

          <Link
            to="blog-categories"
            className={`${
              pathname === "/blog/blog-categories"
                ? "bg-primary text-mainWhite"
                : "bg-mainWhite text-primary"
            } px-5 py-1 rounded-r-lg`}
          >
            Categories
          </Link>
        </div>

        <div className="w-full">
          <Outlet />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Blog;
