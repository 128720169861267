import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

const initialState = {
  status: "idle",
  learnDo: {},
  error: null,
};

export const getLearnDo = createAsyncThunk(
  "get/getLearnDo",
  async (currentPage) => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/learn-and-dos?page=${currentPage}`,
      config
    );
    const final = response.data.data;
    return final;
  }
);

const learnDoSlice = createSlice({
  name: "learnDo",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLearnDo.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(getLearnDo.fulfilled, (state, { payload }) => {
        state.status = "Fulfilled";
        state.learnDo = payload;
      });
  },
});

export default learnDoSlice.reducer;

// // // // // // DELETE LEARN & DO // // // // // //
export const DeleteLearnDo = createAsyncThunk(
  "delete/LearnDo",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/learn-and-dos/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // CREATE // // // // // //
export const AddNewLearnDo = createAsyncThunk(
  "add/learbDo",
  async (formData) => {
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(`${BASE_URL}/admin/learn-and-dos`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE // // // // // //
export const UpdateLearnDo = createAsyncThunk(
  "update/listenLearn",
  async (data) => {
    const { id, formData } = data;
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}/admin/learn-and-dos/${id}`,
        config
      );

      //   success
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // Create Activity // // // // // //
export const createActivity = createAsyncThunk(
  "update/createActivity",
  async (data) => {
    const { id } = data;
    try {
      const config = {
        method: "POST",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/learn-and-dos/${id}/activities`,
        config
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);
// // // // // // Update Activity // // // // // //
export const updateActivityCall = createAsyncThunk(
  "update/updateActivity",
  async (data) => {
    const { id } = data;
    try {
      const config = {
        method: "PUT",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `${BASE_URL}/admin/learn-and-dos/activities/${id}`,
        config
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // DELETE // // // // // //
export const DeleteActivity = createAsyncThunk(
  "delete/LearnDo",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id }),
      };

      const responce = await fetch(
        `${BASE_URL}/admin/learn-and-dos/activities/${id}`,
        config
      );

      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // PUBLISH // // // // // //
export const PublishLearnDo = createAsyncThunk(
  "publish/unpublish",
  async ({ id }) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await fetch(
        `${BASE_URL}/admin/learn-and-dos/${id}/publish`,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// // // // // // UPDATE QUESTION ACTIVITY // // // // // //
export const updateQuestion = createAsyncThunk("update", async (data) => {
  const { id } = data;
  try {
    const config = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${BASE_URL}/admin/learn-and-dos/questions/${id}`,
      config
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
});
// // // // // // DELETE QUESTION ACTIVITY // // // // // //
export const DeleteQuestion = createAsyncThunk(
  "delete/LearnDo",
  async ({ id }) => {
    try {
      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const responce = await fetch(
        `${BASE_URL}/admin/learn-and-dos/questions/${id}`,
        config
      );
      if (responce.ok) {
        const data = await responce.json();
        return data;
      } else {
        alert("Fail to delete");
      }
    } catch (error) {
      throw error;
    }
  }
);
