import React from "react";
import Button from "./Button";
import { MinusIcon, PlusIcon } from "../Assets";
import { Dot } from "../Assets";
import Shimmer from "./Shimmer";
import { Editor } from "@tinymce/tinymce-react";
import { removeHtmlTags } from "../Ultilities/useFunctions";

const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const GoalQuestionForm = ({
  loading,
  questionValues,
  questionChnage,
  handleCreate,
  handleQuestionUpdate,
  questionEditMode,
  editorRefs,
}) => {
  return (
    <div className="w-full rounded-lg bg-mainWhite shadow-lg px-5 py-10">
      <h1 className="">
        {questionEditMode ? "Update Question" : "Add Question"}
      </h1>
      <div className="mt-10">
        <div className="">
          <h3>Question </h3>
          <div className="grid grid-cols-2 gap-3">
            <div className="flex flex-col items-start">
              <input
                name="title"
                id="title"
                value={questionValues.title}
                onChange={(e) => questionChnage(e)}
                type="text"
                className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
              />
              <label className="text-base text-[#94A3B8]">Enter title</label>
            </div>

            <div className="flex flex-col items-start">
              <select
                name="weekly_plan"
                id="weekly_plan"
                onChange={(e) => questionChnage(e)}
                className="border-[2px] my-1 px-3 py-1 w-[100%] rounded-xl"
              >
                <option value="0">False</option>
                <option value="1">True</option>
              </select>
              <label className="text-base text-[#94A3B8]">weekly_plan</label>
            </div>

            <div className="flex flex-col items-start">
              <div className="w-full">
                <Editor
                  tinymceScriptSrc={
                    "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                    "/tinymce/tinymce.min.js"
                  }
                  onInit={(goalOverview) => {
                    editorRefs.pre_text.current = goalOverview;
                  }}
                  initialValue={
                    questionEditMode ? questionValues?.pre_text : ""
                  }
                  init={{
                    height: 350,
                    menubar: true,
                    plugins,
                    toolbar,
                    content_style,
                    setup: function (editor) {
                      editor.setContent("place your content here");
                    },
                  }}
                  id="pre_text"
                />
              </div>

              <label className="text-base text-[#94A3B8]">Enter pre-text</label>
            </div>

            <div className="flex flex-col items-start">
              <div className="w-full">
                <Editor
                  tinymceScriptSrc={
                    "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                    "/tinymce/tinymce.min.js"
                  }
                  onInit={(goalOverview) => {
                    editorRefs.example.current = goalOverview;
                  }}
                  initialValue={questionEditMode ? questionValues?.example : ""}
                  init={{
                    height: 350,
                    menubar: true,
                    plugins,
                    toolbar,
                    content_style,
                    setup: function (editor) {
                      editor.setContent("place your content here");
                    },
                  }}
                  id="example"
                />
              </div>
              <label className="text-base text-[#94A3B8]">
                Enter example(optional)
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-10 mt-5">
        <Button
          btnClick={questionEditMode ? handleQuestionUpdate : handleCreate}
          btnDisabled={loading}
          btnText={
            loading ? (
              <Shimmer height={20} color={"#fff"} />
            ) : questionEditMode ? (
              "Update Question"
            ) : (
              "Add Question"
            )
          }
          btnStyle={loading ? "bg-inactiveText" : "bg-primary text-mainWhite"}
        />
        <Button
          btnClick={() => window.location.reload()}
          btnText={"Cancel"}
          btnStyle={"bg-inactiveText text-mainWhite"}
        />
      </div>
    </div>
  );
};

export default GoalQuestionForm;

export const GoalForm = ({
  editMode,
  handleUpdate,
  handleCreate,
  loading,
  formState,
  handleChange,
  editorRefs,
}) => {
  return (
    <div className="w-full py-2 bg-white shadow-custom rounded-xl">
      <div className="w-[95%] mx-auto">
        <h1 className="text-lg font-medium text-primary ">
          {editMode ? "Update" : "Add"} Goal
        </h1>
      </div>

      <form>
        <div className="w-[95%] mx-auto flex items-start gap-5">
          <div className="flex flex-col items-start w-full">
            <h3 className="capitalize">category</h3>
            <div className="flex flex-col items-start w-full">
              <input
                type="text"
                className="border-[2px] pl-3 h-[30px] my-1 w-[80%] rounded-xl"
                name="category"
                id="category"
                value={formState?.category}
                onChange={(e) => handleChange(e)}
              />
              <label className="text-[#94A3B8]  capitalize text-sm">
                Enter category name
              </label>
            </div>
          </div>

          <div className="flex flex-col items-start w-full">
            <h3 className="capitalize">
              goal setting overview{" "}
              <span className="text-[#94A3B8]  capitalize text-sm">
                Enter setting overview
              </span>
            </h3>
            <div className="w-full">
              <Editor
                tinymceScriptSrc={
                  "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                  "/tinymce/tinymce.min.js"
                }
                onInit={(goalOverview) => {
                  editorRefs.goal_setting_overview.current = goalOverview;
                }}
                initialValue={editMode ? formState?.goal_setting_overview : ""}
                init={{
                  height: 350,
                  menubar: true,
                  plugins,
                  toolbar,
                  content_style,
                  setup: function (editor) {
                    editor.setContent("place your content here");
                  },
                }}
                id="goal_setting_overview"
              />
            </div>
          </div>
        </div>

        <div className="w-[95%] mx-auto mt-10 ">
          <div className="flex items-center gap-10 my-7">
            <button
              className={`px-4 py-[6px] text-base text-white rounded-xl ${
                loading ? "bg-[#898D9E]" : "bg-primary"
              }`}
              onClick={editMode ? handleUpdate : handleCreate}
              disabled={loading}
            >
              {loading ? (
                <Shimmer height={20} color={"#fff"} />
              ) : editMode ? (
                "Update Goal"
              ) : (
                "Add Goal"
              )}
            </button>

            <button
              className="bg-[#898D9E] px-5 capitalize py-[4px] rounded-xl text-white text-base"
              onClick={() => window.location.reload()}
            >
              cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const GoalModal = ({
  category,
  plan_questions,
  reflections,
  updateItem,
  closeModal,
  deleteItem,
  publishGoal,
  goalQuestion,
  loading,
  goalReflection,
  codeActionOpen,
  deleteReflection,
  updateReflection,
  cancelAction,
  openAction,

  openAction2,
  cancelAction2,
  codeActionOpen2,
  updateQuestion,
  deleteQuestion,
  selectedItem,
}) => {
  const thStyle = "text-center px-2 capitalize";
  const tdStyle =
    "text-primary text-[15px] font-semibold px-3 py-1 text-center border-r-[1px]";

  return (
    <div
      className={`w-[95%] bg-white py-20 flex flex-col items-center justify-center`}
    >
      <div className="my-5">
        <div dangerouslySetInnerHTML={{ __html: reflections?.[0]?.pre_text }} />
      </div>
      <div className="px-5">
        <h1 className="text-center text-2xl font-bold">{category}</h1>

        {/* form reflection */}
        <div className="my-10 flex items-center justify-center flex-col">
          <h1 className="text-lg font-bold mb-1">Reflections</h1>

          {reflections?.length > 0 ? (
            <table className="border-2 border-primary p-2">
              <thead>
                <tr className="border-b-2 bg-secondary">
                  <th className={thStyle}>S/N</th>
                  <th className={thStyle}>title</th>
                  <th className={thStyle}>type</th>
                  <th className={thStyle}>pre text</th>
                  <th className={thStyle}>post text</th>
                  <th className={thStyle}>updated at</th>
                  <th className={thStyle}>Action</th>
                </tr>
              </thead>
              <tbody>
                {reflections?.map((eachReflection, index) => (
                  <tr className="">
                    <td className={`${tdStyle}`}>{index + 1}</td>
                    <td className={`${tdStyle}`}>{eachReflection.title}</td>
                    <td className={`${tdStyle}`}>{eachReflection.type}</td>
                    <td className={`${tdStyle}`}>
                      {removeHtmlTags(eachReflection.pre_text)}
                    </td>
                    <td className={`${tdStyle}`}>
                      {removeHtmlTags(eachReflection.post_text)}
                    </td>
                    <td className={`${tdStyle}`}>
                      {new Date(eachReflection.updated_at).toDateString()}
                    </td>
                    <td className={`${tdStyle} hover:bg-secondary relative`}>
                      <p
                        className="flex items-center justify-center cursor-pointer"
                        onClick={() => openAction(eachReflection)}
                      >
                        <img src={Dot} alt="" />
                      </p>

                      <div
                        className={`${
                          codeActionOpen?.[eachReflection?.id] ? "" : "hidden"
                        } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
                      >
                        <p
                          className="hover-effect text-xs cursor-pointer text-[#0F2851]"
                          onClick={cancelAction}
                        >
                          Close
                        </p>

                        <p
                          className="hover-effect text-xs cursor-pointer text-[#207384]"
                          onClick={updateReflection}
                        >
                          Update
                        </p>

                        <p
                          className="hover-effect text-xs cursor-pointer text-[#FF0101]"
                          onClick={deleteReflection}
                        >
                          {loading ? (
                            <Shimmer color={"#898D9E"} height={10} />
                          ) : (
                            "Delete"
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>This Goal has no reflection</p>
          )}
        </div>

        {/* Table fpr questions */}
        <div className="my-10 flex items-center justify-center flex-col">
          <h1 className="text-lg font-bold mb-1">Plan Question</h1>
          {plan_questions?.length > 0 ? (
            <table className="border-2 border-primary p-2">
              <thead>
                <tr className="border-b-2 bg-secondary">
                  <th className={thStyle}>S/N</th>
                  <th className={thStyle}>title</th>
                  <th className={thStyle}>example</th>
                  <th className={thStyle}>pre text</th>
                  <th className={thStyle}>weekly plan</th>
                  <th className={thStyle}>updated at</th>
                  <th className={thStyle}>Action</th>
                </tr>
              </thead>
              <tbody>
                {plan_questions?.map((eachQuestion, index) => (
                  <tr className="">
                    <td className={`${tdStyle}`}>{index + 1}</td>
                    <td className={`${tdStyle}`}>{eachQuestion.title}</td>
                    <td className={`${tdStyle}`}>
                      {removeHtmlTags(eachQuestion.example)}
                    </td>
                    <td className={`${tdStyle}`}>
                      {removeHtmlTags(eachQuestion.pre_text)}
                    </td>
                    <td className={`${tdStyle}`}>
                      {eachQuestion.weekly_plan == "1" ? "True" : "False"}
                    </td>
                    <td className={`${tdStyle}`}>
                      {new Date(eachQuestion.updated_at).toDateString()}
                    </td>
                    <td className={`${tdStyle} hover:bg-secondary relative`}>
                      <p
                        className="flex items-center justify-center cursor-pointer"
                        onClick={() => openAction2(eachQuestion)}
                      >
                        <img src={Dot} alt="" />
                      </p>

                      <div
                        className={`${
                          codeActionOpen2?.[eachQuestion?.id] ? "" : "hidden"
                        } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
                      >
                        <p
                          className="hover-effect text-xs cursor-pointer text-[#0F2851]"
                          onClick={cancelAction2}
                        >
                          Close
                        </p>

                        <p
                          className="hover-effect text-xs cursor-pointer text-[#207384]"
                          onClick={updateQuestion}
                        >
                          Update
                        </p>

                        <p
                          className="hover-effect text-xs cursor-pointer text-[#FF0101]"
                          onClick={deleteQuestion}
                        >
                          {loading ? (
                            <Shimmer color={"#898D9E"} height={10} />
                          ) : (
                            "Delete"
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>This Goal has no Plan Question</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center gap-5 mt-10 w-full">
        <Button
          btnText="Upate"
          btnStyle="bg-primary"
          btnClick={updateItem} // the update function will be here
        />

        <Button
          btnText="Add Reflection"
          btnStyle="bg-[#78B7C5]"
          btnClick={goalReflection}
        />

        <Button
          btnText=" Add Question"
          btnStyle="bg-[#78B7C5]"
          btnClick={goalQuestion}
        />
      </div>

      <div className="flex items-center justify-center mt-10 gap-5">
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={closeModal}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteItem}
        />
        <Button
          btnDisabled={loading}
          btnText={
            loading ? (
              <Shimmer height={20} color={"#fff"} />
            ) : selectedItem?.published == "1" ? (
              "Unpublish"
            ) : (
              "Publish"
            )
          }
          btnStyle={loading ? "bg-inactiveText" : "bg-[#78B7C5]"}
          btnClick={publishGoal}
        />
      </div>
    </div>
  );
};

export const GoalReflectionForm = ({
  reflectionEditMode,
  reflectionValues,
  reflectionChnage,
  handleCreate,
  handleReflectionUpdate,
  loading,
  editorRefs,
}) => {
  const specialId = "pre_text";
  return (
    <div className="w-full rounded-lg bg-mainWhite shadow-lg px-5 py-10">
      <h1 className="">
        {reflectionEditMode ? "Update Reflection" : "Add Reflection"}
      </h1>
      <div className="mt-10">
        <div className="">
          <h3>Reflection</h3>

          <div className="grid grid-cols-2 gap-3">
            <div className="flex flex-col items-start">
              <input
                name="title"
                id="title"
                value={reflectionValues.title}
                onChange={(e) => reflectionChnage(e)}
                type="text"
                className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
              />
              <label className="text-base text-[#94A3B8]">Enter title</label>
            </div>

            <div className="flex flex-col items-start">
              <select
                name="type"
                id="type"
                value={reflectionValues.type}
                onChange={(e) => reflectionChnage(e)}
                className="border-[2px] my-1 px-3 py-1 w-[100%] rounded-xl"
              >
                <option value="range">Range</option>
                <option value="text">Text</option>
              </select>
              <label className="text-base text-[#94A3B8]">Type</label>
            </div>

            <div className="flex flex-col items-start">
              <label className="text-base text-[#94A3B8]">Enter pre-text</label>

              <Editor
                tinymceScriptSrc={
                  "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                  "/tinymce/tinymce.min.js"
                }
                onInit={(reflectionPreText) => {
                  editorRefs.pre_text.current = reflectionPreText;
                }}
                initialValue={
                  reflectionEditMode ? reflectionValues.pre_text : ""
                }
                init={{
                  height: 250,
                  menubar: true,
                  plugins,
                  toolbar,
                  content_style,
                  setup: function (editor) {
                    editor.setContent("place your content here");
                  },
                }}
                // id="pre_text" I removed the ID, it makes the two edito have conflicts
              />
            </div>

            <div className="flex flex-col items-start">
              <label className="text-base text-[#94A3B8]">
                Enter post_text
              </label>

              <Editor
                tinymceScriptSrc={
                  "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                  "/tinymce/tinymce.min.js"
                }
                onInit={(reflectionPostText) => {
                  editorRefs.post_text.current = reflectionPostText;
                }}
                initialValue={
                  reflectionEditMode ? reflectionValues.post_text : ""
                }
                init={{
                  height: 250,
                  menubar: true,
                  plugins,
                  toolbar,
                  content_style,
                  setup: function (editor) {
                    editor.setContent("place your content here");
                  },
                }}
                id="post_text"
              />
            </div>

            <div className="flex gap-3 items-center">
              <div className="flex flex-col items-start">
                <input
                  name="min"
                  id="min"
                  value={reflectionValues.min}
                  onChange={(e) => reflectionChnage(e)}
                  type="number"
                  className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                />
                <label className="text-base text-[#94A3B8]">Minimum</label>
              </div>

              <div className="flex flex-col items-start">
                <input
                  name="max"
                  id="max"
                  value={reflectionValues.max}
                  onChange={(e) => reflectionChnage(e)}
                  type="number"
                  className="border-[2px] my-1 px-3 w-[100%] rounded-xl"
                />
                <label className="text-base text-[#94A3B8]">Maximum</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-10 mt-10">
        <Button
          btnClick={reflectionEditMode ? handleReflectionUpdate : handleCreate}
          btnDisabled={loading}
          btnText={
            loading ? (
              <Shimmer height={20} color={"#fff"} />
            ) : reflectionEditMode ? (
              "Update Reflection"
            ) : (
              "Add Reflection"
            )
          }
          btnStyle={loading ? "bg-inactiveText" : "bg-primary text-mainWhite"}
        />
        <Button
          btnClick={() => window.location.reload()}
          btnText={"Cancel"}
          btnStyle={"bg-inactiveText text-mainWhite"}
        />
      </div>
    </div>
  );
};
