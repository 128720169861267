import React from "react";
import { SearchIcon } from "../Assets";

const ResourceListHead = ({ resourceListType, placeholder }) => {
  return (
    <div className="border-inactiveText py-3 px-10 bg-mainWhite flex items-center justify-between rounded-t-xl">
      <h1 className="text-primary text-lg">{resourceListType}</h1>

      <div className="w-[40%] bg-tranparent py-1 px-5 flex items-center gap-3 border-2 border-inactiveText rounded-lg">
        <img src={SearchIcon} alt="searchIcon" />
        <input
          type="text"
          placeholder={placeholder}
          className="bg-transparent w-[100%]"
        />
      </div>
    </div>
  );
};

export default ResourceListHead;
