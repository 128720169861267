import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");

// // // // // // UPDATE my profile // // // // // //
export const UpdateProfile = createAsyncThunk(
  "update/adminUser",
  async (payload) => {
    try {
      const config = {
        method: "PUT",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(`${BASE_URL}/admin/me`, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
