import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import StatusCode from "../Ultilities/StatusCode";

const initialState = {
  status: StatusCode.IDLE,
  data: [],
  error: null,
};

const BASE_URL = "https://psychinsightsapp.net/api";
const userToken = localStorage.getItem("userToken");
export const fetchDassOption = createAsyncThunk("get", async () => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/dass-question-options`,
      config
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const dassOptionSlice = createSlice({
  name: "dassOption",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDassOption.pending, (state) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchDassOption.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(fetchDassOption.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default dassOptionSlice.reducer;
