import React, { useEffect } from "react";
import { fetchCategory } from "../Features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import InputCat from "./InputCat";
import Shimmer from "./Shimmer";
import Button from "./Button";
import { MinusIcon, PlusIcon } from "../Assets";

const AddBasicQuest = ({
  categories,
  editMode,
  formState,
  handleChange,
  handleCheckboxChange,
  optionValues,
  optionChange,
  increase,
  decrease,
  handleUpdate,
  handleCreate,
  loading,
  addOptionField,
  removeOptionField,
}) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.category.data);
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  return (
    <div className="w-full py-2 bg-white shadow-custom rounded-xl">
      <div className="w-[95%] mx-auto">
        <h1 className="text-lg font-medium text-primary ">
          {editMode ? "Update Basic Question" : "Add Basic Question"}
        </h1>
      </div>

      <div className="w-[95%] mx-auto flex items-start gap-5">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col items-start w-full">
            <h3 className="capitalize">question</h3>
            <div className="flex flex-col items-start w-full">
              <input
                id="question"
                name="question"
                value={formState?.question}
                onChange={(e) => handleChange(e)}
                type="text"
                className="border-[2px] pl-3 h-[30px] my-1 w-[80%] rounded-xl"
              />
              <label className="text-[#94A3B8]  capitalize text-sm">
                enter question
              </label>
            </div>
          </div>

          <div className=" flex items-center gap-10">
            <div className="flex flex-col flex-right items-start bg-red mt-5">
              <label htmlFor="is_k10">Is k10</label>

              <select
                name="is_k10"
                id="is_k10"
                value={formState?.is_k10}
                onChange={(e) => handleChange(e)}
                className="border-[2px] w-[150px]"
              >
                <option value="0">NO</option>
                <option value="1">YES</option>
              </select>
            </div>

            <div className="flex flex-col items-start bg-red mt-5">
              <label htmlFor="special_options">Special Options</label>

              <select
                name="special_options"
                id="special_options"
                value={formState?.special_options}
                onChange={(e) => handleChange(e)}
                className="border-[2px] w-[150px]"
              >
                <option value="0">NO</option>
                <option value="1">YES</option>
              </select>
            </div>
          </div>
        </div>

        <div className="w-full">
          <h3 className="capitalize">Category</h3>
          <p className="text-[#94A3B8]  capitalize text-sm my-2">
            Check associated categories
          </p>
          <div className="grid grid-cols-2 ">
            {Data.map((category) => (
              <InputCat
                key={category.id}
                {...category}
                checked={categories?.includes(category.id)}
                onChange={() => handleCheckboxChange(category.id)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-[95%] mx-auto mt-6 ">
        {formState?.special_options == "1" ? (
          <div>
            <div className="grid grid-cols-2 ">
              {optionValues?.map((_, index) => (
                <div className="w-[98%]" key={index}>
                  <h3>Option {index + 1}</h3>
                  <div className="flex items-center gap-1">
                    <div className="flex flex-col items-start w-[70%]">
                      <input
                        name="option"
                        id="option"
                        value={optionValues[index]?.option}
                        onChange={(e) => optionChange(e, index)}
                        type="text"
                        className="border-[2px] my-1 w-[100%] rounded-xl"
                      />
                      <label className="text-base text-[#94A3B8]">
                        Enter option
                      </label>
                    </div>

                    <div className="flex flex-col items-center w-[30%]">
                      <div className="border-[2px] rounded-xl flex items-center mb-1 justify-center gap-4 w-[70%]">
                        <button
                          dangerouslySetInnerHTML={{ __html: "&minus;" }}
                          className="px-1"
                          onClick={(e) => decrease(e, index)}
                        />
                        <input
                          name="value"
                          type="tel"
                          placeholder="value"
                          value={optionValues[index]?.value || "1"}
                          onChange={(e) => optionChange(e, index)}
                          className={`valueFeild-${index} w-full h-[25px] px-2 text-center border-x-2`}
                        />
                        <button
                          dangerouslySetInnerHTML={{ __html: "&plus;" }}
                          className="px-1"
                          onClick={(e) => increase(e, index)}
                        />
                      </div>
                      <p className="text-base text-[#94A3B8]">Enter value</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="my-5 flex items-center gap-5">
              {optionValues?.length < 4 ? (
                <Button
                  btnClick={addOptionField}
                  btnText="Option"
                  btnStyle="bg-[#78B7C5]"
                  btnIcon={PlusIcon}
                />
              ) : (
                ""
              )}
              {optionValues.length > 1 ? (
                <Button
                  btnClick={removeOptionField}
                  btnText="Option"
                  btnStyle="bg-[#898D9E] "
                  btnIcon={MinusIcon}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="flex items-center gap-10 my-7">
          <button
            className={`px-4 py-[6px] text-base text-white rounded-xl ${
              loading ? "bg-[#898D9E]" : "bg-primary"
            }`}
            onClick={editMode ? handleUpdate : handleCreate}
            disabled={loading}
          >
            {loading ? (
              <Shimmer height={20} color={"#fff"} />
            ) : editMode ? (
              "Update Question"
            ) : (
              "Add question"
            )}
          </button>

          <button
            className="bg-[#898D9E] px-5 capitalize py-[4px] rounded-xl text-white text-base"
            onClick={() => window.location.reload()}
          >
            cancle
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBasicQuest;
