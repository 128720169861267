import {
  Dashuser,
  ResourceCover,
  ArticleCover1,
  ArticleCover2,
  Profile,
  ArticleCover3,
  StrategyCover,
  StrategyCover2,
} from "../Assets";

export const ChartData = [
  {
    id: 1,
    year: "Mon",
    userGain: 80,
    userLost: 20,
  },
  {
    id: 2,
    year: "Tue",
    userGain: 77,
    userLost: 100,
  },
  {
    id: 3,
    year: "Wed",
    userGain: 88,
    userLost: 60,
  },
  {
    id: 4,
    year: "Thu",
    userGain: 90,
    userLost: 30,
  },
  {
    id: 5,
    year: "Fri",
    userGain: 40,
    userLost: 42,
  },
  {
    id: 6,
    year: "Sat",
    userGain: 10,
    userLost: 200,
  },
  {
    id: 7,
    year: "Sun",
    userGain: 55,
    userLost: 107,
  },
];

export const BookResource = [
  {
    id: 1,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 2,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ArticleCover2,
    plan: "Basic",
    level: "Level 3",
  },
  {
    id: 3,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 4,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 5,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 6,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 7,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 8,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 9,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 10,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
];
export const Top3Book = BookResource.filter((book) => book.id <= 3);

export const ArticleResource = [
  {
    id: 1,
    title: "The reward for hapiness",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Focus",
    coverImg: ArticleCover1,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 2,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ArticleCover2,
    plan: "Basic",
    level: "Level 3",
  },
  {
    id: 3,
    title: "Eat good, Feel good",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety",
    coverImg: ArticleCover3,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 4,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 5,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 6,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 7,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 8,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 9,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: " 1",
  },
  {
    id: 10,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
];

export const Top3Articles = ArticleResource.filter(
  (article) => article.id <= 3
);

export const PodcastResource = [
  {
    id: 1,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ArticleCover2,
    plan: "Basic",
    level: "Level 3",
  },
  {
    id: 2,
    title: "The reward for hapiness",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Focus",
    coverImg: ArticleCover1,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 3,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 4,
    title: "Eat good, Feel good",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety",
    coverImg: ArticleCover3,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 5,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 6,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
  {
    id: 7,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 8,
    title: "Company of One",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 9,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
    level: "Level 1",
  },
  {
    id: 10,
    title: "Psychology of money",
    author: "Kathryn Murphy",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Basic",
    level: "Level 1",
  },
];

export const Notification = [
  {
    id: 1,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "20 seconds ago",
    color: "#fff",
    name: "madison mason",
  },
  {
    id: 2,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "2 minutes ago",
    color: "#e0f6f7",
    name: "jane cooper",
  },
  {
    id: 3,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "5 minutes ago",
    color: "#fff",
    name: "robert fox",
  },
  {
    id: 4,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "1 day ago",
    color: "#e0f6f7",
    name: "madison mason",
  },
  {
    id: 5,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "2 minutes ago",
    color: "#fff",
    name: "cooper jane",
  },
  {
    id: 6,
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam?",
    time: "2 minutes ago",
    color: "#e0f6f7",
    name: "jacob jane",
  },
];

export const Top3Podcast = PodcastResource.filter((podcast) => podcast.id <= 3);

export const RecentSub = [
  {
    id: 1,
    name: "Dennis macron",
    date: "Feb 24, 2:00pm",
    price: 103.99,
    plan: "/annual",
    icon: Profile,
  },
  {
    id: 2,
    name: "Dennis macron",
    date: "Feb 24, 2:00pm",
    price: 103.99,
    plan: "/annual",
    icon: Profile,
  },
  {
    id: 3,
    name: "Dennis macron",
    date: "Feb 24, 2:00pm",
    price: 103.99,
    plan: "/annual",
    icon: Profile,
  },
  {
    id: 4,
    name: "Dennis macron",
    date: "Feb 24, 4:00pm",
    price: 160.99,
    plan: "/annual",
    icon: Profile,
  },
  {
    id: 5,
    name: "Dennis macron",
    date: "Feb 24, 2:00pm",
    price: 293.99,
    plan: "/annual",
    icon: Profile,
  },
  {
    id: 6,
    name: "Dennis macron",
    date: "Feb 24, 3:40pm",
    price: 192.99,
    plan: "/annual",
    icon: Profile,
  },
];

export const Strategies = [
  {
    id: 1,
    title: "The reward for hapiness",
    releaseDate: "4 June 2023",
    category: "Stress/Anxiety",
    coverImg: StrategyCover,
    plan: "Basic",
  },
  {
    id: 2,
    title: "Eat good, Feel good",

    releaseDate: "4 August 2023",
    category: "Focus",
    coverImg: StrategyCover2,
    plan: "Premium",
  },
  {
    id: 3,
    title: "The intense reflection",

    releaseDate: "12 May 2023",
    category: "Depression",
    coverImg: Profile,
    plan: "Premium",
  },
  {
    id: 4,
    title: "Psychology of money",

    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: Dashuser,
    plan: "Premium",
  },
  {
    id: 5,
    title: "Company of One",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
  },
  {
    id: 6,
    title: "Psychology of money",
    releaseDate: "4 August 2023",
    category: "Anxiety/Stress",
    coverImg: ResourceCover,
    plan: "Basic",
  },
  {
    id: 7,
    title: "Psychology of money",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
  },
  {
    id: 8,
    title: "Company of One",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Premium",
  },
  {
    id: 9,
    title: "Psychology of money",
    releaseDate: "4 August 2023",
    category: "Depression",
    coverImg: ResourceCover,
    plan: "Premium",
  },
  {
    id: 10,
    title: "Psychology of money",
    releaseDate: "4 August 2023",
    category: "Motivation",
    coverImg: ResourceCover,
    plan: "Basic",
  },
];
