import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const Profile = () => {
  const adminUserData = useSelector((state) => state.auth.userInfo.data);
  return (
    <div>
      <div className="flex items-start gap-7 w-full">
        <div className="w-[20%] bg-white shadow-custom rounded-xl h-[80vh]">
          <div className="w-[80%] mx-auto mt-2">
            <h2 className="text-xl font-semibold capitalize">Profile</h2>
          </div>
          <hr className="w-full my-3" />
          <div className="w-[80%] mx-auto">
            <div className="flex relative flex-col items-center justify-center">
              <div className="bg-[#446193] relative rounded-full flex items-center justify-center w-[80px] h-[80px]">
                <h2 className="uppercase font-semibold text-white text-3xl">
                  {adminUserData.name.slice(0, 1)}
                </h2>
              </div>

              <div className="mt-3 flex flex-col items-center justify-center">
                <h2 className="text-base font-semibold">Afolarin Toyib</h2>
                <p className="mt-[-3px] text-[#94A3B8] capitalize text-sm font-medium ">
                  super admin
                </p>
              </div>
            </div>
            <div className="mt-9 w-full flex flex-col">
              <NavLink
                className="mb-2 capitalize text-base font-semibold"
                to=""
                end
              >
                profile setting
              </NavLink>
              <NavLink
                className="mb-2 capitalize text-base font-semibold"
                to="notification-setting"
              >
                notification setting
              </NavLink>
              <NavLink
                className="mb-2 capitalize text-base font-semibold"
                to="password-setting"
              >
                {" "}
                password setting
              </NavLink>
            </div>
          </div>
        </div>
        <div className="w-[80%] bg-white shadow-custom rounded-xl h-[80vh]">
          <hr className="w-full mt-[46px] mb-4" />
          <div className="w-[94%] mx-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
