import React, { useState } from "react";
import { Dot } from "../Assets";
import StatusCode from "../Ultilities/StatusCode";
import Shimmer from "./Shimmer";
import ReactPagination from "./ReactPagination";
import { fetchPromoCode } from "../Features/PromoCodeSlice.js";
import { useDispatch } from "react-redux";
import { showToast } from "./BlogCategories.jsx";
import {
  activatePromoCode,
  deletePromoCode,
} from "../Features/PostPromoCodeSlice.js";

const SubTable = ({ datas, status, toEditMode, setTheSelected }) => {
  const dispatch = useDispatch();
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const [codeActionOpen, setCodeActionOpen] = useState(false);
  const [selectedCode, setSelectedCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const action = (item) => {
    setCodeActionOpen((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
    setSelectedCode(item);
    setTheSelected(item);
  };

  //   Delete
  const handleDelete = () => {
    setLoading(true);
    dispatch(deletePromoCode({ id: selectedCode.id }))
      .unwrap()
      .then(() => {
        showToast("Promo code deleted successfully.");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  //   Activate / deactivate
  const handleActivate = () => {
    setLoading(true);
    dispatch(activatePromoCode({ id: selectedCode.id }))
      .unwrap()
      .then(() => {
        if (selectedCode.status == "1") {
          showToast("Promo code deactivated successfully.");
        } else showToast("Promo code activated successfully.");
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2100)
      )
      .catch((error) => alert("error deleting post: ", error));
  };

  return (
    <div className="w-[70%] rounded-lg bg-white relative">
      <table className="table  divide-y  divide-gray-200 w-full">
        <thead className="bg-[#ECECEC]">
          <tr>
            <th className="text-primary ">S/N</th>
            <th className="text-primary ">Promo code</th>
            <th className="text-primary ">Redemption(Q)</th>
            <th className="text-primary ">Total limit</th>
            <th className="text-primary ">Discount</th>
          </tr>
        </thead>

        {status === StatusCode.LOADING ? (
          <div className=" absolute top-10 left-0 right-0 bottom-0">
            <Shimmer height={30} color={"#207384"} />
          </div>
        ) : (
          <tbody>
            {datas?.data?.map((promoCode, index) => (
              <tr className="text-center" key={promoCode.id}>
                <td className="py-2 ">{lastSerialNumber + index + 1}</td>
                <td className="py-2 ">{promoCode?.promo_code}</td>
                <td className="py-2 ">{promoCode?.usage_limit}</td>
                <td className="py-2 ">{promoCode?.total_limit}</td>
                <td className="py-2 relative flex items-center justify-center gap-9">
                  {promoCode?.percentage_off}%
                  <img
                    src={Dot}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => action(promoCode)}
                  />
                  <div
                    className={`${
                      codeActionOpen?.[promoCode?.id] ? "" : "hidden"
                    } py-2 px-3 bg-mainWhite flex flex-col gap-3 absolute right-0 shadow-lg z-10`}
                  >
                    <p
                      className="text-xs cursor-pointer text-[#0F2851]"
                      onClick={() => action(promoCode)}
                    >
                      Close
                    </p>
                    <p
                      className="text-xs cursor-pointer text-[#FF0101]"
                      onClick={handleDelete}
                    >
                      {loading ? (
                        <Shimmer color={"#898D9E"} height={10} />
                      ) : (
                        "Delete"
                      )}
                    </p>
                    <p
                      className="text-xs cursor-pointer text-[#207384]"
                      onClick={toEditMode}
                    >
                      Update
                    </p>
                    <p
                      className="text-xs cursor-pointer text-[#207384]"
                      onClick={handleActivate}
                    >
                      {loading ? (
                        <Shimmer color={"#898D9E"} height={10} />
                      ) : selectedCode?.status == "1" ? (
                        "Deactivate"
                      ) : (
                        "Activate"
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <div className="flex justify-end items-end w-full px-5 pb-3">
        <ReactPagination
          total={datas?.last_page}
          onChanges={(i) => {
            dispatch(fetchPromoCode(i?.selected + 1));
            if (i.selected === 0) {
              setLastSerialNumber(0);
            } else {
              setLastSerialNumber(datas?.data.length * datas?.current_page);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SubTable;
