import React, { useEffect, useRef, useState } from "react";
import BoardData from "./BoardData";
import { EyeIcon, PlusIcon, PodcastIcon, TagIcon } from "../Assets";
import Button from "./Button";
import ResourceList from "./ResourceList";
import ResourceListHead from "./ResourceListHead";
import TableMain from "./TableMain";
import TableRow from "./TableRow";
import ReactPagination from "./ReactPagination";
import { useDispatch, useSelector } from "react-redux";
import { getPodcasts } from "../Features/PodcastSlice";
import { updatePodcast } from "../Features/PodcastPostSlice";
import { podcastSummary } from "../Features/PodcastSummary";
import { deletePodcast } from "../Features/PodcastDeleteSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddNewPodcast } from "../Features/CreatePodcastSlice";
import Shimmer from "./Shimmer";
import StatusCode from "../Ultilities/StatusCode";
import { fetchCategory } from "../Features/CategorySlice";
import { fetchSubPlan } from "../Features/SubPlanSlice";
import InputCat from "./InputCat";
import { Editor } from "@tinymce/tinymce-react";
const plugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "Link Checker",
  "Page Embed",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "preview",
  "help",
  "wordcount",
  "fontselect",
  "fontsize",
];
const toolbar =
  "undo redo | formatselect | fontselect | fontsizeselect | " +
  "bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | " +
  "bullist numlist outdent indent | removeformat | link image | table media | " +
  "charmap anchor searchreplace visualblocks code fullscreen insertdatetime | " +
  "preview help wordcount";

const content_style =
  "body { font-family: Times New Roman, Arial, sans-serif; font-size: 25px }";

const Podcast = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [podcastForm, setPodcastForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formState, setFormState] = useState({
    title: "",
    author: "",
    episode: "",
    release_date: "",
    subscription_level: 0,
    podcast_link: "",
  });
  const editorRefs = {
    summary: useRef(null),
  };
  const resourceInfo = (item) => {
    setModalIsOpen(true);
    setSelectedItem(item);
  };
  const closeResourceInfo = () => {
    setModalIsOpen(false);
  };

  // Fetch podcasts
  const dispatch = useDispatch();
  const podcastData = useSelector((state) => state.podcasts?.podcastsData);

  useEffect(() => {
    dispatch(getPodcasts(1));
  }, [dispatch]);
  const [lastSerialNumber, setLastSerialNumber] = useState(0);
  const allPodcasts = podcastData?.data?.map((podcast, index) => (
    <TableRow
      key={podcast.id}
      {...podcast}
      index={lastSerialNumber + index + 1}
      publication_date={new Date(podcast.updated_at).toDateString()}
      tableRowclick={() => resourceInfo(podcast)}
    />
  ));

  // Update podcast
  const categoriesArray = useSelector((state) => state.category.data);
  useEffect(() => {
    if (!editMode) return;
    setFormState(selectedItem);
    selectedItem?.categories.forEach((categoryName) => {
      const category = categoriesArray.find(
        (cat) => cat?.category === categoryName
      );
      if (category) {
        setCategories((prev) => [...prev, category.id]);
      }
    });
  }, [editMode, selectedItem, categoriesArray]);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    setPodcastForm((prev) => !prev);
    setModalIsOpen((prev) => !prev);
  };
  const updatePodcastResource = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const updateData = {
      id: selectedItem.id,
      title: formData.get("title"),
      author: formData.get("author"),
      summary: editorRefs.summary?.current.target.getContent(),
      episode: formData.get("episode"),
      release_date: formData.get("release_date"),
      podcast_link: formData.get("podcast_link"),
      subscription_level: formData.get("subscription_level"),
    };
    const payLoad = { ...updateData, categories };
    selectedFile && formData.append("cover_art", selectedFile);
    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (selectedItem.id !== null) {
      const id = selectedItem.id;
      const data = { id, formData };
      setLoading(true);
      dispatch(updatePodcast(data))
        .unwrap()
        .then(() => {
          showToast(`Poscast Updated successfully`);
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => {
          alert("Error creating post", error);
        });
    }
  };

  // Add podcast
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({ ...prevData, [id]: value }));
  };
  const openAddForm = (e) => {
    e.preventDefault();
    setPodcastForm(!podcastForm);
  };
  const openCloseAddForm = (e) => {
    e.preventDefault();
    setPodcastForm(!podcastForm);
    window.location.reload();
  };
  const handleCheckboxChange = (value) => {
    setCategories((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const validateForm = (formData) => {
    for (const key in formData) {
      if (key === "episode") {
        continue;
      }
      if (
        formData[key] === "" ||
        formData[key] === null ||
        formData[key].length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  const createNewPodcast = (e) => {
    e.preventDefault();
    const payLoad = {
      ...formState,
      summary: editorRefs.summary?.current.target.getContent(),
      categories,
    };
    const formData = new FormData();
    formData.append("cover_art", selectedFile);
    if (!validateForm(payLoad)) {
      alert("Please fill in all required fields before submitting.");
      return;
    } else if (!selectedFile) {
      return alert("please selecte an image file");
    }

    Object.entries(payLoad).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(`${key}[]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    setLoading(true);
    dispatch(AddNewPodcast(formData))
      .unwrap()
      .then(() => {
        setPodcastForm(!podcastForm);
        showToast(
          `A new Podcast titiled ${formState.title} has been added successfully, reload to get updated data`
        );
        setLoading(false);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 2100)
      )
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  };

  // delete  podcast
  const handleDelete = (e) => {
    e.preventDefault();
    const deleteIt = {
      id: selectedItem ? selectedItem.id : null,
    };

    if (deleteIt.id !== null) {
      setLoading(true);
      dispatch(deletePodcast({ id: deleteIt.id }))
        .unwrap()
        .then(() => {
          showToast("Podcast Deleted successfully, reload to update data list");
          setLoading(false);
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 2100)
        )
        .catch((error) => alert("error deleting post: ", error));
    }
  };

  // Get summary
  const podcastSummaryData = useSelector(
    (state) => state.podcastSummary.podcastSummary
  );
  useEffect(() => {
    dispatch(podcastSummary());
  }, [dispatch]);
  const popularPodcast = podcastSummaryData.popular_podcastss;

  // Toastify
  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
    });
  };
  return (
    <div>
      <div className={`${podcastForm ? "" : "hidden"}`}>
        <PodcastForm
          cancelAction={openCloseAddForm}
          createPodcast={createNewPodcast}
          formState={formState}
          handleChange={handleChange}
          setCategories={setCategories}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          editMode={editMode}
          selectedItem={selectedItem}
          categories={categories}
          updatePodcastAction={updatePodcastResource}
          loading={loading}
          editorRefs={editorRefs}
        />
      </div>

      <div
        className={`relative flex flex-col gap-4 ${
          podcastForm ? "hidden" : ""
        }`}
      >
        <div className={`${modalIsOpen ? "modalfloat" : "hidden"}`}>
          <div className={`w-[60%]`}>
            {selectedItem && (
              <Modal
                itemMedia={selectedItem.photo?.url}
                item={selectedItem}
                cancelAction={closeResourceInfo}
                deleteitem={handleDelete}
                updateAction={toggleEditMode}
                loading={loading}
              />
            )}
          </div>
        </div>

        <div className="flex gap-5">
          <BoardData
            resourceIcon={PodcastIcon}
            resourcesIconAlt="PodcastIcon"
            resourceTotal={podcastSummaryData.total_podcasts}
            resourceName="Total Podcasts"
            textStyle="text-[#0D5362]"
          />

          <BoardData
            resourceIcon={EyeIcon}
            resourcesIconAlt="EyeIcon"
            resourceTotal={podcastSummaryData.total_views}
            resourceName="Total Listens"
            textStyle="text-[#0F2851]"
          />

          <div className="ml-auto">
            <Button
              btnClick={openAddForm}
              btnIcon={PlusIcon}
              btnText="Add new podcast"
              btnStyle="bg-primary"
            />
          </div>
        </div>

        <div className="topThreeResource mt-3 bg-mainWhite rounded-lg shadow-lg">
          <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl">
            <h1 className="text-primary text-lg">Popular podcasts</h1>
          </div>

          <div className="px-5 py-3 flex flex-col gap-3">
            {popularPodcast?.map((podcast) => (
              <div key={podcast.id}>
                <ResourceList
                  {...podcast}
                  photo={podcast?.photo?.url}
                  publication_date={new Date(podcast.updated_at).toDateString()}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="listOfAllBooks mt-3 bg-mainWhite rounded-xl shadow-lg">
          <ResourceListHead resourceListType="Podcasts" />
          <TableMain
            resourceType="Podcasts"
            subLevel="Sub Level"
            TableRows={allPodcasts}
          />

          <div className="flex justify-end items-end w-full px-5 pb-3">
            <ReactPagination
              total={podcastData?.last_page}
              onChanges={(i) => {
                dispatch(getPodcasts(i?.selected + 1));
                if (i.selected === 0) {
                  setLastSerialNumber(0);
                } else {
                  setLastSerialNumber(
                    allPodcasts.length * podcastData?.current_page
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Podcast;

const Modal = ({
  item,
  updateAction,
  cancelAction,
  deleteitem,
  ifContentStyle,
  itemMedia,
  loading,
}) => {
  return (
    <div
      className={`modal bg-mainWhite w-full flex flex-col items-center px-5 py-12 rounded-lg shadow-lg  ${ifContentStyle} `}
    >
      <div className="w-[50%] flex items-center justify-center">
        <img src={itemMedia} alt="" className="w-full" />
      </div>

      <h1 className="font-bold text-lg text-center">{item.title}</h1>

      <div className="text-inactiveTest text-sm flex items-start gap-2">
        <p>{item.author}</p>

        <li>{item.release_date}</li>

        <p className="flex">
          <span className=" w-[20px] flex items-center justify-center ml-2">
            <img src={TagIcon} alt="tag-Icon" className="" />
          </span>

          <span>{item.categories}</span>
        </p>
      </div>

      <div className="text-center w-[85%] mx-auto my-5 text-xs">
        <p className="description text-center">{item.summary}</p>
        <div className="text-inactiveTest flex items-start justify-center gap-5 mt-2">
          <p>{new Date(item.updated_at).toDateString()}</p>

          <p>{item.subscription_level}</p>
          <p>
            $ <span>{item.price}</span>
          </p>

          <p href="download">Dawnload</p>
        </div>
      </div>

      <div className="flex items-center gap-5">
        <Button btnText="Upate" btnStyle="bg-primary" btnClick={updateAction} />
        <Button
          btnText="Cancel"
          btnStyle="bg-inactiveText"
          btnClick={cancelAction}
        />

        <Button
          btnDisabled={loading}
          btnText={loading ? <Shimmer height={20} color={"#fff"} /> : "Delete"}
          btnStyle={loading ? "bg-inactiveText" : "bg-red-500"}
          btnClick={deleteitem}
        />
      </div>
    </div>
  );
};

const PodcastForm = ({
  createPodcast,
  cancelAction,
  formState,
  handleChange,
  categories,
  handleFileChange,
  handleCheckboxChange,
  editMode,
  selectedItem,
  updatePodcastAction,
  loading,
  editorRefs,
}) => {
  const Data = useSelector((state) => state.category.data);
  const SubPlanData = useSelector((state) => state.subPlan.data.data);
  const catStatus = useSelector((state) => state.category.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSubPlan());
  }, [dispatch]);

  return (
    <div className="update-modal bg-mainWhite w-full rounded-lg shadow-lg">
      <div className="border-inactiveText border-b-[1px] py-3 px-10 bg-mainWhite rounded-t-xl sticky top-0">
        <h1 className="text-primary text-lg">
          {editMode ? "Update Podcast" : "Add Podcast"}
        </h1>
      </div>

      <form
        action=""
        className="p-5"
        onSubmit={editMode ? updatePodcastAction : createPodcast}
      >
        <div className="grid grid-cols-2 gap-5 pb-10 border-b-2">
          <div>
            <label htmlFor="title" className="text-sm font-bold">
              <span>Podcast</span> title
            </label>
            <input
              value={formState?.title}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="title"
              id="title"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">Enter title</span>
          </div>

          <div>
            <label htmlFor="author" className="text-sm font-bold">
              {/* Podcast author */}
              Episode title
            </label>
            <input
              value={formState?.author}
              onChange={(e) => {
                handleChange(e);
              }}
              type="text"
              name="author"
              id="author"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            />
            <span className="text-inactiveText text-xs">
              Enter episode title
            </span>
          </div>

          <div>
            {/* <label htmlFor="summary" className="text-sm font-bold">
              Description
            </label>
            <textarea
              value={formState?.summary}
              onChange={(e) => {
                handleChange(e);
              }}
              name="summary"
              id="summary"
              cols="30"
              rows="4"
              placeholder="Type or Paste Podcast summary"
              className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
            ></textarea> */}

            <Editor
              tinymceScriptSrc={
                "henxaamn6xdte78gqyhjwtwiczgbsc9bub0g71unagmh13un" +
                "/tinymce/tinymce.min.js"
              }
              onInit={(bookSummary) => {
                editorRefs.summary.current = bookSummary;
              }}
              initialValue={editMode ? formState?.summary : ""}
              init={{
                height: 350,
                menubar: true,
                plugins,
                toolbar,
                content_style,
                setup: function (editor) {
                  editor.setContent("place your content here");
                },
              }}
            />
            <span className="text-inactiveText text-xs">
              Description <span>Podcast</span>
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex  gap-3">
              <div className="w-1/2">
                <label htmlFor="release_date" className="text-sm font-bold">
                  Release date
                </label>
                <input
                  value={formState?.release_date}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type="date"
                  name="release_date"
                  id="release_date"
                  className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
                />
              </div>

              <div className="w-1/2">
                <label htmlFor="episode" className="text-sm font-bold">
                  Episode (optional)
                </label>
                <input
                  value={formState?.episode}
                  type="text"
                  name="episode"
                  id="episode"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
                />
              </div>
            </div>

            <div>
              <label htmlFor="cover_art" className="text-sm font-bold">
                Add Cover Art
                <span className="text-red-500">
                  (Cover art must be less than 200 killobyte)
                </span>
              </label>
              <p className={`font-bold ${editMode ? "" : "hidden"}`}>
                Current cover_art
                <span className="text-primary">
                  <a href={selectedItem?.photo?.url} target="_black">
                    {selectedItem?.photo?.filename}
                  </a>
                </span>
              </p>
              <input
                type="file"
                name="cover_art"
                id="cover_art"
                onChange={handleFileChange}
                className="w-full py-1 px-3 rounded-lg border-2 border-inactiveText"
              />
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div>
            <h1 className="text-lg font-bold">Category</h1>
            <span className="text-inactiveText text-xs">
              Check associated categories
            </span>

            <div className="flex items-center justify-between mb-5">
              <div className="w-[50%]">
                {catStatus === StatusCode.LOADING ? (
                  <div>
                    <Shimmer height={38} width={38} color={"#207384"} />
                  </div>
                ) : (
                  <div className="">
                    {Data.map((category) => (
                      <InputCat
                        key={category.id}
                        {...category}
                        checked={categories?.includes(category.id)}
                        onChange={() => handleCheckboxChange(category.id)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="flex items-center gap-10 w-[50%]">
                <div className="w-1/3">
                  <label htmlFor="subscription_level">Subscription level</label>
                  <select
                    value={formState?.subscription_level}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="subscription_level"
                    id="subscription_level"
                    className=" border-2 py-2 w-full rounded-lg"
                  >
                    <option value="0">Basic</option>
                    {SubPlanData?.map((subPlan) => (
                      <option value={subPlan?.level}>{subPlan?.package}</option>
                    ))}
                  </select>
                </div>

                <div className="w-2/3">
                  <label htmlFor="podcast_link">Add podcast URL</label>
                  <div className="px-2 py-1 w-full rounded-lg border-2 flex items-center bg-tranparent">
                    <input
                      value={formState?.podcast_link}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                      name="podcast_link"
                      id="podcast_link"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="btns flex items-center gap-10">
              <Button
                btnDisabled={loading}
                btnStyle={loading ? "bg-inactiveText" : "bg-primary"}
                btntype={"submit"}
                btnText={
                  loading ? (
                    <Shimmer height={20} color={"#fff"} />
                  ) : editMode ? (
                    "Update Podcast"
                  ) : (
                    "Add Podcast"
                  )
                }
              />

              <Button
                btnClick={cancelAction}
                btnStyle="bg-inactiveText"
                btnText="Cancel"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
